// CONTACT

.p-contact-other {
	width: 100%;
	max-width: 1060px;
	margin: 0 auto;
	padding: 50px 16px 0;
	@include mqPc {
		padding: 80px 20px 100px 80px; }

	&__ttl {
		@include fz(20,30);
		letter-spacing: ls(100);
		text-align: center;
		color: cl(main-color);
		margin-bottom: 20px;
		@include mqPc {
			@include fz(24,24);
			margin-bottom: 50px; } }

	&__wrap {
		border-top: 1px solid cl(black);
		border-bottom: 1px solid cl(black);
		padding: 28px 2px;
		@include mqPc {
			padding: 52px 0; }
		.txt {
			@include fz(14,28);
			letter-spacing: ls(100);
			text-align: center;
			@include mqPc {
				@include fz(16,32); } }
		a {
			color: cl(key-color); } }

	.btn-back {
		width: 250px;
		margin: 30px auto 0;
		@include fz(14,14);
		font-weight: 600;
		text-align: right;
		padding: 11px 40px !important;
		@include mqPc {
			width: 300px;
			@include fz(16,16);
			margin: 80px auto 0;
			padding: 17px 45px !important;
			&:hover {
				color: cl(dark-blue);
				&::before {
					border: 2px solid cl(dark-blue);
					height: 32px; }
				&::after {
					left: 13px;
					transform: scale(-1,1);
					background: url('../image/common/arr_dabl.svg') no-repeat; } } }
		&::before {
			border: 2px solid cl(dark-blue);
			background: cl(dark-blue);
			height: 40px;
			border-radius: 20px;
			right: auto;
			left: 0;
			@include mqPc {
				height: 48px;
				border-radius: 24px; } }
		&::after {
			right: auto;
			left: 25px;
			transform: scale(-1,1);
			top: calc(50% - 4px);
			@include mqPc {
				left: 35px; } } } }


