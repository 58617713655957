// Breadcrumb

.l-breadcrumb {
	background: cl(light-gray);
	@include En();
	&__list {
		width: 100%;
		display: flex;
		padding: 20px 0 20px 15px;
		@include mqPc {
			max-width: 1180px;
			margin: 0 auto;
			padding: 25px 0 25px 110px; } }
	&__item {
		@include fz(12,12);
		margin-right: 26px;
		position: relative;
		@include mqPc {
			@include fz(14,14);
			margin-right: 26px; }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 5px;
			height: 9px;
			background: url('../image/common/ico_breadcrumb.svg') no-repeat;
			top: 2px;
			right: -15px;
			@include mqPc {
				top: 4px; } }
		&:last-child {
			margin-right: 0;
			&::after {
				display: none; } }

		a {
			color: cl(black);
			display: block; }
		a[href] {
			@include mqPc {
				@include easing;
				&:hover {
					text-decoration: underline; } } } } }
