// wp-pagenavi
//--------------------------------------------------
.wp-pagenavi {
	width: 100%;
	max-width: 338px;
	margin: 56px auto 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 0 66px;
	@include mqPc {
		max-width: none;
		margin: 80px auto 0;
		padding: 0 278px; }

	.previouspostslink {
		position: absolute;
		left: 0;
		display: block;
		width: 34px;
		padding: 20px 5px;
		&::before {
			content: '';
			display: none;
			position: absolute;
			width: 32px;
			height: 32px;
			top: 4px;
			left: 0;
			border: 2px solid cl(main-color);
			border-radius: 16px;
			@include easing;
			@include mqPc {
				display: block; } }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 34px;
			height: 8px;
			top: 14px;
			right: 0;
			background: url('../image/common/arr_main.svg') no-repeat;
			background-size: 100% 100%;
			transform: scale(-1,1);
			@include easing;
			@include mqPc {
				top: 17px;
				right: 50px; } }
		@include mqPc {
			width: 98px;
			cursor: pointer;
			padding: 13.5px 65px 13.5px 0;
			&:hover {
				&::before {
					width: 115px;
					height: 40px;
					top: 0;
					border-radius: 20px;
					background: cl(main-color);
					z-index: -1; }
				&::after {
					background: url('../image/common/arr_wh.svg') no-repeat; }
				.txt-img {
					&::after {
						background: url('../image/blog/txt_prev_wh.png') no-repeat;
						background-size: 100% 100%; } } } }

		.txt-img {
			display: none;
			@include mqPc {
				position: relative;
				display: block;
				width: 34px;
				height: 13px;
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 34px;
					height: 13px;
					background: url('../image/blog/txt_prev.png') no-repeat;
					background-size: 100% 100%;
					top: 2px;
					left: 60px; } } }
		.p-arr-prev {
			top: 0 !important;
			left: 0 !important;
			transform: scale(-1,1);
			right: auto;
			@include mqPc {
				top: 12px !important;
				left: 15px !important; } } }

	.nextpostslink {
		position: absolute;
		right: 0;
		display: block;
		width: 34px;
		padding: 20px 5px;
		&::before {
			content: '';
			display: none;
			position: absolute;
			width: 32px;
			height: 32px;
			top: 4px;
			right: 0;
			border: 2px solid cl(main-color);
			border-radius: 16px;
			@include easing;
			@include mqPc {
				display: block; } }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 34px;
			height: 8px;
			top: 14px;
			left: 0;
			background: url('../image/common/arr_main.svg') no-repeat;
			background-size: 100% 100%;
			@include easing;
			@include mqPc {
				top: 17px;
				left: 50px; } }
		@include mqPc {
			width: 98px;
			cursor: pointer;
			padding: 13.5px 65px 13.5px 0;
			&:hover {
				&::before {
					width: 115px;
					height: 40px;
					top: 0;
					border-radius: 20px;
					background: cl(main-color);
					z-index: -1; }
				&::after {
					background: url('../image/common/arr_wh.svg') no-repeat; }
				.txt-img {
					&::after {
						background: url('../image/blog/txt_next_wh.png') no-repeat;
						background-size: 100% 100%; } } } }
		.txt-img {
			display: none;
			@include mqPc {
				position: relative;
				display: block;
				width: 34px;
				height: 13px;
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 34px;
					height: 13px;
					background: url('../image/blog/txt_next.png') no-repeat;
					background-size: 100% 100%;
					top: 2px;
					left: 5px; } } } }

	.page,.last {
		display: block;
		@include OpenSans;
		@include fz(16,37);
		color: cl(main-color);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		text-align: center;
		border: 2px solid cl(main-color);
		position: relative;
		@include mqPc {
			@include fz(20,40);
			border: none;
			color: cl(black);
			&::after {
				content: '';
				display: block;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 2px solid cl(main-color);
				border-radius: 50%;
				@include easing; }
			&:hover {
				&::after {
					opacity: 1; } } } }
	.current,.page,.last,.extend {
		margin: 0 6px; }
	.hidden {
		display: none;
		@include mqPc {
			display: block; } }
	.extend {
		margin-bottom: 8px;
		@include mqPc {
			margin-bottom: 0; } }
	.current {
		@include OpenSans;
		@include fz(16,37);
		color: cl(white) !important;
		background: cl(dark-blue);
		border: 2px solid cl(dark-blue);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		text-align: center;
		@include mqPc {
			@include fz(20,40);
			border: none; } } }
