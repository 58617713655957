// LOADING

html.no-scroll {
	height: 100%;
	overflow: hidden; }

// .l-loading-top
//--------------------------------------------------------------------------------
.l-loading-top {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: cl(white);
	transform-origin: right center;

	// .l-loading-top__logo
	&__logo {
		width: 242px;
		height: 50px;
		margin: 0 auto;
		padding-top: calc((100vh - 50px) / 2); }

	// .l-loading-top__bg
	&__bg {
		width: 100%;
		height: 100%;
		@include center;
		transform: scale(0,1);
		transform-origin: left center;
		background: cl(main-color); } }



// .l-loading
//--------------------------------------------------------------------------------
// .l-loading
// 	width: 100%
// 	height: 100%
// 	position: fixed
// 	top: 0
// 	left: auto
// 	right: auto
// 	z-index: 100
// 	background: cl(white)

// 	// .l-loading__bg
// 	&__bg
// 		width: 100%
// 		height: 100%
// 		+center
// 		background: cl(white)

// 	// .l-loading__loader
// 	&__loader,
// 	&__loader:after
// 		border-radius: 50%
// 		width: 40px
// 		height: 40px

// 	// .l-loading__loader
// 	&__loader
// 		top: 50%
// 		margin: 0 auto
// 		position: relative
// 		border-top: 3px solid cl(main-color)
// 		border-right: 3px solid cl(main-color)
// 		border-bottom: 3px solid cl(main-color)
// 		border-left: 3px solid cl(white)
// 		-webkit-transform: translateZ(0)
// 		-ms-transform: translateZ(0)
// 		transform: translateZ(0) translateY(-50%)
// 		animation: load 1.5s infinite linear
