// RECRUIT

.p-recruit {
	@include Padding;
	.p-messege {
		width: 100%;
		margin: 0 auto;
		padding: 0 9px 40px;
		@include mqPc {
			max-width: 896px;
			padding: 0 20px 95px 80px; }
		&__ttl {
			color: cl(main-color);
			text-align: center; }
		&__copy {
			p {
				@include fzm(20,30,30,0);
				@include lsCenter;
				color: cl(key-color);
				padding: 0 7px;
				@include mqPc {
					@include fzm(20,36,60,5);
					padding: 0; } } } }


	.p-messege-nav {
		&__list {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			@include mqPc {
				flex-wrap: nowrap;
				justify-content: space-between;
				padding: 4px; } }
		&__item {
			width: 148px;
			position: relative;
			margin: 13px 4px;
			@include mq320 {
				width: 132px; }
			@include mqPc {
				margin: 0;
				width: 174px;
				&:first-child {
					margin-left: 0; }
				&:last-child {
					margin-right: 0; } }
			.link {
				display: block;
				@include fz(13,13);
				color: cl(white);
				padding: 19.5px 0 19.5px 15px;
				position: relative;
				background: cl(blue);
				border-radius: 26px;
				@include mqPc {
					@include fz(14,14);
					padding: 13px 0;
					padding-left: 25px; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 156px;
					height: 60px;
					border-radius: 30px;
					border: 2px solid cl(blue);
					top: -4px;
					left: -4px;
					@include mq320 {
						width: 140px; }
					@include mqPc {
						width: 182px;
						height: 48px;
						border-radius: 24px;
						top: -4px;
						left: -4px;
						@include easing; } }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 6px;
					background: url('../image/common/arr_mini_wh.svg') no-repeat;
					background-size: 100% 100%;
					transform: rotate(90deg);
					top: 23px;
					right: 12px;
					@include mqPc {
						width: 18px;
						height: 7px;
						top: 17px;
						right: 14px; } }
				@include mqPc {
					@include easing;
					&:hover {
						background: cl(key-color);
						&::before {
							width: 184px;
							height: 50px;
							border-radius: 25px;
							top: -5px;
							left: -5px;
							border: 2px solid rgba(cl(key-color), .5); } } } } } }


	.p-about {
		width: 100%;
		background: cl(blue);
		color: cl(white);
		padding: 45px 0 0;
		position: relative;
		@include mqPc {
			padding: 80px 0 0 60px; }
		&__ttl {
			text-align: center;
			.ja {
				color: cl(white); } }
		&__read {
			width: 100%;
			margin: 0 auto;
			@include fzm(14,28,25,0);
			padding: 0 16px;
			@include mqPc {
				width: percentage(800 / 1200);
				max-width: 800px;
				@include fzm(16,32,40,0);
				&:last-of-type {
					margin-bottom: 0; } } }
		&__thumb {
			width: 100%;
			margin: 45px auto 0;
			@include mqPc {
				max-width: 1220px;
				margin: 76px auto 0; } } }


	.p-category {
		padding-left: 16px;
		padding-right: 16px;
		@include mqPc {
			padding-left: 80px;
			padding-right: 20px; }
		&__info {
			margin: 0 auto;
			border: 2px solid cl(main-color);
			padding: 38px 0 13px;
			@include mqPc {
				max-width: 960px;
				padding: 65px 0 40px; } }
		&__inner {
			width: percentage(294 / 320);
			margin: 0 auto;
			@include fz(14,28);
			@include mqPc {
				width: percentage(810 / 960);
				@include fz(16,32); } }
		&__ttl {
			@include fz(16,25);
			background: cl(yellow);
			display: inline;
			text-align: left;
			padding-left: 5px;
			@include mqPc {
				@include fz(20,31); } }
		&__notes {
			@include fz(12,24);
			padding: 12px 0 19px;
			@include mqPc {
				@include fz(14,14);
				padding: 12px 0 38px; } }
		dl {
			padding: 17px 13px;
			border-top: 1px solid cl(black);
			font-weight: 600;
			@include mqPc {
				@include clearfix;
				padding: 24px 0;
				font-weight: 500; } }
		dt {
			@include mqPc {
				width: percentage(228 / 800);
				padding-left: 22px;
				float: left; } }
		dd {
			font-weight: 500;
			position: relative;
			@include mqPc {
				margin-left: percentage(228 / 800); } }
		dd.dots {
			padding-left: 22px;
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: cl(black);
				top: 13px;
				left: 0; } }
		dd.notes {
			@include fz(14,21);
			margin-bottom: 10px;
			letter-spacing: ls(40);
			&:last-child {
				margin-bottom: 0; } } }


	.p-conditions {
		padding-left: 16px;
		padding-right: 16px;
		@include mqPc {
			padding-left: 80px;
			padding-right: 20px; }
		&__info {
			margin: 0 auto;
			@include fz(14,28);
			@include mqPc {
				max-width: 960px;
				@include fz(16,32); }
			dl {
				padding: 17px 13px;
				border-top: 1px solid cl(black);
				font-weight: 600;
				&:last-child {
					border-bottom: 1px solid cl(black); }
				@include mqPc {
					@include clearfix;
					padding: 24px 0;
					font-weight: 500; } }
			dt {
				@include mqPc {
					float: left;
					width: percentage(308 / 960);
					padding-left: 22px; } }
			dd {
				font-weight: 500;
				position: relative;
				@include mqPc {
					margin-left: percentage(308 / 960); } }
			dd.dots {
				padding-left: 22px;
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: cl(black);
					top: 13px;
					left: 0; } }
			dd.notes {
				@include fz(14,21);
				margin-bottom: 10px;
				letter-spacing: ls(40);
				&:last-child {
					margin-bottom: 0; } } } }


	.p-flow {
		padding-left: 16px;
		padding-right: 16px;
		@include mqPc {
			max-width: 1060px;
			margin: 0 auto;
			padding-left: 80px;
			padding-right: 20px; }
		&__item {
			margin-bottom: 46px;
			border: 2px solid cl(main-color);
			position: relative;
			@include mqPc {
				display: flex;
				margin-bottom: 40px;
				border: 2px solid cl(main-color);
				position: relative; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				background: url('../image/common/arr_triangle.svg') no-repeat;
				left: percentage(163.5 / 360);
				bottom: -32px;
				width: 33px;
				height: 15px;
				@include mqPc {
					left: percentage(468 / 960);
					bottom: -28px;
					width: 24px;
					height: 11px; } }
			&:last-child {
				margin-bottom: 0;
				&::after {
					content: none; } } }
		&__left {
			@include fz(16,16);
			@include lsCenter;
			position: relative;
			background: cl(key-color);
			color: cl(white);
			padding: 18px 0;
			@include mqPc {
				@include fz(20,24);
				display: flex;
				align-items: center;
				justify-content: center;
				width: percentage(200 / 960);
				padding: 40px 0; }
			svg {
				fill: cl(white); }
			.num {
				width: 18px;
				height: 13px;
				position: absolute;
				top: 10px;
				left: 12px;
				@include mqPc {
					width: 24px;
					height: 18px;
					top: 14px;
					left: 12px; } }
			.ttl {
				@include mqPc {
					text-orientation: upright;
					writing-mode: vertical-rl;
					writing-mode: horizontal-tb; }
				span {
					@include fz(14,14);
					padding-top: 8px;
					@include mqPc {
						display: block;
						@include fz(16,16); } } } }
		&__right {
			background: cl(white);
			position: relative;
			width: 100%;
			padding: 23px 15px;
			@include mqPc {
				width: percentage(760 / 960);
				padding: 30px; }
			.read {
				@include fz(14,28);
				@include mqPc {
					@include fz(16,32); } } }
		&__time {
			position: absolute;
			display: block;
			color: cl(blue);
			@include fz(14,14);
			height: 15px;
			left: percentage(216 / 360);
			bottom: -32px;
			@include mqPc {
				@include fz(16,16);
				height: 18px;
				left: percentage(500 / 960);
				bottom: -31px; } }
		&__notes {
			@include fz(12,24);
			padding-top: 17px;
			@include mqPc {
				@include fz(14,24);
				padding-top: 19px; } } }


	.p-contact {
		padding-left: 16px;
		padding-right: 16px;
		@include mqPc {
			padding-left: 80px;
			padding-right: 20px; }
		&__inner {
			background: cl(light-gray);
			@include lsCenter;
			margin: 44px auto 0;
			padding: percentage(30 / 360) percentage(22 / 360);
			@include mqPc {
				max-width: 960px;
				margin: 45px auto 0;
				padding: 52px 0 42px; } }
		&__ttl {
			@include fz(16,16);
			color: cl(blue);
			@include mqPc {
				@include fz(20,20); } }
		&__name {
			@include fzm(14,14,27,29);
			@include mqPc {
				@include fzm(16,16,30,30); } }
		&__mail {
			@include fz(12,12);
			margin-bottom: 10px;
			@include mqPc {
				@include fz(14,14);
				margin-bottom: 8px; } }
		&__link {
			@include fz(16,16);
			@include OpenSans;
			color: cl(key-color);
			text-decoration: underline;
			@include mqPc {
				@include fz(18,18);
				@include easing; }
			&:hover {
				text-decoration: none; } } } }
