// SCO-116

.p-sco-116 {
	&__inner {
		position: relative;
		@include mqPc {
			padding-top: 50px; } }

	.p-sco-cat {
		@include mqPc {
			margin-top: -50px; } }
	.l-sco-cat__list {
		justify-content: flex-end;
		@include mqPc {
			justify-content: space-around; } }

	.p-drawing {
		position: relative;
		width: 328px;
		margin: 33px auto 47px;
		@include mqPc {
			width: 972px;
			margin: 0 auto 100px;
			padding-top: 65px;
			transform: scale(0.82,0.82); }
		@include mq360 {
			width: 310px;
			transform: scale(0.9,0.9); }

		&__btn {
			@include fz(14,19);
			letter-spacing: ls(100);
			color: cl(yellow);
			padding: 2px 35px 2px 0;
			position: absolute;
			top: -54px;
			left: 16px;
			@include mqPc {
				left: 5px; }
			&:focus {
				outline: 0; }
			&:after {
				content: '';
				display: block;
				width: 24px;
				height: 22px;
				position: absolute;
				top: 0;
				right: 0;
				background: url('../image/pipeline/116/arr_rs.png') no-repeat;
				background-size: 24px 22px; } }

		&__key {
			width: 100px;
			margin: 0 auto 68px;
			border-radius: 14px;
			@include f(12);
			position: relative;
			color: cl(white);
			text-align: center;
			background: cl(key-color);
			padding: 4px;
			z-index: 2;
			@include mqPc {
				width: 150px;
				margin: 0 auto;
				border-radius: 20px;
				@include f(20);
				padding: 6px;
				position: absolute;
				top: 144px;
				left: 35px; }
			@include mq360 {
				margin-left: 90px; } }

		&__cytoplasm {
			position: absolute;
			top: 52px;
			right: 0;
			width: 329px;
			height: 302px;
			background: url('../image/pipeline/116/line_sp.png') no-repeat;
			background-size: 100% auto;
			@include f(14);
			color: cl(key-color);
			text-align: right;
			padding-top: 15px;
			@include mqPc {
				top: 0;
				width: 722px;
				height: 424px;
				background: url('../image/pipeline/116/line.png') no-repeat;
				background-size: 100% auto;
				@include f(16); }
			.en & {
				letter-spacing: .05em; } }

		.container {
			width: 100%;
			padding: 0 24px;
			position: relative;
			@include mqPc {
				width: 722px;
				padding: 0 35px 0 40px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-left: auto; }
			@include mq360 {
				padding: 0; }
			.en & {
				letter-spacing: .05em; }

			&--inhibition {
				margin-bottom: 25px;
				@include mqPc {
					margin-bottom: 30px; }
				.container__txt {
					top: 24px;
					@include mqPc {
						top: 50%;
						position: absolute;
						right: 150px;
						transform: translateY(-50%); } } }

			&__figure {
				width: 280px;
				@include mqPc {
					width: 516px; } }

			&__txt {
				position: absolute;
				right: 0;
				@include mqPc {
					position: relative;
					right: auto;
					width: 112px; }
				.txt {
					@include fz(12,18);
					letter-spacing: ls(50);
					@include mqPc {
						@include fz(16,20); } } }

			.inhibition {
				width: 159px;
				height: 85px;
				margin: 0 auto;
				position: relative;
				@include mqPc {
					width: 280px;
					height: 125px; }
				&:before {
					content: '';
					width: 130px;
					height: 46px;
					border-radius: 23px;
					background: cl(white);
					position: absolute;
					bottom: -5px;
					left: 0;
					right: 0;
					margin: auto;
					@include mqPc {
						width: 214px;
						height: 66px;
						border-radius: 33px;
						bottom: -7px; } }
				.keap1 {
					@include f(12);
					color: cl(white);
					text-align: center;
					background: cl(blue);
					width: 100%;
					height: 100%;
					border-radius: 20px;
					padding-top: 20px;
					@include mqPc {
						@include f(20);
						border-radius: 35px; } }

				.nrf2 {
					@include f(12);
					color: cl(white);
					text-align: center;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					background: #14ae67;
					width: 120px;
					border-radius: 18px;
					padding: 8px 6px;
					z-index: 1;
					@include mqPc {
						@include f(20);
						width: 200px;
						border-radius: 26px;
						padding: 12px 6px;
						bottom: 0; } } }

			.nucleus {
				width: 280px;
				height: 120px;
				position: relative;
				margin-left: 5px;
				@include easing;
				@include mqPc {
					width: 516px;
					height: 176px;
					margin-left: 0; }
				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 167px;
					height: 541px;
					background: url('../image/pipeline/116/arr_ant_sp.png') no-repeat;
					background-size: 100% auto;
					bottom: -541px;
					left: -29px;
					z-index: -1;
					@include mqPc {
						width: 696px;
						height: 114px;
						background: url('../image/pipeline/116/arr_ant.png') no-repeat;
						background-size: 100% auto;
						bottom: -114px;
						left: -156px; }
					@include mq360 {
						left: -14px; } }
				.name {
					@include fz(14,20);
					color: cl(key-color);
					padding: 50px 15px;
					@include mqPc {
						@include fz(16,20);
						padding: 78px 20px; } }
				.img {
					@include center;
					z-index: -1; }
				.img-ant {
					opacity: 0; } } }

		// .p-drawing__list
		&__list {
			width: 100%;
			position: relative;
			margin: 75px auto 0;
			@include mqPc {
				width: calc(968px + 40px);
				display: flex;
				position: relative;
				margin: 125px -20px 0; } }


		// .p-drawing__item
		&__item {
			width: 288px;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 18px 0;
			margin: 20px auto 0;
			@include mqPc {
				width: 296px;
				height: 262px;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 25px 0;
				margin: 0 20px; }
			&:nth-child(3) {
				padding: 27px 0;
				@include mqPc {
					padding: 105px 0; } }
			.name {
				@include fz(12,12);
				letter-spacing: ls(50);
				text-align: center;
				@include mqPc {
					@include fz(16,20); }
				&--en {
					@include fz(10,10);
					@include mqPc {
						@include fz(14,18); } } }
			.img {
				width: 100px;
				margin: 4px auto 0;
				@include mqPc {
					width: 121px;
					margin: 15px auto 7px; } }
			.txt {
				@include fz(12,20);
				letter-spacing: ls(50);
				text-align: center;
				@include mqPc {
					@include fz(16,24); }
				.en & {
					letter-spacing: .05em; } } } } }

