// COMPANY

.p-company {
	@include Padding;
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 0 16px;
		@include mqPc {
			max-width: 1060px;
			padding: 0 20px 0 80px; } }


	// .p-mission
	.p-mission {
		border: 2px solid cl(main-color);
		position: relative;
		margin-top: 12px;
		margin-bottom: 25px;
		@include mqPc {
			margin-top: 35px;
			margin-bottom: 50px; }
		&__ttl {
			position: absolute;
			top: percentage(-14 / 360);
			left: 0;
			right: 0;
			width: 190px;
			margin: 0 auto;
			background: cl(white);
			padding: 0 5px;
			@include mqPc {
				width: 303px;
				padding: 0 20px;
				top: percentage(-50 / 960); }
			span {
				display: block; }
			.img {
				margin: 0 auto;
				line-height: 1; }
			.ja {
				@include fz(12,12);
				@include lsCenter;
				padding-top: 15px;
				@include mqPc {
					@include fz(16,16);
					padding-top: 22px; } } }
		&__inner {
			// width: percentage(310 / 328)
			margin: 0 auto;
			padding: 68px 0 30px;
			@include mqPc {
				// width: 606px
				padding: 85px 0 60px; }
			&--eng {
				padding: 45px 0 30px;
				@include mqPc {
					padding: 55px 0 60px; } } }
		&__copy {
			p {
				@include fz(20,30);
				color: cl(key-color);
				text-align: center;
				margin: 0 auto;
				padding: 0 5px;
				@include mqPc {
					@include fz(24,48);
					padding: 0; }
				@include En(); } }

		&__nav {
			margin-top: 26px;
			margin: 0 auto;
			width: percentage(310 / 328);
			@include mqPc {
				// width: 606px
				width: 100%; } }
		&__list {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			@include mqPc {
				flex-wrap: nowrap;
				// justify-content: space-between
				justify-content: center;
				padding: 4px; } }
		&__item {
			width: 132px;
			position: relative;
			margin: 13px 10px;
			@include mqPc {
				margin: 0;
				width: 174px;
				margin: 0 20px;
				&:first-child {
					margin-left: 0; }
				&:last-child {
					margin-right: 0; } }
			&--eng {
				width: auto !important;
				.link {
					padding: 19.5px 28px 19.5px 15px !important;
					@include mqPc {
						padding: 13px 55px 13px 25px !important; } } }
			.link {
				display: block;
				@include fz(13,13);
				color: cl(white);
				padding: 19.5px 0;
				padding-left: 15px;
				position: relative;
				background: cl(blue);
				border-radius: 26px;
				@include mqPc {
					@include fz(14,14);
					padding: 13px 0;
					padding-left: 25px; }
				@include En(.05em,1);
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: calc(100% + 8px);
					height: calc(100% + 8px);
					border-radius: 30px;
					border: 2px solid cl(blue);
					top: -4px;
					left: -4px;
					@include mqPc {
						border-radius: 24px;
						top: -4px;
						left: -4px;
						@include easing; } }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 6px;
					background: url('../image/common/arr_mini_wh.svg') no-repeat;
					background-size: 100% 100%;
					transform: rotate(90deg);
					top: 23px;
					right: 12px;
					@include mqPc {
						width: 18px;
						height: 7px;
						top: 17px;
						right: 14px; } }
				@include mqPc {
					@include easing;
					&:hover {
						background: cl(key-color);
						&::before {
							width: calc(100% + 10px);
							height: calc(100% + 10px);
							border-radius: 25px;
							top: -5px;
							left: -5px;
							border: 2px solid rgba(cl(key-color), .5); } } } } } }


	// .p-info
	.p-info {
		@include fz(14,28);
		@include mqPc {
			@include fz(16,32); }
		@include En();
		&.en dt {
			padding-bottom: 5px;
			@include mqPc {
				padding-bottom: 0; } }
		dl {
			padding: 17px 13px;
			border-top: 1px solid cl(black);
			font-weight: 600;
			&:last-child {
				border-bottom: 1px solid cl(black); }
			@include mqPc {
				@include clearfix;
				padding: 24px 0;
				font-weight: 500; } }
		dt {
			@include mqPc {
				float: left;
				width: percentage(308 / 960);
				padding-left: 22px; }
			span {
				@include fz(14,14); } }
		dd {
			font-weight: 500;
			@include mqPc {
				padding-left: percentage(308 / 960); } }
		// .p-btn
		// 	+JPfont
		// 	width: 153px
		// 	margin: 18px 0 8px
		// 	text-align: left
		// 	+mqPc
		// 		width: 173px
		// 		margin: 25px 0 8px
		// 	&::before
		// 		background: cl(blue)
		// 		border: 2px solid cl(blue)
		// 	+mqPc
		// 		&:hover
		// 			color: cl(blue)
		// 			&::before
		// 				border: 2px solid cl(blue)
		// 				background: none
		// 			&::after
		// 				background: url('../image/common/arr_bl.svg') no-repeat
 }		// 				background-size: 100% 100%


	// .p-development
	.p-development {
		&__txt {
			@include fzm(14,28,39,0);
			text-align: center;
			@include mqPc {
				@include fzm(16,32,48,0); }
			@include En(); }
		&__img {
			width: 100%;
			max-width: 680px;
			margin: 0 auto; } }


	// .p-access
	.p-access {
		&__txt {
			@include fzm(14,28,39,0);
			text-align: center;
			@include mqPc {
				@include fzm(16,32,48,0); }
			@include En(); }
		&__map {
			position: relative;
			width: 100%;
			padding-top: percentage(228 / 360);
			overflow: hidden;
			margin: percentage(25 / 360) auto 0;
			iframe {
				@include center;
				width: 100%;
				height: 100%; }
			@include mqPc {
				margin: 25px auto 0;
				padding-top: percentage(456 / 960); } }

		&__table {
			background: cl(light-gray);
			padding: percentage(32 / 360) percentage(28 / 360) percentage(32 / 360);
			@include mqPc {
				padding: percentage(50 / 960) percentage(80 / 960); }
			.table__ttl {
				@include fz(16,16);
				color: cl(blue);
				font-weight: 600;
				margin-bottom: percentage(20 / 360);
				@include mqPc {
					@include fz(20,20);
					margin-bottom: percentage(34 / 960); }
				@include En(); }
			table {
				tr {
					@include fz(14,25);
					@include mqPc {
						@include fz(16,32); }
					@include En(); }
				th {
					width: 100%;
					display: block;
					text-align: left;
					padding-left: 15px;
					position: relative;
					@include mqPc {
						width: percentage(298 / 960);
						font-weight: 500;
						vertical-align: top;
						display: table-cell; }
					&::before {
						content: '';
						display: block;
						position: absolute;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background: cl(black);
						top: 9px;
						left: 0;
						@include mqPc {
							top: 14px; } } }
				td {
					width: 100%;
					margin-top: percentage(8 / 360);
					display: block;
					@include mqPc {
						width: auto;
						margin-top: 0;
						display: table-cell; } }
				span {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: cl(black); }
				td.mb {
					padding-bottom: percentage(35 / 960); } } } } }
