// gnav

// .l-gnav
//--------------------------------------------------------------------------------
.l-gnav {
	width: 100%;
	height: 100%;
	@include mqPc {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2; }
	&.border {
		border-bottom: 2px solid cl(border-gray);
		background: cl(white);
		@include easing;
		@include mqPc {
			border-bottom: none;
			background: none; } }
	&.is-fixed {
		@include mqPc {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
			// background: cl(main-color)
			width: 100%;
			height: 100%; } }
	&.is-open {
		@include mqPc {
			display: block;
			min-width: 1000px;
			padding: 64px 0 64px percentage(170 / 1220);
			overflow: auto;
 } }			// background-color: #fff


	// .l-gnav__logo
	&__logo {
		margin-top: 16px;
		margin-left: 16px;
		@include mqPc {
			margin-top: 0;
			margin-left: percentage(60 / 1280); }
		a {
			display: block;
			width: 182px;
			height: 37px;
			@include mqPc {
				width: 242px;
				height: 50px; }
			.logo-color {
				display: block; }
			.logo-wh {
				display: none; } }
		p {
			display: none; }
		.is-fixed & {
			@include mqPc {
				display: flex;
				align-items: flex-end;
				margin-bottom: 40px;
				margin-left: 0;
				z-index: 1;
				opacity: 0;
				transform: translateX(8px);
				a {
					.logo-color {
						display: none; }
					.logo-wh {
						display: block; } }
				p {
					display: block;
					@include fz(16,16);
					letter-spacing: ls(60);
					color: cl(white);
					margin-left: 22px;
					margin-bottom: 10px; } } }
		.is-open & {} }


	// .l-gnav__group
	&__group {
		display: none;
		width: 100%;
		position: fixed;
		height: 100vh;
		margin-top: 15px;
		background: cl(white);
		overflow-y: scroll;
		z-index: 1;
		opacity: 0;
		@include mqPc {
			// position: relative
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: percentage(754 / 1280);
			max-width: 754px;
			min-width: 680px;
			height: 110px;
			margin-top: 0;
			background: none;
			overflow-y: visible;
			opacity: 1; }
		.is-fixed & {
			// display: block
			@include mqPc {
				width: 100%;
				height: auto;
				margin-left: 6px;
				opacity: 0;
				transform: translateX(8px); } }
		.is-open & {
			position: relative; } }


	// .l-gnav__nav
	&__nav {
		@include mqPc {
			display: flex;
			justify-content: space-between; } }


	// .l-gnav__list
	&__list {
		@include mqPc {
			width: percentage(486 / 754);
			max-width: 486px;
			display: flex;
			justify-content: space-between;
			align-items: center; }
		.is-open & {
			@include mqPc {
				display: block;
				height: 100%;
				padding-left: 288px;
				position: relative; } } }


	// .l-gnav__unit
	&__unit {
		position: relative;
		.is-open & {
			@include mqPc {
				width: 260px;
				@include mq1060 {
					width: 288px;

					&:nth-child(2) {
						width: 325px; } }

				&:first-child {
					position: absolute;
					top: 0;
					left: 0; } } }

		&--hvr {
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 13px 7.5px 0 7.5px;
				border-color: cl(key-color) transparent transparent transparent;
				bottom: 10px;
				left: calc(50% - 7.5px);
				opacity: 0;
				@include easing;
				.en & {
					bottom: 15px; } }
			&:hover {
				&:before {
					opacity: 1; } }
			.is-open & {
				&:before {
					display: none; } } } }


	// .l-gnav__item
	&__item {
		position: relative;
		overflow: hidden;
		@include mqSp {
			&:not(.l-gnav__parent)::after {
				content: '';
				display: block;
				position: absolute;
				width: 28px;
				height: 6px;
				background-image: url('../image/common/arr_wh.svg');
				background-size: 100% 100%;
				top: calc(50% - 4px);
				right: 20px; } }
		.en & {
			a {
				padding: 25px 19px;
				@include mqPc {
					padding: 46px 10px; } } }

		a {
			display: block;
			padding: 20px 19px;
			background: cl(main-color);
			border-bottom: 1px solid cl(white);
			color: cl(white);
			@include mqPc {
				background: none;
				border-bottom: none;
				color: cl(main-color);
				padding: 36px 10px;
				// margin: 30px 0
				margin: 0;
				@include easing;
				&:hover {
					color: cl(key-color); } }
			.is-open & {
				@include mqPc {
					color: cl(white);
					padding: 0;
					margin-bottom: 30px;
					display: inline-block;
					&.is-active {
						color: cl(white); }
					&:hover {
						color: cl(key-color);
						.en,.ja {
							text-shadow: none; } } } }
			&.is-active {
				@include mqPc {
					color: cl(key-color); } } }
		span {
			display: block; }
		.en {
			@include OpenSans;
			@include fz(18,18);
			letter-spacing: ls(60);
			@include mqPc {
				text-shadow: 0 0 10px cl(white); }
			.is-open & {
				@include mqPc {
					display: none; } } }
		.ja {
			@include fz(10,10);
			margin-top: 7px;
			@include mqPc {
				@include fz(12,12);
				margin-top: 8px;
				text-shadow: 0 0 10px cl(white); }
			.is-open & {
				@include mqPc {
					@include fz(20,20);
					text-shadow: none;
					display: block; } }
			&--en {
				display: none; } } }


	// .l-gnav__parent
	&__parent {
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 24px;
			height: 2px;
			background-color: cl(white);
			top: calc(50% - 1px);
			right: 22px; }
		&::after {
			transform: rotate(90deg);
			@include easing; }
		&.is-open {
			a {
				background: cl(key-color); }
			&::after {
				transform: rotate(0);
				@include easing; } }
		@include mqPc {
			&::before,
			&::after {
				display: none; } }
		.is-open & {
			@include mqPc {
				pointer-events: none; } } }


	// .l-gnav__child
	&__child {
		display: none;
		.is-open & {
			@include mqPc {
				display: block; } }

		&--pipeline {
			@include mqPc {
				width: 365px;
				letter-spacing: ls(60); } }

		&--pc {
			display: none;
			@include mqPc {
				position: absolute;
				width: 384px;
				padding: 20px 16px;
				background: cl(key-color);
				left: -141px;
				.en & {
					width: 348px;
					left: -125px; } }
			a {
				display: block;
				position: relative;
				color: cl(white);
				padding: 6px 6px 6px 34px;
				border-bottom: none;
				@include fz(14,26);
				letter-spacing: ls(60);
				@include easing;
				&:hover {
					background: cl(main-color); }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 12px;
					height: 2px;
					background: cl(white);
					top: 50%;
					left: 15px;
					transform: translateY(-50%); } }

			.is-open & {
				display: none; } }

		a {
			display: block;
			color: cl(main-color);
			padding: 23px 65px 23px 19px;
			border-bottom: 1px solid cl(main-color);
			position: relative;
			&:last-child {
				border-bottom: none; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 28px;
				height: 6px;
				background-image: url('../image/common/arr_main.svg');
				background-size: 100% 100%;
				top: calc(50% - 4px);
				right: 20px;
				@include mqPc {
					display: none; } }
			&:not([href]) {
				opacity: 0.5;
				pointer-events: none; }

			.is-open & {
				@include mqPc {
					color: cl(white);
					@include fz(16,24);
					padding: 0 0 0 25px;
					margin-bottom: 10px;
					border-bottom: none;
					@include easing;
					&:last-child {
						margin-bottom: 30px; }
					&::after {
						display: none; }
					&::before {
						content: '';
						display: block;
						position: absolute;
						width: 12px;
						height: 2px;
						background: cl(white);
						top: 12px;
						left: 0;
						@include easing; }
					&:hover {
						color: cl(key-color);
						&::before {
							background: cl(key-color); } }
					.min {
						@include fz(14,24); } } } } }


	// .l-gnav__lang
	&__lang {
		display: flex;
		align-items: center;
		justify-content: center;
		@include OpenSans;
		@include fz(14,14);
		font-weight: bold;
		margin: 32px 0;
		position: relative;
		@include mqPc {
			width: 92px;
			justify-content: flex-start;
			@include fz(12,12);
			margin: 0 34px 0 55px;
			position: absolute;
			top: 37px;
			right: 110px; }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 27px;
			height: 2px;
			background: #b5b5b5;
			top: 24px;
			left: calc((100% - 27px) / 2); }
		@include mqPc {
			&::before {
				width: 20px;
				top: 18px;
				left: calc((100% - 20px) / 2); } }
		a {
			display: block;
			width: 50px;
			height: 50px;
			line-height: 46px;
			padding-left: 3px;
			border-radius: 50%;
			text-align: center;
			border: 2px solid #b5b5b5;
			color: #b5b5b5;
			@include mqPc {
				width: 36px;
				height: 36px;
				line-height: 33px; }
			&:first-child {
				margin-right: 27px;
				@include mqPc {
					margin-right: 20px; } } }
		a:not(.is-current) {
			@include mqPc {
				position: relative;
				@include easing;
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 36px;
					height: 36px;
					border-radius: 50%;
					border: 2px solid rgba(cl(light-blue), 0);
					top: -2px;
					left: -2px;
					@include easing; }
				&:hover {
					color: cl(white);
					border: 2px solid cl(light-blue);
					background: cl(light-blue);
					&::after {
						border: 2px solid rgba(cl(light-blue), .3);
						// transform: scale(1.3,1.3)
						z-index: -1;
						width: 44px;
						height: 44px;
						top: -6px;
						left: -6px; } } } }
		.is-current {
			border: 2px solid cl(light-blue);
			color: cl(light-blue);
			pointer-events: none; }
		.is-open & {
			@include mqPc {
				display: none; } } }


	// .l-gnav__contact
	&__contact {
		padding-bottom: 108px;
		@include mqPc {
			padding-bottom: 0;
			margin-left: auto; }
		a {
			width: percentage(328 / 360);
			height: 72px;
			// padding-top: 20px
			// display: block
			color: cl(white);
			background: cl(light-blue);
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@include mqPc {
				width: 110px;
				height: 110px;
				// padding-top: 36px
				@include easing;
				&:hover {
					background: cl(key-color); } } }
		span {
			display: block;
			text-align: center; }
		.en {
			@include OpenSans;
			@include fz(18,18);
			letter-spacing: ls(60); }
		.ja {
			@include fz(10,10);
			margin-top: 6px;
			@include mqPc {
				@include fz(12,12);
				margin-top: 8px; } }
		.is-open & {
			@include mqPc {
				position: absolute;
				left: 0;
				// bottom: 136px
				bottom: 30px;
				a {
					width: 220px;
					height: 100px; }
				.en {
					@include fz(20,20); }
				.ja {
					@include fz(14,14); } } }
		&--en {
			bottom: 14px !important; } }


	// .l-gnav__blog
	&__blog {
		display: none;
		.is-open & {
			@include mqPc {
				display: block;
				width: 220px;
				height: 100px;
				position: absolute;
				left: 0;
				bottom: 26px;
				a {
					@include easing;
					display: block;
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 220px;
						height: 100px;
						background-color: rgba(cl(white), .1);
						top: 0;
						left: 0;
						opacity: 0;
						@include easing; }
					&:hover {
						&::after {
							opacity: 1; } } } } } } }
