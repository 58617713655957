// SCO

.l-sco {
	@include Padding;

	// .l-sco__inner
	&__inner {
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;
		@include mqPc {
			max-width: 1068px;
			padding-left: 80px; } }

	// .l-sco__desc
	&__desc {
		width: 100%;
		margin: 0 auto;
		@include fzm(14,28,40,0);
		@include En();
		@include mqPc {
			max-width: 820px;
			@include fzm(16,32,60,0); }
		p + p {
			margin-top: 15px;
			@include mqPc {
				margin-top: 20px; } }
		a {
			color: cl(key-color);
			border-bottom: 1px solid cl(key-color);
			&:hover {
				border-bottom: 1px solid transparent; } } } }


// .l-sco-box
.l-sco-box {
	@include En();
	margin-bottom: 41px;
	padding: 15px 17px 15px 17px;
	border: 2px solid cl(light-gray);
	@include mqPc {
		margin-bottom: 80px;
		// padding: 12px 0 12px 70px
		border: 3px solid cl(light-gray);
		padding: 20px 0; }
	.en & {
		letter-spacing: .05em; }

	&__unit {
		@include fz(14,28);
		width: 100%;
		margin: 0 auto;
		display: flex;
		@include mqPc {
			width: percentage(824 / 972);
			@include fz(16,32); }

		&:not(:last-child) {
			margin-bottom: 10px; }

		// .l-sco-box--en
		&--en {
			@include fz(13,15);
			@include mqPc {
				@include fz(16,19); } } }

	&__left {
		margin-right: 3px;
		width: 70px;
		@include mqPc {
			width: 90px;
			margin-right: 0; }
		.en & {
			width: 120px;
			@include mqPc {
				width: 130px; } }

		// .l-sco-box__ttl
		.ttl {
			position: relative;
			@include mqPc {
				@include fz(16,24);
				margin: 4px 0 0; }
			&--mg {
				margin: 4px 0 0;
				@include mqPc {
					margin: 14px 0 0; } }
			span {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				@include mqPc {
					right: 10px; } } } }

	&__right {
		width: percentage(260 / 330);
		@include mqPc {
			width: 734px; }

		// .l-sco-box__list
		.list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			@include mqPc; }

		li {
			@include fz(14,22);
			padding: 3px 0;
			margin: 5px 10px 5px 0;
			@include mqPc {
				@include fz(16,24);
				padding: 4px 0;
				margin: 10px 12px 10px 0; } }

		// .l-sco-box__item
		.item {
			@include fz(14,22);
			color: cl(white);
			padding: 3px 16px;
			background: cl(key-color);
			border-radius: 14px;
			margin: 5px 10px 5px 0;
			@include mqPc {
				@include fz(16,24);
				padding: 4px 28px;
				margin: 10px 12px 10px 0;
				border-radius: 16px; }
			.en & {
				@include fz(13,22);
				letter-spacing: ls(50);
				@include mqPc {
					@include fz(16,24); } }
			&:last-child {
				margin-right: 0; } }

		.txt {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,32); } } } }


.l-sco-cat {
	width: 100%;
	max-width: 328px;
	margin: 0 auto;
	@include mqPc {
		width: auto;
		position: absolute;
		right: 0; }

	// .l-sco-cat__list
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-bottom: 25px;
		@include mqPc {
			display: block;
			margin-bottom: 0; } }

	// .l-sco-cat__item
	&__item,
	%l-sco-cat__item {
		@include fz(12,12);
		padding: 5px 0 5px 29px;
		position: relative;
		margin-top: 9px;
		margin-bottom: 9px;
		letter-spacing: ls(50);
		@include mqPc {
			@include fz(14,14);
			padding: 6px 0 6px 35px;
			margin-top: 0;
			margin-bottom: 10px; }
		&:last-child {
			margin-bottom: 0; }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 27px;
			height: 22px;
			top: 0;
			left: 0;
			@include mqPc {
				width: 32px;
				height: 26px; } }
		&--change {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_change.png') no-repeat;
				background-size: 100% 100%; } }
		&--activation {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_activation.png') no-repeat;
				background-size: 100% 100%; } }
		&--effect {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_effect.png') no-repeat;
				background-size: 100% 100%; } }
		&--inhibition {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_inhibition.png') no-repeat;
				background-size: 100% 100%; } }
		&--route {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_route.png') no-repeat;
				background-size: 100% 100%; } }
		&--protein {
			@extend %l-sco-cat__item;
			&::before {
				background: url('../image/pipeline/cat_protein.png') no-repeat;
				background-size: 100% 100%; } } } }

.l-sco__pipeline-img {
  padding-inline: 10px;
  margin-bottom: 40px;
  @include mqPc {
    margin: 0px auto 80px;
    max-width: 800px; } }

.l-sco-detail {
	padding: 14px 15px;
	margin: 40px 0 47px;
	background: cl(light-gray);
	@include mqPc {
		padding: 27px 38px;
		margin: 48px 0 98px; }
	&--en {
		.l-sco-detail__txt {
			@include OpenSans; } }

	// .l-sco-detail__txt
	&__txt {
		@include fz(12,24);
		letter-spacing: ls(50);
		@include mqPc {
			@include fz(14,24); }
		span {
			font-weight: 700; } } }


.l-sco-links {
	width: 100%;
	margin: 0px auto 60px;
	padding: percentage(27 / 328) percentage(17 / 328) percentage(40 / 328);
	border: 2px solid cl(main-color);
	@include OpenSans;
	letter-spacing: ls(50);
	@include mqPc {
		margin: 0px auto 110px;
		// margin: 80px auto 110px
		// margin: 0 auto 30px
		padding: 56px 64px 80px; }

	// .l-sco-links__hdl3
	&__hdl3 {
		@include OpenSans;
		@include f(16);
		font-weight: 600;
		color: cl(dark-blue);
		margin-bottom: 10px;
		@include mqPc {
			@include f(20);
			margin-bottom: 18px; } }

	// .l-sco-links__list
	&__list {
		&:not(:last-child) {
			margin-bottom: 26px;
			@include mqPc {
				margin-bottom: 60px; } } }

	// .l-sco-links__list
	&__item {
		border-bottom: 1px solid cl(black);
		padding: 17px 0 20px;
		@include mqPc {
			padding: 25px 12px 28px; }
		&:first-child {
			border-top: 1px solid cl(black); }

		p {
			@include JPfont;
			font-weight: 500; }

		p + p {
			@include f(13);
			margin-top: 15px!important;
			@include mqPc {
				@include f(14);
				margin-top: 25px!important; } }

		// 最初の<p>タグのスタイル
		// > p:first-of-type
		// 	+JPfont
		// 	+fz(14,25)
		// 	+mqPc
		// 		+fz(16,26)

		// // 2番目の<p>タグのスタイル
		// > p:nth-of-type(2)
		// 	margin-top: 15px
		// 	+fz(13,23)
		// 	+mqPc
		// 		margin-top: 25px
		// 		+fz(14,27)

		/// 直下に<a>タグがある場合のスタイル
		/// &:has(> a)
		/// 	margin-top: 0

		/// 2番目の<a>タグのスタイル
		// 	> a
		// 		margin-top: 0px
		// 		display: inline-block
		// 		+JPfont
		// 		color: cl(key-color)
		// 		+fz(13,21)
		// 		// margin-top: 10px
		// 		text-decoration: underline
		// 		+mqPc
		// 			+fz(14,20)

		// 	// <a>タグのスタイル
		// > p:nth-of-type(3) > a
		// 	display: inline-block
		// 	+JPfont
		// 	color: cl(key-color)
		// 	+fz(13,21)
		// 	text-decoration: underline
		// 	+mqPc
		// 		+fz(14,20)

		a {
			display: inline-block;
			@include JPfont;
			color: cl(key-color);
			@include fz(13,21);
			margin-top: 10px;
			text-decoration: underline;
			@include mqPc {
				@include fz(14,20);
				&:hover {
					border-bottom: 1px solid transparent; } } }

		a[href$=".pdf"] {
			position: relative;
			&::after {
				content: 'PDF';
				@include OpenSans;
				@include fz(12,12);
				color: cl(white);
				background: cl(key-color);
				padding-left: 9px;
				padding-right: 9px;
				width: 50px;
				height: 18px;
				margin-left: 6px;
				text-decoration: none;
				@include mqPc {
					// text-decoration: none
 } } }					// vertical-align: 2px


		// ul
		// 	li
		// 		border-bottom: 1px solid cl(black)
		// 		padding: 17px 0 20px
		// 		+JPfont
		// 		+fz(14,25)
		// 		// &:not(:first-child)
		// 		// 	margin-top: 30px
		// 		// 	+mqPc
		// 		// 		margin-top: 40px
		// 		+mqPc
		// 			+fz(16,26)
		// 			padding: 25px 12px 28px
		// 		&:first-child
		// 			border-top: 1px solid cl(black)
		// 		p
		// 			margin-top: 15px
		// 			+fz(13,23)
		// 			+mqPc
		// 				margin-top: 25px
		// 				+fz(14,27)
		// 		a
		// 			display: inline-block
		// 			+JPfont
		// 			color: cl(key-color)
		// 			+fz(13,21)
		// 			margin-top: 10px
		// 			text-decoration: underline
		// 			+mqPc
		// 				+fz(14,20)
		// 				// &:hover
		// 				// 	border-bottom: 1px solid transparent
		// 			// display: inline-block
		// 			// color: cl(key-color)
		// 			// +mqPc
		// 			// 	&:hover
		// 			// 		border-bottom: 1px solid transparent

		// 			// // display: inline
		// 			// border-bottom: 1px solid cl(key-color)
		// 			// word-break: break-word
		// 			// // +easing(.2)

		// 		a[href$=".pdf"]
		// 			position: relative
		// 			&::after
		// 				content: 'PDF'
		// 				+OpenSans
		// 				+fz(12,12)
		// 				color: cl(white)
		// 				background: cl(key-color)
		// 				padding-left: 12px
		// 				padding-right: 9px
		// 				width: 50px
		// 				height: 18px
		// 				margin-left: 6px
		// 				text-decoration: none
		// 				+mqPc
		// 					vertical-align: 2px

		// 		// .details
		// 		// 	margin-top: 15px
		// 		// 	+fz(13,23)
		// 		// 	+mqPc
		// 		// 		margin-top: 25px
		// 		// 		+fz(14,27)

		// 		// .details-link
		// 		// 	+JPfont
		// 		// 	color: cl(key-color)
		// 		// 	+fz(13,21)
		// 		// 	margin-top: 5px
		// 		// 	+mqPc
		// 		// 		+fz(14,20)

		// 		// 	span
		// 		// 		border-bottom: 1px solid cl(key-color)

		// 		// 		&:hover
		// 		// 			span
		// 		// 				border-bottom: 1px solid transparent

		// 		// .link
		// 		// 	margin-top: 5px
		// 		// 	+mqPc
		// 		// 		margin-top: 6px
		// 		// 	p
		// 		// 		+fz(13,23)
		// 		// 		+mqPc
		// 		// 			+fz(14,20)

		// 		// .read
		// 		// 	+fz(13,23)
		// 		// 	margin-top: 10px
		// 		// 	+mqPc
		// 		// 		+fz(14,20)
		// 		// 		margin-top: 12px
		// //英語の場合
		// // &.en
		// // 	ul
		// // 		li
		// // 			a
		// // 				width: 30px
		// // 				+mqPc
 }		// // 					width: 32px



	// .l-sco-links__item
	// &__item
	/// border-bottom: 1px solid cl(black)
	/// padding: 17px 0 20px
	/// +mqPc
	/// 	padding: 25px 12px 28px
	/// &:first-child
	/// 	border-top: 1px solid cl(black)
	/// a
	/// 	display: inline-block
	/// 	color: cl(key-color)
	/// 	+mqPc
	/// 		&:hover
	/// 			p
	/// 				border-bottom: 1px solid transparent

	/// 	p
	/// 		display: inline
	/// 		border-bottom: 1px solid cl(key-color)
	/// 		word-break: break-word
	/// 		// +easing(.2)

	/// a[href$=".pdf"]
	/// 	position: relative
	/// 	&::after
	/// 		content: 'PDF'
	/// 		+OpenSans
	/// 		+fz(12,12)
	/// 		color: cl(white)
	/// 		background: cl(key-color)
	/// 		padding-left: 12px
	/// 		padding-right: 9px
	/// 		width: 50px
	/// 		height: 18px
	/// 		margin-left: 6px
	/// 		+mqPc
	/// 			vertical-align: 2px

	/// .ttl
	/// 	+JPfont
	/// 	+fz(14,25)
	/// 	+mqPc
	/// 		+fz(16,26)
	/// 	&:not(:first-child)
	/// 		margin-top: 30px
	/// 		+mqPc
	/// 			margin-top: 40px

	/// .details
	/// 	margin-top: 15px
	/// 	+fz(13,23)
	/// 	+mqPc
	/// 		margin-top: 25px
	/// 		+fz(14,27)

	/// .details-link
	/// 	+JPfont
	/// 	color: cl(key-color)
	/// 	+fz(13,21)
	/// 	margin-top: 5px
	/// 	+mqPc
	/// 		+fz(14,20)

	/// 	span
	/// 		border-bottom: 1px solid cl(key-color)

	/// 		&:hover
	/// 			span
	/// 				border-bottom: 1px solid transparent

	/// .link
	/// 	margin-top: 5px
	/// 	+mqPc
	/// 		margin-top: 6px
	/// 	p
	/// 		+fz(13,23)
	/// 		+mqPc
	/// 			+fz(14,20)

	/// .read
	/// 	+fz(13,23)
	/// 	margin-top: 10px
	/// 	+mqPc
	/// 		+fz(14,20)
 }	/// 		margin-top: 12px

.l-sco-contact {
	margin: 15px auto 60px;
	@include fz(14,28);
	@include En();
	@include JPfont;
	@include mqPc {
		margin: 20px auto 110px;
		width: 100%;
		max-width: 820px;
		@include fz(16,32); }
	p + p {
		margin-top: 15px;
		@include mqPc {
			margin-top: 20px; } }
	a {
		color: cl(key-color);
		border-bottom: 1px solid cl(key-color);
		&:hover {
			border-bottom: 1px solid transparent; } }

	.l-sco__detail ~ & {
		// margin-top: -80px
		@include mqPc {
 } } }			// margin-top: -20px

.l-sco-other {
	@include mqPc;
	// .l-sco-other__hdl
	&__hdl {
		padding-bottom: 37px;
		text-align: center;
		@include mqPc {
			padding-bottom: 38px; }
		span {
			display: block; }
		.img {
			margin: 0 auto;
			line-height: 1;
			img {
				width: auto;
				max-width: none;
				height: 31px;
				@include mqPc {
					height: 28px; } } }
		.ja {
			@include fz(12,12);
			@include lsCenter;
			padding-top: 10px;
			@include mqPc {
				@include fz(14,14); } } }

	// .l-sco-other__list
	&__list {
		// width: 100%
		width: calc(100% + 20px);
		margin: 5px -10px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		@include mqPc {
			width: calc(100% + 32px);
			width: 100%;
			max-width: 625px;
			margin: 0 auto; } }

	// .l-sco-other__item
	&__item {
		position: relative;
		z-index: 2;
		padding: 5px;
		margin: 0 10px 15px 10px;
		@include mqPc {
			padding: 7px;
			margin: 0;
			margin: 0 25px 0 0;
			@include easing;
			&:hover {
				&::before {
					width: 180px;
					height: 180px;
					border: 3px solid rgba(cl(dark-blue), .7);
					top: -3px;
					left: -3px; }
				.link {
					background-color: cl(dark-blue); } } }

		&:nth-of-type(1),
		&:nth-of-type(4) {
			@include mqPc {
				margin-left: 25px; } }

		&:nth-of-type(-n+3) {
			@include mqPc {
				margin-bottom: 25px; } }
		// &:not(:last-child)
		// 	+mqPc
		// 		margin: 0 25px 0 0


		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 150px;
			height: 150px;
			border: 2px solid cl(key-color);
			border-radius: 50%;
			top: 0;
			left: 0;
			z-index: -1;
			@include mqPc {
				width: 174px;
				height: 174px;
				@include easing; } }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 18px;
			height: 7px;
			background: url('../image/common/arr_mini_wh.svg') no-repeat;
			background-size: 100% 100%;
			bottom: 22px;
			left: 66px;
			z-index: 1;
			@include mqPc {
				@include easing;
				bottom: 24px;
				left: 80px; } }

		.link {
			display: block;
			width: 140px;
			height: 140px;
			padding: 20px 18px 40px;
			border-radius: 50%;
			background-color: cl(key-color);
			color: cl(white);
			@include mqPc {
				width: 160px;
				height: 160px;
				padding: 25px 24px 70px;
				@include easing; }
			&--en {
				padding: 20px 8px 40px;
				@include mqPc {
					padding: 30px 18px 67px; } } }
		.ttl {
			@include fz(16,16);
			font-weight: 600;
			margin-bottom: 9px;
			text-align: center;
			@include mqPc {
				@include fz(18,18);
				margin-bottom: 10px; }
			&--en {
				margin-bottom: 8px;
				@include mqPc {
					margin-bottom: 10px; } } }
		.txt {
			@include fz(11,14);
			position: relative;
			padding-left: 15px;
			&:not(:last-child) {
				margin-bottom: 3px; }
			@include mqPc {
				@include fz(10,13);
				&:not(:last-child) {
					margin-bottom: 4px; } }
			@include En(.05em,1.2);
			span {
				@include mqPc {
					display: block;
					@include fz(12,24); } }
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background: cl(white);
				top: calc(7px - 1.5px);
				left: 5px;
				@include mqPc {
					top: calc(7.5px - 1.5px); } }
			&--en {
				@include fz(11,14);
				@include mqPc {
					@include fz(11,14); } } } }

	// .l-sco-other__btn
	&__btn {
		width: 100%;
		margin: 33px auto 0;
		@include mqPc {
			max-width: 260px;
			margin: 48px auto 0; }
		.p-btn {
			@include fz(14,14);
			padding: 13px 76px;
			text-align: center;
			@include mqPc {
				@include fz(16,16);
				padding: 17px 76px;
				text-align: right;
				&:hover {
					color: cl(dark-blue);
					&::before {
						border: 2px solid cl(dark-blue);
						height: 32px;
						background: none; }
					&::after {
						background: url('../image/common/arr_dabl.svg') no-repeat;
						left: 25px; } } }
			&::before {
				border: 2px solid cl(dark-blue);
				background: cl(dark-blue);
				height: 40px;
				border-radius: 20px;
				@include mqPc {
					height: 48px;
					border-radius: 24px; } }
			&::after {
				left: 37px;
				@include mqPc {
					left: 48px; } } } } }
