// BASE

// html,body
//--------------------------------------------------------------------------------
html, body {
	width: 100%;
	height: 100%;
	margin: 0; }

// Modal fixed body
html.is-locked {
	height: 100%;
	overflow: hidden; }

body {
	width: 100%;
	@include fz14;
	@include JPfont;
	-webkit-font-smoothing: antialiased;
	color: cl(text);
	letter-spacing: ls(100);
	position: relative;
	@include mqPc {
		@include fz;
		min-width: 1000px; }
	//For Analysis tag
	> iframe,
	> img,
	> div:not([class]) > iframe,
	> div:not([class]) > img {
		display: none; }

	img {
		pointer-events: none; } }



// Hover
//--------------------------------------------------------------------------------
// p
// 	&:not([class])
// 		> a:not([class])
// 			+deco
a {
	color: cl(text);
	text-decoration: none;
 }	// cursor: pointer

li {
	list-style-type: none; }

figure {
	text-align: center; }

img,
video {
	max-width: 100%;
	width: 100%;
	vertical-align: top; }

// button
// 	outline: none

button,
input,
textarea {
	border-radius: 0; }



// object-fit(img,video) * ofi.js
//--------------------------------------------------------------------------------
.cover {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: center top;
	font-family: 'object-fit: cover; object-position: center top;'; }
