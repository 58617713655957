// ORIGINS

.p-origins {
	@include Padding;
	@include mqPc {
		padding-top: 100px; }
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 0 16px;
		@include mqPc {
			max-width: 1060px;
			padding: 0 20px 0 80px; } }
	.p-name {
		&__hdl3 {
			span {
				display: block; }
			.img_name {
				width: percentage(270 / 360);
				margin: 0 auto;
				@include mqPc {
					width: 358px;
					padding-right: 20px; } }
			.ja {
				@include fz(12,12);
				@include lsCenter;
				padding-top: 10px;
				color: cl(main-color);
				@include mqPc {
					@include fz(16,16);
					padding-top: 15px; }
				@include En(); } }
		&__read {
			@include fzm(14,28,70,37);
			@include lsCenter;
			@include mqPc {
				@include fzm(16,32,116,48); }
			@include En(); } }

	.p-logo {
		border: 2px solid cl(main-color);
		position: relative;
		&__ttl {
			position: absolute;
			top: percentage(-8 / 360);
			left: 0;
			right: 0;
			width: 84px;
			margin: 0 auto;
			background: cl(white);
			padding: 0 4px 0 8px;
			@include mqPc {
				width: 140px;
				padding: 0 20px;
				top: percentage(-38 / 960); }
			span {
				display: block; }
			.img {
				margin: 0 auto;
				line-height: 1; }
			.ja {
				@include fz(12,12);
				@include lsCenter;
				padding-top: 15px;
				@include mqPc {
					@include fz(16,16);
					padding-top: 22px; }
				&--eng {
					position: absolute;
					width: 154px;
					left: calc(50% - 77px);
					@include mqPc {
						width: 204px;
						left: calc(50% - 102px); } } } }
		&__desc {
			width: percentage(297 / 328);
			margin: 0 auto;
			padding: percentage(68 / 328) 0 percentage(30 / 328);
			@include mqPc {
				width: 814px;
				padding: 102px 0 75px;
				display: flex;
				justify-content: space-between;
				align-items: center; } }
		&__thumb {
			width: percentage(112 / 296);
			margin-left: percentage(82 / 296);
			@include mqPc {
				width: 218px;
				margin: 0;
				margin-left: 25px; } }
		&__body {
			width: 100%;
			margin: percentage(20 / 296) auto 0;
			@include mqPc {
				width: 480px;
				margin: 0; }
			.logo__read {
				@include fz(14,28);
				@include mqPc {
					@include fz(16,32); }
				@include En(); } } } }
