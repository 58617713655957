// Text

// text-align
//--------------------------------------------------
.u-ta-c {
	text-align: center; }

.u-ta-l {
	text-align: left; }

.u-ta-r {
	text-align: right; }




// text-indent
//--------------------------------------------------
.u-ti-half {
	text-indent: -.5em; }
