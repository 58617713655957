// Vars

// Colors
//--------------------------------------------------------------------------------
$colors: (
	white: #fff,
	black: #000,

	text: #000,
	text-gray: #999,

	main-color: #002e5b,
	key-color: #00c0e9,

	light-blue: #039dd6,
	blue: #1b68b2,
	dark-blue: #0c4892,
	off-white: #010101,
	gray: #7f7f7f,
	light-gray: #eee,
	border-gray: #e5e5e5,
	yellow: #fccb34,
	orange: #fc8626
);

@function cl($key) {
	@return map-get($colors, $key);
}



// Font
//--------------------------------------------------------------------------------
$font-size: (
	base: 16
);

@function font($key) {
	@return map-get($font-size, $key);
}


// Size
//--------------------------------------------------------------------------------
$pc-size: (
	max:   1400,
	base:  1280,
	inner: 1040,
);

$sp-size: (
	base:  360,
	inner: 310,
);

@function widthPc($key) {
	@return map-get($pc-size, $key);
}

@function widthSp($key) {
	@return map-get($sp-size, $key);
}

$maxDiff: widthPc(max) / widthPc(base);//1.09375
$inner-padding-sp:  (widthSp(base) -  widthSp(inner)) / 2;
$inner-padding-pc:  (widthPc(base) -  widthPc(inner)) / 2;
$max-padding-pc: $inner-padding-pc * $maxDiff;

//header
$hdr-height-pc: 136;
$hdr-height-sp: 90;


// Easing
// ----------------------------------------

$easing: (
	// sine
	ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
	ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
	ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),

	// quad
	ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
	ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
	ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),

	// cubic
	ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
	ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
	ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),

	// quart
	ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
	ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
	ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),

	// quint
	ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
	ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1),
	ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),

	// expo
	ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
	ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1),
	ease-in-out-expo: cubic-bezier(1, 0, 0, 1),

	// circ
	ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335),
	ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1),
	ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86),

	// back
	ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
	ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
	ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
);

@function easing($key) {
	@return map-get($easing, $key);
}



// tracking（letter-spacing）
// ----------------------------------------
@function ls($value) {
	@return ($value / 1000) * 1em
}

:root {
	--vw: 1vw
}