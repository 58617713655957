// LINK ICON

// .c-link-icon
//---------------------------------------------------
.c-link-icon,
%c-link-icon {
	@include fz(14,14);
	@include OpenSans;
	color: cl(main-color);
	padding: 9px 65px 9px 0;
	display: inline-block;
	position: relative;
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 32px;
		height: 32px;
		top: 0;
		right: 0;
		border: 2px solid cl(main-color);
		border-radius: 16px;
		@include easing; }
	&__arr {}
	%c-link-icon__arr {
		width: 34px;
		height: 8px;
		position: absolute;
		top: 12px;
		right: 15px;
		@include easing; }
	svg {
		&.c-link-icon__arr--main {
			@extend %c-link-icon__arr;
			fill: cl(main-color); }
		&.c-link-icon__arr--wh {
			@extend %c-link-icon__arr;
			fill: cl(white); } }

	@include mqPc {
		display: block;
		@include easing;
		&:hover {
			letter-spacing: ls(200);
			color: cl(white);
			&::before {
				width: 112%;
				height: 40px;
				top: -4px;
				border-radius: 20px;
				background: cl(main-color);
				z-index: -1; }
			svg {
				&.c-link-icon__arr--main {
					fill: cl(white); } } } }


	// .c-link-icon--wh
	&--wh {
		@extend %c-link-icon;
		@include mqPc {
			&:hover {
				&::before {
					width: 32px;
					height: 32px;
					top: -6.5px;
					background: none; } } }
		&::before {
			top: -6.5px;
			border: 2px solid cl(white); } } }
