// RD

.p-rd {
	@include Padding;
	&__sec {
		padding-top: 48px;
		@include mqPc {
			padding-top: 94px; }
		&:first-child {
			padding-top: 0; } }
	&__ttl {
		@include fz(20,30);
		@include lsCenter;
		color: cl(main-color);
		padding: 0 16px 20px;
		@include mqPc {
			@include fz(28,28);
			padding: 0 20px 40px 80px; }
		@include En(); }
	&__inner {
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;
		@include mqPc {
			max-width: 920px;
			padding-left: 80px;
			padding-right: 20px; } }
	&__read {
		@include fzm(14,28,35,38);
		@include mqPc {
			@include fzm(16,32,50,50); }
		@include En();
		&:first-child {
			margin-top: 0; } }
	&__img {
		width: 100%;
		margin: 0 auto;
		@include mqPc {
			margin: 45px auto; }
		h3 {
			@include fz(12,24);
			letter-spacing: ls(100);
			text-align: center;
			color: cl(main-color);
			margin-bottom: 20px;
			@include mqPc {
				@include fz(16,32); }
			@include En();
			span {
				color: cl(black);
				@include fz(10,20);
				display: block;
				@include mqPc {
					@include fz(14,28); } } }
		figcaption {
			text-align: right;
			@include fz(10,16);
			padding-top: 10px;
			letter-spacing: 0;
			@include mqPc {
				@include fz(12,18);
				padding-top: 15px; } }
		&--01 {
			@include mqPc {
				width: 532px; }
			&.en {
				@include mqPc {
					width: 587px; } } }
		&--02 {
			@include mqPc {
				width: 506px; }
			&.en {
				@include mqPc {
					width: 526px; } } }
		&--03 {
			@include mqPc {
				width: 513px; }
			&.en {
				@include mqPc {
					width: 546px; } } }
		&--04 {
			@include mqPc {
				width: 745px; }
			&.en {
				@include mqPc {
					width: 765px; } } }
		&--05 {
			@include mqPc {
				width: 820px; }
			&.en {
				@include mqPc {
					width: 820px; } } }
		&--06 {
			@include mqPc {
				width: 753px; }
			&.en {
				@include mqPc {
					width: 801px; } } } }
	&__txt {
		@include fz(14,26);
		color: cl(white);
		text-align: center;
		width: 100%;
		margin: 58px auto 0;
		padding: 9px;
		border-radius: 34px;
		background: cl(main-color);
		position: relative;
		letter-spacing: ls(50);
		@include mqPc {
			@include fz(16,30);
			width: 640px;
			margin: 58px auto 0;
			border-radius: 24px; }
		@include En();
		&--en {
			@include mqPc {
				border-radius: 39px; } }
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: -40px;
			left: calc(50% - 18px);
			width: 36px;
			height: 22px;
			background: url('../image/business/graph03_arr.png') no-repeat;
			background-size: 100% 100%;
			@include mqPc {
				top: -46px;
				left: calc(50% - 24px);
				width: 48px;
				height: 29px; } } }


	.p-rd-link {
		width: 100%;
		margin: 48px auto 0;
		padding: 0 16px;
		@include mqPc {
			max-width: 1060px;
			margin: 90px auto 0;
			padding: 0 20px 0 80px; }
		&__wrap {
			margin: 0 auto;
			background: cl(light-gray);
			padding-top: 29px;
			padding-bottom: 41px;
			@include mqPc {
				max-width: 960px;
				padding-top: 60px;
				padding-bottom: 48px; } }
		p {
			@include fz(14,28);
			@include lsCenter;
			padding: 0 41px;
			@include mqPc {
				@include fz(16,16);
				padding: 0; }
			@include En(); }
		&__btn {
			max-width: percentage(296 / 328);
			margin: 29px auto 0;
			@include fz(14,14);
			font-weight: 600;
			padding: 11px 40px !important;
			@include mqPc {
				max-width: 260px;
				@include fz(16,16);
				margin: 35px auto 0;
				padding: 17px 40px !important;
				&:hover {
					color: cl(dark-blue);
					&::before {
						border: 2px solid cl(dark-blue);
						height: 32px; }
					&::after {
						background: url('../image/common/arr_dabl.svg') no-repeat; } } }
			&::before {
				border: 2px solid cl(dark-blue);
				background: cl(dark-blue);
				height: 40px;
				border-radius: 20px;
				@include mqPc {
					height: 48px;
					border-radius: 24px; } }
			&::after {
				right: 25px;
				@include mqPc {
					right: 24px; } }
			&--en {
				@include fz(14,21);
				letter-spacing: .05em;
				@include mqPc {
					@include fz(16,16);
					max-width: 460px; }
				&::before {
					height: 50px;
					border-radius: 25px;
					@include mqPc {
						height: 48px;
						border-radius: 24px; } }
				&:hover {
					letter-spacing: ls(100); } } } } }
