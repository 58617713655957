// BUTTON

// .c-btn
//---------------------------------------------------
.c-btn,
%c-btn {
	display: block;
	@include fz(14,14);
	letter-spacing: ls(100);
	position: relative;
	color: cl(white);
	@include OpenSans;
	border-radius: 20px;
	padding: 13px 12px 13px 24px;
	text-align: center;
	@include mqPc {
		padding: 13px 32px;
		text-align: left;
		@include easing; }
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 40px;
		top: 50%;
		right: 0;
		border: 2px solid cl(black);
		background: black;
		border-radius: 20px;
		transform: translateY(-50%);
		@include easing; }
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 24px;
		height: 8px;
		top: 50%;
		right: 12px;
		background: url('../image/common/arr_sp_wh.svg') no-repeat;
		background-size: 100% 100%;
		transform: translateY(-50%);
		@include easing;
		@include mqPc {
			width: 34px;
			height: 8px;
			right: 26px;
			background: url('../image/common/arr_wh.svg') no-repeat;
			background-size: 100% 100%; } }
	@include mqPc {
		&:hover {
			letter-spacing: ls(200);
			color: cl(key-color);
			&::before {
				width: 32px;
				height: 32px;
				border-radius: 16px;
				border: 2px solid cl(key-color);
				background: none; }
			&::after {
				width: 34px;
				height: 8px;
				right: 26px;
				background: url('../image/common/arr_key.svg') no-repeat;
				background-size: 100% 100%;
				transform: scale(1,1) translate(11px,-50%); } } }
	span {
		display: inline-block;
		position: relative; }


	// .c-btn-wh
	&-wh,
	%c-btn-wh {
		@extend %c-btn;
		color: cl(blue);
		&::before {
			border: 2px solid cl(white);
			background: cl(white); }
		&::after {
			right: 14px;
			background: url('../image/common/arr_sp_bl.svg') no-repeat;
			background-size: 100% 100%; }
		@include mqPc {
			&::after {
				right: 26px;
				background: url('../image/common/arr_bl.svg') no-repeat;
				background-size: 100% 100%; }
			&:hover {
				color: cl(white);
				&::before {
					border: 2px solid cl(white); }
				&::after {
					background: url('../image/common/arr_wh.svg') no-repeat;
					background-size: 100% 100%; } } }
		span {
			padding-right: 47px; } }


	// .c-btn-key
	&-key {
		@extend %c-btn-wh;
		color: cl(white);
		&::before {
			border: 2px solid cl(key-color);
			background: cl(key-color); }
		@include mqPc {
			&:hover {
				color: cl(key-color);
				&::before {
					border: 2px solid cl(key-color); } } } }


	// .c-btn--left
	&--left {
		@extend %c-btn;
		padding: 13px 28px;
		&::before {
			right: auto;
			left: 0; }
		&::after {
			left: 30px;
			background: url('../image/common/arr_sp_wh.svg') no-repeat;
			background-size: 100% 100%;
			transform: scale(-1, 1) translate(11px,-50%); }
		@include mqPc {
			text-align: right;
			padding: 13px 28px;
			&::after {
				left: 40px;
				right: 0;
				background: url('../image/common/arr_wh.svg') no-repeat;
				background-size: 100% 100%;
				transform: scale(-1, 1) translate(11px,-50%); }
			&:hover {
				&::before {
					left: 0;
					border: 2px solid cl(key-color); }
				&::after {
					left: 25px;
					transform: scale(-1,1) translate(11px,-50%); } } }
		span {
			padding-right: 0; } } }
