// SCO-094

.p-sco-094 {
	&__inner {
		position: relative; }

	.p-sco-cat {
		@include mqPc {
			margin-top: 20px; } }

	.p-drawing {
		@include mqPc {
			transform: scale(0.82,0.82); }
		@include mq360 {
			transform: scale(0.9,0.9); }

		// .p-drawing__key
		&__key {
			width: 199px;
			height: 36px;
			border-radius: 18px;
			@include fz(14,36);
			color: cl(white);
			background: cl(key-color);
			text-align: center;
			margin: 0 auto 35px;
			position: relative;
			@include mqPc {
				width: 290px;
				height: 60px;
				border-radius: 30px;
				@include fz(20,60);
				margin: 0 auto 76px; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 172px;
				height: 506px;
				background: url('../image/pipeline/094/arr_act_sp.png') no-repeat;
				background-size: 100% 100%;
				bottom: percentage(-506 / 36);
				left: percentage(93 / 199);
				@include mqPc {
					width: 528px;
					height: 62px;
					background: url('../image/pipeline/094/arr_act.png') no-repeat;
					background-size: 100% 100%;
					bottom: -62px;
					left: -119px; } } }


		// .p-drawing__blue
		&__blue {
			width: 100%;
			margin: 0 auto;
			position: relative;
			@include mqPc {
				width: 760px;
				display: flex;
				justify-content: space-between; }
			div {
				width: 169px;
				height: 30px;
				margin: 0 auto;
				border-radius: 15px;
				@include fz(12,30);
				color: cl(white);
				background: cl(blue);
				text-align: center;
				margin-bottom: 35px;
				position: relative;
				@include mqPc {
					width: 260px;
					height: 50px;
					margin: 0;
					border-radius: 25px;
					@include fz(18,50);
					margin-bottom: 38px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 16px;
					height: 24px;
					background: url('../image/pipeline/094/sym07.png') no-repeat;
					background-size: 100% 100%;
					bottom: -24px;
					left: 77px;
					@include mqPc {
						width: 24px;
						height: 29px;
						background: url('../image/pipeline/arr_short_eff.png') no-repeat;
						background-size: 100% 100%;
						bottom: -29px;
						left: 118px; } }
				&:nth-child(2) {
					display: none;
					@include mqPc {
						display: block; } } }

			// .p-drawing__blue--sp
			&--sp {
				display: block;
				margin-top: 32px;
				@include mqPc {
					display: none; } } }



		// .p-drawing__table
		&__table {
			width: 100%;
			margin: 0 auto 32px;
			position: relative;
			@include mqPc {
				max-width: 968px;
				display: flex;
				justify-content: space-between;
				margin: 0 auto 72px; } }


		// .p-drawing__list
		&__list {
			width: 288px;
			margin: 0 auto;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 4px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: space-between;
			position: relative;
			@include mqPc {
				width: percentage(464 / 968);
				min-width: 440px;
				height: 458px;
				margin: 0;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 35px 20px 37px 0; }
			&:first-child {
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: percentage(20 / 288);
					height: percentage(530 / 352);
					background: url('../image/pipeline/094/arr_eff_sp.png') no-repeat;
					background-size: 100% 100%;
					top: percentage(172 / 352);
					left: percentage(-22 / 288);
					@include mqPc {
						display: none; } } }
			&:last-child {
				@include mqPc {
					padding: 42px 15px 30px 0;
					align-items: center;
					align-content: center;
					.p-drawing__item {
						margin: 0 33px 20px;
						&:nth-child(1) {
							.txt {
								margin-top: 12px; } } } } } }


		// .p-drawing__item
		&__item {
			margin: 15px 12px;
			@include mqPc {
				width: auto;
				margin: 0 10px; }
			.name {
				@include fz(12,12);
				letter-spacing: ls(50);
				text-align: center;
				@include mqPc {
					@include fz(16,16); }
				&--en {
					@include fz(10,13);
					@include mqPc {
						@include fz(14,18); } } }
			.img {
				width: 75px;
				margin: 10px auto 5px;
				@include mqPc {
					width: 100px;
					margin: 10px auto 5px; }
				&--left {
					@include mqPc {
						img {
							margin-left: 5px; } } } }
			&:nth-child(1) {
				.txt {
					margin-top: 0; } }
			&:nth-child(3) {
				margin: 15px 6px;
				@include mqPc {
					margin: 0 0 0 25px; } }
			&:nth-child(4) {
				margin: 15px 6px;
				@include mqPc {
					margin: 0 20px 0 40px; } }
			&:nth-child(5) {
				margin: 15px 6px;
				@include mqPc {
					margin: 0 20px; } }

			.txt-area {
				text-align: center;
				margin: 0 auto;
				&--en {
					.txt--up,
					.txt--down {
						@include fz(10,13);
						@include mqPc {
							@include fz(14,18); } } } }

			.txt-lh {
				@include mqPc {
					margin-top: 16px; }
				&--sp {
					margin-top: 14px;
					@include mqPc {
						margin-top: 16px; } } }


			.txt,
			%txt {
				@include fz(12,12);
				padding: 2px 0;
				padding-right: 22px;
				position: relative;
				letter-spacing: ls(0);
				display: inline-block;
				@include mqPc {
					@include fz(16,20);
					padding-right: 28px; }
				&:last-child {
					margin-right: 0; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 0;
					@include mqPc {
						width: 20px;
						height: 20px;
						top: calc(50% - 10px); } }
				&--up {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_up.png') no-repeat;
						background-size: 100% 100%; } }

				&--down {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_down.png') no-repeat;
						background-size: 100% 100%; } } }

			.txt-mb {
				margin-bottom: 5px; } }


		// .p-drawing__effect
		&__effect {
			width: 288px;
			margin: 0 auto;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 23px 0;
			align-items: center;
			position: relative;
			@include mqPc {
				width: 464px;
				height: 138px;
				margin: 0 auto;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 55px 0; }
			&::before {
				display: none;
				@include mqPc {
					content: '';
					display: block;
					position: absolute;
					width: 512px;
					height: 64px;
					background: url('../image/pipeline/094/arr_eff.png') no-repeat;
					background-size: 100% 100%;
					top: -75px;
					left: -27px; } }

			.txt-area {
				display: flex;
				margin: 0 auto;
				justify-content: center;
				&--en {
					.txt--up,
					.txt--down {
						@include fz(10,13);
						@include mqPc {
							@include fz(14,18); } } } }

			.txt,
			%txt {
				@include fz(14,15);
				padding-right: 22px;
				position: relative;
				letter-spacing: ls(0);
				display: inline-block;
				margin-right: 25px;
				@include mqPc {
					@include fz(16,20);
					padding-right: 28px;
					margin-right: 55px; }
				&:last-child {
					margin-right: 0; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 0;
					@include mqPc {
						width: 20px;
						height: 20px;
						top: calc(50% - 10px); } }
				&--up {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_up.png') no-repeat;
						background-size: 100% 100%; } }

				&--down {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_down.png') no-repeat;
						background-size: 100% 100%; } } } } } }
