// EXECUTIVE

.p-executive {
	width: 100%;
	max-width: 1080px;
	margin: 0 auto;
	padding: 50px 16px 0;
	@include mqPc {
		padding: 100px 20px 20px 80px; }

	.p-unit {
		margin-bottom: 50px;
		@include mqPc {
			display: flex;
			justify-content: space-between;
			margin-bottom: 80px;
			&:nth-child(even) {
				flex-direction: row-reverse; } }
		&__thumb,
		%p-unit__thumb {
			width: 100%;
			line-height: 1;
			@include mqPc {
				width: percentage(392 / 980); } }
		&__body {
			width: 100%;
			padding-top: 27px;
			position: relative;
			@include mqPc {
				width: percentage(524 / 980);
				padding-top: 30px; }
			@include En();
			.title {
				@include fz(12,15);
				@include mqPc {
					@include fz(14,18); } }
			.name-ja {
				@include fz(20,20);
				padding-top: 15px;
				@include mqPc {
					@include fz(28,28); } }
			.name-en {
				@include fz(11,11);
				@include OpenSans;
				position: absolute;
				top: 61px;
				// top: 57px
				right: 0;
				padding-left: 32px;
				padding-top: 0;
				color: cl(black);
				@include mqPc {
					@include fz(12,12);
					top: 71px;
					// top: 68px
					padding-left: 42px; }
				&::before {
					content: '';
					position: absolute;
					width: 24px;
					height: 2px;
					background: cl(black);
					top: 5px;
					left: 0;
					@include mqPc {
						width: 32px;
						height: 2px;
						top: 5px; } } }
			.txt {
				@include fzm(14,28,0,29);
				letter-spacing: ls(116);
				@include mqPc {
					@include fzm(14,28,0,26); }
				@include En(); }
			.link {
				width: 100%;
				margin-top: 42px;
				@include mqPc {
					max-width: 204px;
					margin-top: 38px; }
				&::after {
					right: 24px; } } } } }
