// SCO-272

.p-sco-272 {
	&__inner {
		position: relative; }

	.p-sco-cat {
		@include mqPc {
			margin-top: 30px; } }

	.p-drawing {
		@include mqPc {
			transform: scale(0.82,0.82); }

		// .p-drawing__flex
		&__flex {
			position: relative;
			width: 328px;
			height: 428px;
			margin: 0 auto;
			padding-top: 5px;
			margin-bottom: 6px;
			@include mq360 {
				width: 100%; }
			@include mqPc {
				width: auto;
				display: flex;
				justify-content: center;
				padding-top: 0;
				margin-bottom: 50px; } }


		// .p-drawing__left
		&__left {
			position: absolute;
			top: 148px;
			right: 8px;
			@include mq360 {
				transform: scale(0.9,0.9);
				right: -10px; }
			@include mqPc {
				position: relative;
				margin: 70px 19px 0;
				top: 0;
				right: 0; }
			.main {
				width: 170px;
				height: 30px;
				border-radius: 15px;
				@include fz(12,30);
				letter-spacing: ls(0);
				color: cl(white);
				background: cl(main-color);
				text-align: center;
				position: relative;
				@include mqPc {
					width: 260px;
					height: 50px;
					border-radius: 25px;
					@include fz(16,50);
					margin: 0 auto; }
				&:nth-child(1),
				&:nth-child(2) {
					margin-bottom: 46px;
					@include mqPc {
						margin-bottom: 70px; }
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 15px;
						height: 28px;
						background: url('../image/pipeline/272/sym04.png') no-repeat;
						background-size: 100% 100%;
						bottom: -37px;
						left: 84px;
						@include mqPc {
							width: 25px;
							height: 47px;
							background: url('../image/pipeline/272/sym04.png') no-repeat;
							background-size: 100% 100%;
							bottom: -60px;
							left: 117px; } } }
				&:nth-child(3) {
					&::after {
						display: none;
						@include mqPc {
							content: '';
							display: block;
							position: absolute;
							width: 180px;
							height: 48px;
							background: url('../image/pipeline/272/sym03.png') no-repeat;
							background-size: 100% 100%;
							bottom: -48px;
							left: 126px; } } } }
			.ace1 {
				width: 80px;
				height: 24px;
				border-radius: 12px;
				@include fz(12,24);
				color: cl(white);
				background: cl(light-blue);
				text-align: center;
				position: absolute;
				bottom: 41px;
				left: -93px;
				@include mqPc {
					width: 118px;
					height: 40px;
					border-radius: 20px;
					@include fz(16,40);
					bottom: 133px;
					left: -186px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 17px;
					height: 36px;
					background: url('../image/pipeline/272/sym01.png') no-repeat;
					background-size: 100% 100%;
					transform: rotate(-90deg);
					bottom: -6px;
					right: -4px;
					z-index: -1;
					@include mqPc {
						width: 28px;
						height: 59px;
						bottom: -10px;
						right: -8px; } }
				&--eng {
					width: 85px;
					left: -98px;
					@include fz(10,24);
					letter-spacing: .05em;
					@include mqPc {
						width: 118px;
						left: -186px;
						@include fz(14,40); } } }
			.ace2 {
				width: 48px;
				height: 24px;
				border-radius: 3px;
				@include fz(12,24);
				color: cl(white);
				background: #1bb27b;
				text-align: center;
				position: absolute;
				bottom: 41px;
				left: 10px;
				@include mqPc {
					width: 80px;
					height: 40px;
					border-radius: 6px;
					@include fz(16,40);
					bottom: 133px;
					left: -30px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 39px;
					height: 14px;
					background: url('../image/pipeline/272/sym06.png') no-repeat;
					background-size: 100% 100%;
					bottom: 5px;
					right: -16px;
					z-index: -1;
					@include mqPc {
						width: 67px;
						height: 24px;
						bottom: 8px;
						right: -45px; } } } }


		// .p-drawing__right
		&__right {
			position: absolute;
			left: 0;
			@include mq360 {
				transform: scale(0.9,0.9);
				left: -10px; }
			@include mqPc {
				position: relative;
				margin: 0 19px; }
			.key {
				width: 199px;
				height: 36px;
				border-radius: 18px;
				@include fz(14,36);
				color: cl(white);
				background: cl(key-color);
				text-align: center;
				margin: 0 auto 46px;
				position: relative;
				@include mqPc {
					width: 290px;
					height: 60px;
					border-radius: 30px;
					@include fz(20,60);
					margin: 0 auto 70px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 17px;
					height: 36px;
					background: url('../image/pipeline/272/sym01.png') no-repeat;
					background-size: 100% 100%;
					bottom: -36px;
					left: 91px;
					@include mqPc {
						width: 28px;
						height: 59px;
						bottom: -59px;
						left: 131px; } } }
			.blue {
				width: 169px;
				height: 30px;
				border-radius: 15px;
				@include fz(12,30);
				letter-spacing: ls(50);
				color: cl(white);
				background: cl(blue);
				text-align: center;
				margin: 0 auto 242px;
				position: relative;
				@include mqPc {
					width: 260px;
					height: 50px;
					border-radius: 25px;
					@include fz(18,50);
					margin: 0 0 188px 22px; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 127px;
					height: 100px;
					background: url('../image/pipeline/272/sym03_sp.png') no-repeat;
					background-size: 100% 100%;
					top: 20px;
					left: 82px;
					z-index: -1;
					@include mqPc {
						width: 24px;
						height: 178px;
						background: url('../image/pipeline/272/sym02.png') no-repeat;
						background-size: 100% 100%;
						transform: rotate(-90deg);
						top: -64px;
						left: -70px; } } }
			.main {
				width: 170px;
				height: 30px;
				border-radius: 15px;
				@include fz(12,30);
				letter-spacing: ls(50);
				color: cl(white);
				background: cl(main-color);
				text-align: center;
				position: relative;
				margin: 0 auto;
				@include mqPc {
					width: 260px;
					height: 50px;
					border-radius: 25px;
					@include fz(16,50);
					margin-left: 22px; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 232px;
					background: url('../image/pipeline/272/sym02_sp.png') no-repeat;
					background-size: 100% 100%;
					top: -232px;
					left: 20px;
					@include mqPc {
						width: 24px;
						height: 178px;
						background: url('../image/pipeline/272/sym02.png') no-repeat;
						background-size: 100% 100%;
						top: -178px;
						left: 111px; } }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 42px;
					background: url('../image/pipeline/272/sym07_sp.png') no-repeat;
					background-size: 100% 100%;
					bottom: -38px;
					left: 69px;
					z-index: -1;
					@include mqPc {
						width: 24px;
						height: 37px;
						background: url('../image/pipeline/272/sym07.png') no-repeat;
						background-size: 100% 100%;
						bottom: -37px;
						left: 111px; } } }
			.arb {
				width: 80px;
				height: 24px;
				border-radius: 12px;
				@include fz(12,24);
				color: cl(white);
				background: cl(light-blue);
				text-align: center;
				position: absolute;
				bottom: 3px;
				right: -83px;
				@include mqPc {
					width: 118px;
					height: 40px;
					border-radius: 20px;
					@include fz(16,40);
					bottom: 15px;
					right: -150px; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 17px;
					height: 36px;
					background: url('../image/pipeline/272/sym01.png') no-repeat;
					background-size: 100% 100%;
					transform: rotate(90deg);
					bottom: -6px;
					left: -2px;
					z-index: -1;
					@include mqPc {
						width: 28px;
						height: 59px;
						bottom: -10px;
						left: -12px; } } } }


		// .p-drawing__effect
		&__effect {
			width: 100%;
			max-width: 328px;
			margin: 0 auto;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 28px 0 25px;
			@include mq360 {
				width: 100%; }
			@include mqPc {
				width: 784px;
				max-width: none;
				height: 260px;
				margin: 0 auto;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 40px 0 0; } }


		// .p-drawing__illust
		&__illust {
			width: 300px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			@include mq360 {
				transform: scale(0.9,0.9); }
			@include mqPc {
				width: 554px; }
			.box {}
			.illust {
				width: 75px;
				margin: 0 auto;
				@include mqPc {
					width: 110px; } }
			.txt {
				@include fz(12,12);
				text-align: center;
				margin-top: 13px;
				@include mqPc {
					@include fz(18,18);
					margin-top: 27px; }
				@include En(); } } } }
