// List

// .c-lst-gnav
//--------------------------------------------------
.c-lst-gnav {}





// .c-lst-ast
//--------------------------------------------------
.c-lst-ast {
	padding-left: 1.5em;
	> li {
		&::before {
			content: '\203B';
			display: inline-block;
			width: 1.5em;
			margin-left: -1.5em; } } }
