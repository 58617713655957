// PC or SP
// ----------------------------------------
.u-pc {
	display: none !important;
	@include mqPc {
		display: block !important; } }

.u-sp {
	display: block !important;
	@include mqPc {
		display: none !important; } }

br.u-pc,
span.u-pc {
	display: none !important;
	@include mqPc {
		display: inline !important; } }

br.u-sp,
span.u-sp {
	display: inline !important;
	@include mqPc {
		display: none !important; } }
