// toggle

// .l-toggle
//--------------------------------------------------------------------------------
.l-toggle {
	cursor: pointer;
	background: cl(main-color);
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	@include mqPc {
		position: absolute;
		width: 60px;
		left: 0;
		height: 100vh;
		pointer-events: none;
		.l-index-header & {
			top: 740px; }
		.l-header & {
			top: 350px; } }
	&.is-fixed {
		@include mqPc {
			position: fixed;
			top: 0;
			pointer-events: auto;
			&:hover {
				@include easing;
				background: cl(key-color); } } }
	&.is-open {
		background: cl(key-color);
		.l-toggle__line--top {
			transform: rotate(45deg);
			top: 28px;
			@include mqPc {
				top: 18px; } }
		.l-toggle__line--btm {
			transform: rotate(-45deg);
			top: 28px;
			@include mqPc {
				top: 18px; } }
		@include mqPc {
			background: cl(main-color);
			border-right: 2px solid cl(white);
			@include easing;
			.l-toggle__txt {
				&::after {
					content: 'Close'; } } } }
	&__wrap {
		width: 68px;
		height: 68px;
		position: relative;
		@include mqPc {
			// display: none
			opacity: 0;
			width: 60px;
			height: 90px;
			margin-top: calc((100vh - 90px) / 2);
			transition: .3s easing(ease-out-quint);
 }			// +easing
		.is-fixed & {
			@include mqPc {
				// display: block
				opacity: 1;
				animation: menuOpen .1s linear;
				outline: none; } } }

	&__line--circle {
		display: none;
		@include mqPc {
			content: '';
			display: block;
			position: absolute;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			border: 2px solid cl(white);
			top: 2px;
			left: 14px; } }
	&__line--top {
		@include easing;
		content: '';
		display: block;
		position: absolute;
		background: cl(white);
		width: 28px;
		height: 2px;
		top: 22px;
		left: 20px;
		@include mqPc {
			width: 12px;
			height: 1px;
			top: 15px;
			left: 24px; } }
	&__line--btm {
		@include easing;
		content: '';
		display: block;
		position: absolute;
		background: cl(white);
		width: 28px;
		height: 2px;
		top: 32px;
		left: 20px;
		@include mqPc {
			width: 12px;
			height: 1px;
			top: 21px;
			left: 24px; } }
	&__txt {
		position: relative;
		display: block;
		@include mqPc {
			transform: rotate(90deg);
			margin-right: 3px; }
		&::after {
			content: 'Menu';
			display: block;
			@include fz(10,10);
			@include Lato;
			color: cl(white);
			letter-spacing: ls(100);
			margin-top: 30px;
			font-weight: bold; }
		@include mqPc {
			&::after {
				@include fz(14,14);
				margin-top: 45px; } } } }


// #toggle
// 	cursor: pointer
// 	.is-locked &
// 		background: cl(key-color)
// 		.l-toggle__line--top
// 			transform: rotate(45deg)
// 			top: 28px
// 			+mqPc
// 				top: 18px
// 		.l-toggle__line--btm
// 			transform: rotate(-45deg)
// 			top: 28px
// 			+mqPc
// 				top: 18px
// 		+mqPc
// 			background: cl(main-color)
// 			border-right: 2px solid cl(white)
// 			+easing
// 			.l-toggle__txt
// 				&::after
// 					content: 'Close'
