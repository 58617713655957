// Nav

// .l-nav
//--------------------------------------------------------------------------------
.l-nav {
	background: cl(key-color);
	padding: 16px 0;
	@include mqPc {
		display: flex;
		align-items: center;
		padding: 30px 0; }
	&__inner {
		width: 100%;
		margin: 0 auto;
		position: relative;
		@include mqPc {
			margin: 0 auto;
			display: flex;
			align-items: center;
			padding-left: 60px; } }
	&__ttl {
		display: none;
		@include mqPc {
			display: block;
			line-height: 1;
			padding-left: 40px;
			position: absolute;
			top: calc((60px - 16px) / 2);
			left: 60px; } }
	&__img {
		img {
			width: auto;
			max-width: none;
			height: 15px; }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 30px;
			height: 2px;
			background: cl(white);
			top: 6px;
			left: 0; } }
	&__menu {
		width: percentage(320 / 360);
		margin: 0 auto;
		background: cl(white);
		border-radius: 20px;
		@include mqPc {
			width: 100%;
			background: none;
			border-radius: 0; } }
	&__btn {
		width: 100%;
		margin: 0 auto;
		border-radius: 20px;
		@include mqPc {
			display: none; }
		@include En(.05em,1);
		a {
			display: block;
			padding: 12px 0;
			@include fz(16,16);
			text-align: center;
			color: cl(key-color);
			position: relative;
			@include easing;
			&::after {
				@include easing;
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 9px;
				background: url('../image/common/menu_arr_key.svg') no-repeat;
				background-size: 100% 100%;
				top: 17px;
				right: percentage(25 / 320); } }
		&.is-open {
			a {
				@include easing;
				&::after {
					@include easing;
					transform: scale(-1,-1); } } } }
	&__list {
		display: none;
		width: percentage(272 / 320);
		margin: 0 auto;
		padding: 23px 0 32px;
		border-top: 2px solid cl(key-color);
		@include mqPc {
			background: none;
			width: 100%;
			// max-width: 1200px
			display: flex;
			justify-content: center;
			// justify-content: flex-start
			flex-wrap: wrap;
			border-top: none;
			// padding: 0
			padding: 0 130px; }
		@include mq1280 {
			justify-content: center; }
		&--company {
			@extend .l-nav__list;
			@include mqPc {
				// justify-content: flex-start
				// padding-left: 130px
				// padding-right: 20px
 }				// padding: 0 130px
			// +mq1280
			// 	justify-content: center
 } }			// 	padding: 0

	&__item {
		margin-bottom: 12px;
		text-align: center;
		&:last-child {
			margin-bottom: 0; }
		@include mqPc {
			margin: 10px 0 10px 15px;
			border-radius: 20px;
			overflow: hidden;
			&:last-child {
				margin-bottom: 10px;
 } }				// margin-right: 15px
		.link {
			@include fz(14,14);
			letter-spacing: ls(100);
			color: cl(black);
			position: relative;
			@include En(.05em,1);
			@include mqPc {
				display: block;
				@include fz(16,16);
				color: cl(white);
				padding: 12px 40px;
				&::before {
					position: absolute;
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 20px;
					border: 2px solid cl(white);
					top: 0;
					left: 0; }
				&::after {
					position: absolute;
					content: '';
					display: block;
					width: 101%;
					height: 100%;
					background: cl(white);
					border-radius: 20px;
					top: 0;
					left: -101%;
					z-index: 1;
					@include easing; } } }
		a:not(.is-active) {
			&:hover {
				@include mqPc {
					color: cl(key-color);
					&::after {
						left: 0; } } } }
		.is-active {
			color: cl(key-color);
			@include mqPc {
				color: cl(key-color);
				background: cl(white); } }
		span {
			position: relative;
			z-index: 2; } }



	// .l-nav--gray
	//---------------------------------------------------
	&--gray {
		@extend .l-nav;
		background: cl(light-gray);
		padding: 40px 0 30px 16px;
		.l-nav {
			&__ttl {
				display: block;
				line-height: 1;
				padding-left: 38px;
				@include mqPc {
					line-height: 1;
					padding-left: 40px;
					position: absolute;
					top: calc((60px - 16px) / 2);
					left: 60px; } }
			&__img {
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 26px;
					height: 2px;
					background: cl(main-color);
					top: 6px;
					left: 0;
					@include mqPc {
						width: 30px;
						top: 6px;
						left: 0; } } }
			&__menu {
				width: 100%;
				margin: 23px auto 0;
				background: none;
				border-radius: 0;
				@include mqPc {
					margin: 0 auto; } }
			&__list {
				display: block;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				border-top: none;
				padding: 0;
				@include mqPc {
					border-top: none;
					// justify-content: flex-start
					justify-content: center;
					padding-left: 130px;
					padding-right: 20px; }
				// +mq1280
				// 	justify-content: center
 }				// 	padding: 0
			&__item {
				background: none;
				margin: 10px 16px 10px 0;
				@include mqPc {
					margin: 10px 0 10px 15px;
					border-radius: 20px;
					overflow: hidden;
					&:last-child {
						margin-bottom: 10px; } }
				.link {
					color: cl(main-color);
					padding: 11px 23px;
					border-radius: 20px;
					border: 2px solid cl(main-color);
					display: block;
					@include mqPc {
						@include fz(14,14);
						padding: 10px 40px;
						border-radius: 17px;
						border: none;
						&::before {
							border: 2px solid cl(main-color); }
						&::after {
							background: cl(main-color); } } }
				a:not(.is-active) {
					border-radius: 20px;
					&:hover {
						@include mqPc {
							color: cl(white); } } }
				.is-active {
					color: cl(white);
					background: cl(main-color); } } } } }
