// BUSINESS

.p-business {
	@include mqPc {
		padding-bottom: 100px; }
	.p-thought {
		background: cl(blue);
		position: relative;
		overflow: hidden;

		// .p-thought__inner
		&__inner {
			width: 100%;
			margin: 0 auto;
			padding: percentage(360 / 360) 16px percentage(35 / 360) 16px;
			position: relative;
			@include mqPc {
				width: 100%;
				max-width: 1250px;
				padding: 110px 80px 110px 140px; } }


		// .p-thought__body
		&__body {
			width: 100%;
			color: cl(white);
			@include mqPc {
				width: percentage(512 / 1030); }
			.ttl {
				@include fz(20,20);
				@include mqPc {
					@include fz(28,28); }
				@include En(); }
			.read {
				@include fzm(14,28,0,27);
				font-weight: 600;
				@include mqPc {
					@include fzm(16,35,0,30); }
				@include En(); }
			.p-philosophy {
				&__ttl {
					margin-top: 35px;
					@include mqPc {
						margin-top: 65px; }
					span {
						display: block; }
					.img {
						width: 160px;
						@include mqPc {
							width: 229px; } }
					.ja {
						@include fz(12,12);
						padding-top: 8px;
						@include mqPc {
							@include fz(16,16);
							padding-top: 12px; }
						@include En(); } }
				&__list {
					width: 100%;
					padding-top: 24px;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					@include mqPc {
						padding-top: 18px;
						justify-content: space-between; } }
				&__item {
					width: 74px;
					height: 74px;
					border-radius: 50%;
					border: 2px solid cl(white);
					@include fz(14,18);
					text-align: center;
					margin: 5px 10px 5px 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					&:last-child {
						margin-right: 0; }
					@include mqPc {
						width: 110px;
						height: 110px;
						@include fz(17,22);
						margin: 16px 17px 0 0; }
					@include En(.05em,1.4);
					&--eng {
						@include fz(10,14);
						@include mqPc {
							@include fz(17,22); } } } } }



		// .p-thought__thumb
		&__thumb {
			width: 100%;
			position: absolute;
			top: 45px;
			right: -11px;
			z-index: 1;
			line-height: 1;
			border: 1px solid cl(white);
			padding: 10px;
			@include mqPc {
				width: percentage(554 / 1250);
				min-width: 480px;
				top: 86px;
				right: -23px; } } }


	.p-business-nav {
		margin: 44px 0 34px;
		@include mqPc {
			margin: 100px 0; }
		&__list {
			width: 100%;
			padding: 0 10px;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			align-items: center;
			@include mqPc {
				max-width: 886px;
				padding: 0 20px 0 80px;
				justify-content: space-between; } }
		&__item {
			position: relative;
			z-index: 2;
			padding: 4px;
			margin: 7.5px 0;
			@include mqPc {
				text-align: center;
				padding: 7px;
				margin: 0; }
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 157px;
				height: 60px;
				border: 2px solid cl(dark-blue);
				border-radius: 30px;
				top: 0;
				left: 0;
				z-index: -1;
				@include mq320 {
					width: 146px;
					height: 54px;
					border-radius: 27px; }
				@include mqPc {
					width: 174px;
					height: 174px;
					border-radius: 50%;
					top: 0;
					left: 0;
					@include easing; } }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 8px;
				background: url('../image/common/arr_mini_wh.svg') no-repeat;
				background-size: 100% 100%;
				transform: rotate(90deg);
				bottom: 24px;
				left: 126px;
				z-index: 1;
				@include mqPc {
					width: 18px;
					height: 8px;
					bottom: 29px;
					left: 80px;
					@include easing; } }
			.link {
				display: flex;
				align-items: center;
				width: 149px;
				height: 52px;
				border-radius: 26px;
				background-color: cl(dark-blue);
				padding-left: 15px;
				@include mq320 {
					width: 138px;
					height: 46px;
					border-radius: 23px; }
				@include mqPc {
					display: block;
					width: 160px;
					height: 160px;
					padding: 30px 0 67px;
					border-radius: 50%;
					@include easing; } }
			.num {
				width: 19px;
				height: 14px;
				@include mqPc {
					width: 26px;
					height: 20px; } }
			svg {
				fill: cl(white); }
			.txt {
				@include fz(14,16);
				color: cl(white);
				margin-left: 8px;
				@include mqPc {
					@include fz(18,28);
					padding-top: 15px;
					margin-left: 0;
					@include easing; }
				&--eng {
					@include fz(10,12);
					letter-spacing: 0;
					@include mqPc {
						@include fz(15,23);
						letter-spacing: .05em; } }
				&--eng-sp {
					@include fz(10,12);
					letter-spacing: 0;
					@include mqPc {
						@include fz(18,28);
						letter-spacing: .1em; } } }
			.txt-pt {
				@include mqPc {
					padding-top: 4px; } }
			@include mqPc {
				@include easing;
				&:hover {
					&::before {
						width: 180px;
						height: 180px;
						border: 3px solid rgba(cl(key-color), .5);
						top: -3px;
						left: -3px; }
					&::after {
						bottom: 24px; }
					.link {
						background-color: cl(key-color); } } } } }


	.p-section {
		width: 100%;
		margin: 0 auto 51px;
		padding: 0 16px;
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0; }
		@include mqPc {
			max-width: 1060px;
			padding: 0 20px 80px 80px;
			margin: 0 auto; }
		&__inner {
			@include mqPc {
				display: flex;
				justify-content: space-between; } }
		&__body {
			margin-top: 31px;
			@include mqPc {
				width: percentage(351 / 960);
				padding-top: 17px;
				margin-top: 0; }
			.section__ttl {
				display: flex;
				align-items: center;
				.num {
					width: 32px;
					height: 23px;
					@include mqPc {
						width: 41px;
						height: 30px; } }
				.num-en {
					width: 32px;
					height: 23px;
					@include mqPc {
						width: 50px;
						height: 30px; } }
				svg {
					fill: cl(main-color); }
				.ttl {
					@include fz(18,18);
					color: cl(main-color);
					padding-left: 12px;
					@include mqPc {
						@include fz(24,24);
						padding-left: 16px; } } }
			.section__read {
				@include fzm(14,28,0,19);
				@include mqPc {
					@include fzm(16,32,0,30); }
				@include En(); } }
		&__thumb,
		%p-section__thumb {
			@include mqPc {
				width: percentage(560 / 960);
				line-height: 1; }
			&--pc {
				@extend %p-section__thumb;
				display: none;
				@include mqPc {
					display: block; } }
			&--sp {
				@extend %p-section__thumb;
				display: block;
				@include mqPc {
					display: none; } } } } }
