// SCO-267

.p-sco-267 {
	&__inner {
		position: relative; }

	.p-sco-cat {
		@include mqPc {
			margin-top: 20px; } }

	.p-drawing {
		@include mqPc {
			transform: scale(0.82,0.82); }
		@include mq360 {
			transform: scale(0.9,0.9); }

		// .p-drawing__key
		&__key {
			width: 199px;
			height: 36px;
			border-radius: 18px;
			@include fz(14,36);
			color: cl(white);
			background: cl(key-color);
			text-align: center;
			margin: 0 auto 35px;
			position: relative;
			@include mqPc {
				width: 290px;
				height: 60px;
				border-radius: 30px;
				@include fz(20,60);
				margin: 0 auto 38px; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 25px;
				background: url('../image/pipeline/267/arr_act_sp.png') no-repeat;
				background-size: 100% 100%;
				bottom: -25px;
				left: 92px;
				z-index: -1;
				@include mqPc {
					width: 24px;
					height: 29px;
					background: url('../image/pipeline/arr_short_act.png') no-repeat;
					background-size: 100% 100%;
					bottom: -29px;
					left: 133px; } } }


		// .p-drawing__blue
		&__blue {
			width: 169px;
			height: 30px;
			border-radius: 15px;
			@include fz(12,30);
			color: cl(white);
			background: cl(blue);
			text-align: center;
			margin: 0 auto;
			position: relative;
			@include mqPc {
				width: 260px;
				height: 50px;
				border-radius: 25px;
				@include fz(18,50);
				margin: 0 auto 74px; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 167px;
				height: 344px;
				background: url('../image/pipeline/267/arr_eff_right_sp.png') no-repeat;
				background-size: 100% 100%;
				bottom: -344px;
				left: 82px;
				@include mqPc {
					width: 528px;
					height: 62px;
					background: url('../image/pipeline/267/arr_eff_top.png') no-repeat;
					background-size: 100% 100%;
					bottom: -62px;
					left: -134px; } } }


		// .p-drawing__list
		&__list {
			width: 100%;
			position: relative;
			margin: 0 auto;
			@include mqPc {
				max-width: 968px;
				display: flex;
				justify-content: space-between;
				position: relative;
				margin: 0 auto 77px; } }


		// .p-drawing__item
		&__item {
			width: 288px;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 23px 0;
			margin: 35px auto 0;
			@include mqPc {
				width: percentage(464 / 968);
				height: 232px;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 30px 0;
				margin: 0 auto; }
			.name {
				@include fz(12,12);
				letter-spacing: ls(50);
				text-align: center;
				@include mqPc {
					@include fz(16,16); }
				&--en {
					@include fz(10,13);
					@include mqPc {
						@include fz(14,18); } } }
			.img {
				width: 75px;
				margin: 10px auto 15px;
				@include mqPc {
					width: 100px;
					margin: 10px auto 15px; } }
			.txt-area {
				display: flex;
				margin: 0 auto;
				justify-content: center;
				&--en {
					.txt--up,
					.txt--down {
						@include fz(10,13);
						@include mqPc {
							@include fz(14,18); } } } }
			.txt,
			%txt {
				@include fz(12,12);
				padding-right: 20px;
				margin-right: 20px;
				position: relative;
				letter-spacing: ls(50);
				padding: 2px 0;
				text-align: center;
				@include mqPc {
					@include fz(16,20);
					padding-right: 28px;
					margin-right: 22px; }
				&:last-child {
					margin-right: 0; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 0;
					@include mqPc {
						width: 20px;
						height: 20px;
						top: calc(50% - 10px); } }
				&--up {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_up.png') no-repeat;
						background-size: 100% 100%; } }
				&--down {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_down.png') no-repeat;
						background-size: 100% 100%; } } } }


		// .p-drawing__effect
		&__effect {
			width: 288px;
			margin: 35px auto 0;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding: 26px 0;
			align-items: center;
			position: relative;
			@include mqPc {
				width: 464px;
				height: 138px;
				margin: 0 auto;
				border: 3px solid cl(light-gray);
				border-radius: 10px;
				padding: 55px 0;
				align-items: center; }
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 20px;
				height: 378px;
				background: url('../image/pipeline/267/arr_eff_left_sp.png') no-repeat;
				background-size: 100% 100%;
				bottom: 28px;
				left: -22px;
				@include mqPc {
					width: 512px;
					height: 64px;
					background: url('../image/pipeline/267/arr_eff_btm.png') no-repeat;
					background-size: 100% 100%;
					top: -80px;
					bottom: auto;
					left: -27px; } }
			.txt-area {
				display: flex;
				margin: 0 auto;
				justify-content: center;
				&--en {
					.txt--up,
					.txt--down {
						@include fz(10,13);
						@include mqPc {
							@include fz(14,18); } } } }
			.txt,
			%txt {
				@include fz(14,12);
				padding-right: 22px;
				margin-right: 23px;
				position: relative;
				letter-spacing: ls(50);
				padding: 2px 0;
				@include mqPc {
					@include fz(18,22);
					padding-right: 28px;
					margin-right: 55px; }
				&:last-child {
					margin-right: 0; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 0;
					@include mqPc {
						width: 20px;
						height: 20px;
						top: calc(50% - 10px); } }
				&--up {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_up.png') no-repeat;
						background-size: 100% 100%; } }

				&--down {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_down.png') no-repeat;
						background-size: 100% 100%; } } } } } }
