// NEWS

.p-news {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	position: relative;
	padding: 50px 16px 0;
	@include mqPc {
		padding: 80px 20px 100px 80px; }
	&__ttl {
		padding-bottom: 0;
		.img {
			width: 129px; } } }


// 記事
//--------------------------------------------------
.p-news-article {
	width: 100%;
	margin: 0 auto;
	position: relative;
	padding: 50px 16px 0;
	@include mqPc {
		max-width: 900px;
		padding: 80px 20px 100px 80px; }
	@include En();
	&__head {
		padding-bottom: 20px;
		border-bottom: 2px solid cl(black);
		margin-bottom: 31px;
		@include mqPc {
			padding-bottom: 18px;
			margin-bottom: 40px; }
		h2 {
			@include fz(18,27);
			letter-spacing: ls(100);
			@include mqPc {
				@include fz(24,36); } } }
	&__body {
		padding-bottom: 18px;
		border-bottom: 2px solid cl(black);
		@include mqPc {
			padding-bottom: 20px; }
		p {
			@include fz(14,28);
			margin-bottom: 33px;
			@include mqPc {
				@include fz(16,32);
				margin-bottom: 40px; } }
		img {
			width: auto;
			height: auto; }
		a {
			color: cl(key-color);
			border-bottom: 1px solid cl(key-color);
			padding-bottom: 3px; }

		ul,ol {
			margin: 0 0 33px 20px;
			padding: 0;
			@include fz(13,24);
			@include mqPc {
				margin: 0 0 40px 24px;
				@include fz(16,28); } }
		ul {
			list-style-type: disc; }
		ol {
			list-style-type: decimal; }
		ul li {
			list-style-type: disc; }
		ol li {
			list-style-type: decimal; } }
	&__btn {
		max-width: 100%;
		margin: 50px auto 0;
		@include mqPc {
			max-width: 173px;
			margin: 80px auto 0; }
		.p-btn {
			&::before {
				border: 2px solid cl(dark-blue);
				background: cl(dark-blue); }
			&::after {
				left: 38px; }
			@include mqPc {
				&:hover {
					color: cl(dark-blue);
					&::before {
						border: 2px solid cl(dark-blue); }
					&::after {
						background: url('../image/common/arr_dabl.svg') no-repeat; } } } } } }



// パンくず
//--------------------------------------------------
.p-breadcrumb__item {
	white-space: nowrap; }
