// MESSAGE

.p-message {
	@include Padding;
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 0 16px;
		@include mqPc {
			max-width: 1060px;
			padding: 0 20px 0 80px; } }
	&__txt {
		width: 100%;
		margin: 0 auto;
		@include fz(14,28);
		padding-top: 30px;
		@include mqPc {
			width: percentage(820 / 960);
			@include fz(16,32);
			padding-top: 55px; } }
	&__read {
		margin-bottom: 14px;
		@include mqPc {
			margin-bottom: 23px; } }
	&__name {
		margin-top: 34px;
		@include mqPc {
			margin-top: 46px; }
		.img {
			width: 156px;
			margin-top: 14px;
			@include mqPc {
				width: 206px;
				margin-top: 10px; } } } }
