// POLICY

.p-policy {
	width: 100%;
	margin: 0 auto;
	padding: 50px 16px 0;
	@include mqPc {
		max-width: 920px;
		padding: 70px 20px 100px 80px; }

	&__copy {
		@include fz(14,28);
		@include mqPc {
			@include fz(16,32); }
		@include En(); }

	&__sec {
		margin-top: 41px;
		@include mqPc {
			margin-top: 58px; }
		h2 {
			@include fz(16,16);
			font-weight: 400;
			border-bottom: 2px solid cl(black);
			padding-bottom: 10px;
			margin-bottom: 12px;
			@include mqPc {
				@include fz(20,20);
				padding-bottom: 15px;
				margin-bottom: 22px; }
			@include En(); }
		p {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,32); }
			&.mt {
				margin-top: 22px; }
			@include En(); }
		h3 {
			@include fz(14,28);
			font-weight: 400;
			margin: 24px 0 2px;
			@include mqPc {
				@include fz(16,32);
				margin: 24px 0 2px; }
			&.mt-10 {
				margin-top: 10px; } }
		li {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,32); }
			@include En();
			span {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: cl(black);
				display: inline-block;
				margin-right: 14px;
				margin-bottom: 2px; } }
		.mail {
			color: cl(key-color);
			text-decoration: underline;
			@include easing;
			&:hover {
				text-decoration: none; } } } }
