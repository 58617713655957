// HEADLINE

// .c-hdl-txt
//---------------------------------------------------
.c-hdl-txt {
	padding-top: 25px;
	@include mqPc {
		padding-top: 50px; }
	&__ttl {
		@include fz(20,20);
		@include lsCenter;
		color: cl(main-color);
		padding-top: 25px;
		padding-bottom: 32px;
		@include mqPc {
			@include fz(28,28);
			padding: 50px 20px 46px; }
		@include En(.05em,1);

		&.pipeline {
			position: relative;
			padding-top: 96px; } } }
// .c-hdl-img
//---------------------------------------------------
.c-hdl-img {
	padding-bottom: 37px;
	position: relative;
	@include mqPc {
		padding-bottom: 46px; }
	span {
		display: block; }
	.img {
		margin: 0 auto;
		line-height: 1; }
	.ja {
		@include fz(12,12);
		@include lsCenter;
		padding-top: 15px;
		color: cl(main-color);
		@include mqPc {
			@include fz(16,16);
			padding-top: 22px; } }

	&--h32 {
		@extend .c-hdl-img;
		.img {
			img {
				width: auto;
				max-width: none;
				height: 22px;
				@include mqPc {
					height: 32px; } } } } }
