// KV
.l-index-kv {
	width: 100%;
	height: 490px;
	padding: 153px 0 0;
	position: absolute;
	overflow: hidden;
	z-index: 1;
	@include mqPc {
		height: 740px;
		padding: 248px 0 228px; }

	&__copy {
		width: percentage(337 / 360);
		margin: 0 auto;
		position: relative;
		z-index: 2;
		overflow: hidden;
		@include mqPc {
			width: percentage(1080 / 1280);
			margin-left: percentage(63 / 1080); }
		.en div {
			width: 100%;
			position: relative;
			// &:nth-child(2)
			// 	width: percentage(233 / 296)
			// 	max-width: 233px
			// 	padding-top: 10px
			// &:nth-child(3)
			// 	width: percentage(299 / 296)
			// 	max-width: 299px
			// 	padding-top: 10px
			@include mqPc {
				width: 584px;
				// max-width: 584px
				// &:nth-child(2)
				// 	width: 419px
				// 	max-width: 419px
				// 	padding-top: 0
				// &:nth-child(3)
 } }				// 	display: none
		.ja div {
			width: percentage(133 / 296);
			max-width: 133px;
			background: cl(yellow);
			padding: 5px 12px;
			margin-top: 18px;
			margin-left: 9px;
			line-height: 1;
			position: relative;
			&:nth-child(2) {
				width: percentage(189 / 296);
				max-width: 189px;
				margin-top: 9px; }
			@include mqPc {
				width: 490px;
				max-width: none;
				padding: 9px 24px;
				margin-top: 20px;
				margin-left: 10px;
				&:nth-child(2) {
					display: none; } } } }
	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url('../image/common/bg_kv.png');
		background-size: 100px 173px;
		background-repeat: repeat;
		z-index: 1;
		background-color: rgba(cl(black), .04); }

	&__images {
		width: 100%;
		@include center;
		z-index: 0;
		overflow: hidden;
		.img {
			@include bgCover('../image/top/kv_sp.jpg');
			background-position: 50% 50%;
			height: 490px;
			&.img2 {
				@include bgCover('../image/top/kv2_sp.jpg'); }
			@include mqPc {
				@include bgCover('../image/top/kv.jpg');
				background-position: left top;
				height: 740px;
				&.img2 {
					@include bgCover('../image/top/kv2.jpg'); } } } }

	&__progress {
		position: absolute;
		display: block;
		left: percentage(32 / 360);
		bottom: 35px;
		z-index: 2;
		width: 143px;
		height: 2px;
		background: rgba(cl(black), .07);
		@include mqPc {
			left: percentage(73 / 1080);
			bottom: 86px; }
		.line {
			position: absolute;
			width: 143px;
			height: 2px;
			background: cl(black);
			top: 0;
			left: 0; }
		.point {
			position: relative;
			display: block;
			width: 143px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: cl(black);
				top: -3px;
				left: 0; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: cl(black);
				top: -3px;
				right: 0; } } } }



.l-kv {
	@include relative;
	padding: 34px 16px 46px;
	height: 160px;
	margin-top: 68px;
	@include mqPc {
		padding: 67px 20px 70px 80px;
		height: 240px;
		margin-top: 0; }

	// 1行のとき
	&--en {
		padding: 66px 16px !important;
		@include mqPc {
			padding: 104px 20px 104px 80px !important; } }

	// 2行のとき
	&--en2 {
			padding: 46px 16px 46px;
			letter-spacing: ls(100);
			@include mqPc {
				padding: 80px 20px 80px 80px !important; } }

	&__tag {
		text-align: center;
		position: relative;
		p {
			@include fz(12,12);
			letter-spacing: ls(200);
			color: cl(white);
			text-align: center;
			font-weight: 600;
			display: inline-block;
			padding-left: 40px;
			margin: 0 auto 14px;
			position: relative;
			@include mqPc {
				margin: 0 auto 18px; }
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 26px;
				height: 2px;
				background: cl(white);
				top: 5px;
				left: 0; } } }

	&__ttl {
		line-height: 1;
		font-size: 0;
		text-align: center;
		span {
			display: block; }
		.ja {
			@include fz(24,28);
			color: cl(white);
			text-align: center;
			letter-spacing: ls(200);
			@include mqPc {
				@include fz(32,32); }
			&--en {
				letter-spacing: ls(100); } }
		.en {
			display: block;
			margin: 8px auto 0;
			@include mqPc {
				margin: 15px auto 0; }
			img {
				width: auto;
				max-width: none;
				height: 14px;
				@include mqPc {
					height: 18px; } } }
		.txt {
			@include fz(12,18);
			color: cl(white);
			padding-top: 15px;
			@include mqPc {
				@include f(16); } }
		&--pipeline {
			line-height: 1;
			font-size: 0;
			text-align: center;
			span {
				display: block; }
			.ja {
				@include fz(24,24);
				color: cl(white);
				text-align: center;
				letter-spacing: ls(200);
				@include mqPc {
					@include fz(40,40); } } } }

	&--company {
		@extend .l-kv;
		@include bgCover('../image/corp/kv.jpg'); }
	&--mission {
		@extend .l-kv;
		@include bgCover('../image/mission/kv.jpg'); }
	&--business {
		@extend .l-kv;
		@include bgCover('../image/business/kv.jpg'); }
	&--pipeline {
		@extend .l-kv;
		@include bgCover('../image/pipeline/kv.jpg'); }
	&--news {
		@extend .l-kv;
		@include bgCover('../image/news/kv.jpg'); }
	&--contact {
		@extend .l-kv;
		@include bgCover('../image/contact/kv.jpg'); }
	&--policy,
	&--term-of-use {
		@extend .l-kv;
		@include bgCover('../image/other/kv.jpg'); }


	// 子ページが存在するとき
	&--pipeline,
	&--news,
	&--contact,
	&--term-of-use {
		padding: 58px 16px 56px;
		@include mqPc {
			padding: 98px 20px 70px 80px; } }


	&--sco {
		padding: 50px 16px;
		@include mqPc {
			adding: 98px 20px 70px 80px; } }


	// スマホでタイトルが2行になるとき
	&--policy {
		padding: 45px 16px 38px;
		@include mqPc {
			padding: 98px 20px 70px 80px; } } }
