// Font
//--------------------------------------------------------------------------------

// font-family
//--------------------------------------------------
// NotoSans
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:500');
// OpenSans
@import url('https://fonts.googleapis.com/css?family=Open+Sans:600,700');
// Lato
@import url('https://fonts.googleapis.com/css?family=Lato:400');

@mixin JPfont {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 500; }

@mixin OpenSans {
	font-family: 'Open Sans', sans-serif; }

@mixin Lato {
	font-family: 'Lato', sans-serif; }

// font-size,line-height
//--------------------------------------------------
@mixin f($size:font(base),$lh:$size+8) {
	font-size: $size * 1px;
	font-size: ( $size / 16 ) * 1rem;
	line-height: $lh / $size; }

@mixin fz($size:font(base),$lh:$size*1.5) {
	font-size: $size * 1px;
	font-size: ( $size / 16 ) * 1rem;
	line-height: $lh / $size; }

@mixin f14 {
	@include f(14); }

@mixin f15 {
	@include f(15); }

@mixin f16 {
	@include f(16); }

@mixin f36 {
	@include f(36); }

@mixin fz14 {
	@include fz(14); }

@mixin fz15 {
	@include fz(15); }

@mixin fz16 {
	@include fz(16); }



// letter-spacing, text-align
//--------------------------------------------------
@mixin lsCenter($size:.1em) {
	text-align: center;
	letter-spacing: $size;
 }	// padding-left: $size


// Media Query
//--------------------------------------------------------------------------------
@mixin mq320 {
	@media screen and (max-width: 320px) {
		@content; } }

@mixin mq360 {
	@media screen and (max-width: 359px) {
		@content; } }

@mixin mqSp {
	@media screen and (max-width: 768px) {
		@content; } }

@mixin mqTab {
	@media screen and (min-width: 415px) {
		@content; } }

@mixin mqPc {
	@media screen and (min-width: 769px) {
		@content; } }

@mixin mq1000 {
	@media screen and (min-width: 1001px) {
		@content; } }

@mixin mq1040 {
	@media screen and (min-width: 1041px) {
		@content; } }

@mixin mq1060 {
	@media screen and (min-width: 1061px) {
		@content; } }

@mixin mq1120 {
	@media screen and (min-width: 1121px) {
		@content; } }

@mixin mq1280 {
	@media screen and (min-width: 1281px) {
		@content; } }

// =mqMax
// 	@media screen and (min-width: 1401px)
// 		@content





// Block
//--------------------------------------------------------------------------------
@mixin content {
	// max-width: widthPc(max) + px
	max-width: widthPc(base) + px;
	width: 100%;
	margin: 0 auto; }

@mixin section {
	@include content;
	padding: 0 percentage($inner-padding-sp / widthSp(base));
	@include mqPc {
		padding: 0 percentage($inner-padding-pc / widthPc(base)); }
	//+mqMax
	@include mq1280 {
		padding-left: $inner-padding-pc + px;
		padding-right: $inner-padding-pc + px; } }





// FlexBox
//--------------------------------------------------------------------------------
@mixin flex {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch; }


@mixin flex-end {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: stretch; }





// Position
//--------------------------------------------------------------------------------
@mixin center {
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; }

@mixin relative($zPosition: 1) {
	position: relative;
	z-index: $zPosition; }





// Hover
//--------------------------------------------------------------------------------
@mixin alpha($alpha:.7) {
	@include easing;
	@include mqPc {
		&:hover {
			opacity: $alpha; } } }

@mixin deco {
	text-decoration: underline;
	@include mqPc {
		&:hover {
			text-decoration: none; } } }

@mixin hoverTxt($color: #000, $alpha:.3) {
	@include easing;
	@include mqPc {
		&:hover {
			color: rgba($color, $alpha); } } }

@mixin slash($size:m) {
	a {
		position: relative;
		@include mqPc {
			&:hover {
				&:after {
					@if $size == s {
						transform: rotate(-50deg) scale(1,1) translate(-5px, -32px); }
					@else if $size == l {
						transform: rotate(-50deg) scale(1,1) translate(-20px, -128px); }
					@else {
						transform: rotate(-50deg) scale(1,1) translate(-10px, -64px); } } } }
		&:after {
			content: '';
			@if $size == s {
				width: 40px;
				height: 3px;
				transform: rotate(-50deg) scale(0,1) translate(-5px, -32px); }
			@else if $size == l {
				width: 160px;
				height: 10px;
				transform: rotate(-50deg) scale(0,1) translate(-20px, -128px); }
			@else {
				width: 80px;
				height: 5px;
				transform: rotate(-50deg) scale(0,1) translate(-10px, -64px); }
			background-color: cl(yellow);
			position: absolute;
			top: 50%;
			left: 50%;
			@include easing;
			transform-origin: right center; } }
	span {
		@include relative; } }

@mixin skewBtn($bg-color: cl(yellow), $color: currentColor) {
	a {
		position: relative;
		overflow: hidden;
		@include easing;
		@include mqPc {
			&:hover {
				color: $color;
				&:after {
					width: 200%; } } }
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 100%;
			background-color: $bg-color;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) skew(-40deg);
			@include easing(.4); } }
	span {
		@include relative; } }






// Transition
//--------------------------------------------------------------------------------
// =easing($duration: .3, $ease: easing(ease-in-quad))
// 	transition: $duration + s $ease

@mixin easing($duration: .5, $ease: easing(ease-out-quint)) {
	transition: $duration + s $ease; }



// Clearfix
//--------------------------------------------------------------------------------
@mixin clearfix {
	&:after {
		content: '';
		clear: both;
		display: block;
		height: 0; } }



// `長文時`の行間（line-height）と上下余白（margin）を自動調整します
//--------------------------------------------------------------------------------
// @example
//   font-size: 16px
//   line-height: 1.75(28)
//   margin-bottom: 50px
//   margin-top: 30px
//
//   ↓↓↓↓↓
//
//   @scss - Usage
//   .foo {
//     @include fzm(16, 28, 50, 30)
//   }
//
//   @css - CSS Output
//   .foo {
//     font-size: 1rem;
//     line-height: 1.75;
//     margin-bottom: 44px;
//     margin-top: 24px;
//   }
@mixin fzm($fz: font, $lh: $fz * 2, $mb: 0, $mt: 0) {
	font-size: ($fz / 16) * 1rem;
	line-height: $lh / $fz;
	margin-bottom: $mb - ($fz * ($lh / $fz) - $fz) / 2 + px;
	margin-top: $mt - ($fz * ($lh / $fz) - $fz) / 2  + px; }



// メトリクスを指定します
//--------------------------------------------------------------------------------
@mixin metrics($bool:true) {
	@if $bool == true {
		font-feature-settings : "palt" 1; }
	@if $bool == false {
		font-feature-settings: normal; } }



// 背景画像をcoverにします
//--------------------------------------------------------------------------------
@mixin bgCover($url,$bs:cover) {
	background-image: url($url);
	background-size: $bs;
	background-repeat: no-repeat;
	background-position: 50% 50%; }



// ページのpaddingを指定
//--------------------------------------------------------------------------------
@mixin Padding {
	padding: 50px 0 0;
	@include mqPc {
		padding: 80px 0 100px; } }



// 英語フォント
//--------------------------------------------------------------------------------
@mixin En($ls: .05em, $lh: 1.6) {
	&.en {
		letter-spacing: $ls !important;
		line-height: $lh !important; } }
