// Height

// height
//--------------------------------------------------
.u-h-auto {
	height: auto;
	img {
		height: auto; } }

img.u-h-auto {
	height: auto; }
