// FORM

.c-form {
	padding: 50px 16px 0;
	width: 100%;
	margin: 0 auto;
	@include mqPc {
		max-width: 1060px;
		padding: 80px 20px 80px 100px; }
	&__desc {
		@include fz(14,30);
		padding-bottom: 29px;
		text-align: center;
		border-bottom: 1px solid cl(black);
		@include mqPc {
			@include fz(16,32);
			padding-bottom: 58px; }
		@include En(); }
	&__row {
		width: 100%;
		margin: 0 auto;
		padding: 23px 13px 26px;
		border-bottom: 1px solid cl(black);
		@include mqPc {
			max-width: 960px;
			padding: 21px 20px;
			display: flex;
			justify-content: space-between; }
		@include En();
		.required {
			display: inline-block;
			@include fz(12,12);
			color: cl(light-blue);
			padding-left: 12px; } }
	&__term {
		width: 100%;
		margin-bottom: 14px;
		@include mqPc {
			width: percentage(294 / 920);
			padding: 5px 0 0 8px;
			margin-bottom: 0; } }
	&__input {
		width: 100%;
		word-wrap: break-word;
		@include mqPc {
			width: percentage(626 / 920); }

		input[type="radio"] {
			// display: none
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			border: 0;
			overflow: hidden;
			padding: 0;
			clip: rect(0, 0, 0, 0);

			&:focus + label {
				&::before {
					border-color: #7db9f6;
					box-shadow: 0 0 5px #7db9f6;
					border: 2px solid #7db9f6; } }

			&:checked + label {
				cursor: default; }

			&:checked + label {
				&::before {
					background: cl(white);
					border: 2px solid cl(key-color); }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: cl(key-color);
					top: 7px;
					left: 4px;
					@include mqPc {
						width: 12px;
						height: 12px;
						top: 7px;
						left: 4px; } } } }

		label {
			display: inline;
			@include fz(14,30);
			padding-left: 24px;
			position: relative;
			@include mqPc {
				@include fz(16,34);
				padding-left: 28px;
				cursor: pointer; }
			&:not(:last-of-type) {
				margin-right: 22px; }
			&::before {
				content: '';
				display: block;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 2px solid cl(black);
				position: absolute;
				top: 3px;
				left: 0;
				@include mqPc {
					width: 20px;
					height: 20px;
					top: 3px; } } }

		input[type="text"],
		input[type="tel"], {
			width: 100%;
			@include fz(14,14);
			padding: 12px 1em;
			background: cl(light-gray);
			@include mqPc {
				@include fz(16,16);
				width: 320px;
				padding: 7px 1em; } }

		input[type="text"]#address,
		input[type="email"],
		textarea {
			width: 100%;
			@include fz(14,21);
			padding: 12px 1em;
			background: cl(light-gray);
			@include mqPc {
				@include fz(16,24);
				padding: 7px 1em; } } }


	// .wpcf7-form-control-wrap
	// 	.wpcf7-list-item
	// 		margin: 0 39px 0 0
	// 		&:last-child
	// 			margin-right: 0
	// 	.wpcf7-list-item-label
	// 		+fz(14,30)
	// 		+mqPc
	// 			+fz(16,34)


	&__atten {
		@include fz(13,13);
		color: cl(orange);
		margin-top: 8px; }


	&__btn {
		width: 100%;
		margin: 50px auto 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include mqPc {
			width: 560px;
			margin: 80px auto 0;
			flex-wrap: wrap; }

		input[type="reset"] {
			display: none; }

		.btn-conf,button {
			width: percentage(154 / 328);
			color: cl(white);
			position: relative;
			height: 40px;
			@include fz(14,14);
			@include JPfont;
			@include mqPc {
				width: 256px;
				height: 48px;
				@include fz(16,16);
				cursor: pointer; }
			&::before {
				height: 40px;
				border-radius: 20px;
				@include mqPc {
					height: 48px;
					border-radius: 24px; } }
			&::after {
				right: 13px;
				@include mqPc {
					right: 60px; } }
			&.c-btn {
				transition: none; }
			&.btn-conf {
				padding: 0;
				span {
					margin-right: percentage(54 / 154);
					pointer-events: none;
					position: absolute;
					top: 12px;
					left: 20px;
					@include mqPc {
						@include easing;
						margin-right: 0;
						position: absolute;
						top: 16px;
						left: 65px; } }
				&::before {
					background: cl(dark-blue);
					border: 2px solid cl(dark-blue); } }
			@include mqPc {
				&:hover {
					&::before {
						background: none; }
					&.btn-conf {
						span {
							margin-right: 0;
							color: cl(dark-blue); }
						&::before {
							border: 2px solid cl(dark-blue);
							height: 32px; }
						&::after {
							background: url('../image/common/arr_dabl.svg') no-repeat;
							background-size: 100% 100%;
							right: 26px; } } } }
			input {
				font-size: 0;
				width: 100%;
				height: 100%;
				padding: 13px 12px 13px 24px;
				@include mqPc {
					padding: 24px 100px 24px 65px; } } }


		.btn-cancel {
			width: percentage(154 / 328);
			position: relative;
			@include mqPc {
				width: 256px; }
			&__btn {
				width: 100%;
				background: cl(gray);
				border: 2px solid cl(gray);
				border-radius: 24px;
				overflow: hidden;
				&::before {
					position: absolute;
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 24px;
					border: 2px solid cl(gray);
					top: 0;
					left: 0; }
				&::after {
					position: absolute;
					content: '';
					display: block;
					width: 101%;
					height: 100%;
					background: cl(light-gray);
					border-radius: 24px;
					top: 0;
					left: -101%;
					z-index: 1;
					@include easing; }
				@include mqPc {
					@include easing;
					&:hover {
						color: cl(gray);
						&::after {
							left: 0; } } }
				span {
					position: relative;
					z-index: 2; } } }

		.btn-back {
			// width: percentage(154 / 328)
			background: cl(gray);
			border: 2px solid cl(gray);
			border-radius: 24px;
			overflow: hidden;
			@include mqSp {
				width: 100%; }
			@include mqPc {
				width: 256px; }
			&::before {
				position: absolute;
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 24px;
				border: 2px solid cl(gray);
				top: 0;
				left: 0; }
			&::after {
				position: absolute;
				content: '';
				display: block;
				width: 101%;
				height: 100%;
				background: cl(light-gray);
				border-radius: 24px;
				top: 0;
				left: -101%;
				z-index: 1;
				@include easing; }
			@include mqPc {
				@include easing;
				&:hover {
					color: cl(gray);
					&::after {
						left: 0; } } }
			span {
				position: relative;
				z-index: 2; } }

		.btn-submit {
			width: percentage(154 / 328);
			position: relative;
			@include mqPc {
				width: 256px; }
			&__btn {
				width: 100%;
				color: cl(white);
				position: relative;
				height: 40px;
				padding: 0;
				@include fz(14,14);
				@include JPfont;
				@include mqPc {
					width: 256px;
					height: 48px;
					@include fz(16,16);
					cursor: pointer; }
				span {
					margin-right: percentage(54 / 154);
					pointer-events: none;
					position: absolute;
					top: 12px;
					left: 20px;
					@include mqPc {
						margin-right: 0;
						position: absolute;
						top: 16px;
						left: 65px; } }
				input {
					font-size: 0; }
				&::before {
					background: cl(dark-blue);
					border: 2px solid cl(dark-blue);
					height: 40px;
					border-radius: 20px;
					@include mqPc {
						height: 48px;
						border-radius: 24px; } }
				&::after {
					right: 13px;
					@include mqPc {
						right: 60px; } }
				@include mqPc {
					&:hover {
						span {
							margin-right: 0;
							color: cl(dark-blue); }
						&::before {
							border: 2px solid cl(dark-blue);
							height: 32px; }
						&::after {
							background: url('../image/common/arr_dabl.svg') no-repeat;
							background-size: 100% 100%;
							right: 26px; } } } } } } }
