// TERM OF USE

.p-term-of-use {
	width: 100%;
	margin: 0 auto;
	padding: 50px 16px 0;
	@include mqPc {
		max-width: 920px;
		padding: 70px 20px 100px 80px; }

	&__copy {
		@include fz(14,28);
		padding-bottom: 7px;
		@include mqPc {
			@include fz(16,32);
			padding-bottom: 17px; }
		@include En(); }

	&__sec {
		margin-top: 41px;
		h2 {
			@include fz(16,16);
			font-weight: 400;
			border-bottom: 2px solid cl(black);
			padding-bottom: 10px;
			margin-bottom: 12px;
			@include mqPc {
				@include fz(20,20);
				padding-bottom: 15px;
				margin-bottom: 22px; }
			@include En(); }
		p {
			@include fz(14,25);
			@include mqPc {
				@include fz(16,32); }
			@include En(); } } }
