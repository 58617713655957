// Footer

// .l-footer
//--------------------------------------------------------------------------------
.l-footer {
	position: relative;
	width: 100%;

	// .l-footer__inner
	&__inner {
		display: none;
		@include mqPc {
			max-width: 1250px;
			margin: 0 auto;
			padding: 80px 50px 55px 140px;
			display: flex;
			justify-content: flex-start;
			position: relative;
			height: auto;
			@media screen and (min-width: 1159px) {
				height: 592px; } } }

	// .l-footer__left
	&__left {
		width: 252px;
		a {
			display: block;
			color: cl(black);
			&:first-child {
				width: 240px; }
			// &:last-child
			// 	width: 252px
			// 	margin-top: 620px
			// 	+alpha
			// 	@media screen and (min-width: 1159px)
 }			// 		margin-top: 234px
		.name {
			@include fz(16,16);
			letter-spacing: ls(60);
			margin-top: 18px; }
		&--en {
			a {
				&:first-child {
					width: 240px; }
				&:last-child {
					width: 240px;
					margin-top: 0;
					@include alpha;
					@media screen and (min-width: 1159px) {
						margin-top: 0; } } } } }

	// .l-footer__nav
	&__nav {
		width: percentage(864 / 1250);
		display: flex;
		flex-flow: column wrap;
		margin-top: 16px;
		margin-left: percentage(138 / 1250); }

	// .l-footer__item
	&__item {
		margin-bottom: 48px;
		margin-right: 92px;
		width: 200px;
		&--pipeline {
			width: 350px;
			.en & {
				width: 286px; } }
		p {
			@include fz(17,17);
			color: cl(main-color);
			font-weight: bold;
			a {
				color: cl(main-color);
				@include mqPc {
					@include easing;
					&:hover {
						color: cl(key-color); } } } }
		ul {
			margin-top: 20px; }
		li {
			@include fz(14,21);
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0; }
			a {
				display: inline-block;
				@include easing;
				color: rgba(cl(black),.7);
				position: relative;
				padding-left: 22px;
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 8px;
					height: 1px;
					background: rgba(cl(black),.5);
					top: 11px;
					left: 0; }
				@include mqPc {
					&:hover {
						color: cl(key-color);
						&::before {
							background: cl(key-color); } } }

				&:not([href]) {
					opacity: 0.5;
					pointer-events: none; } }
			@include En(); } }


	// .l-footer__lower
	&__lower {
		width: 100%;
		margin: 0 auto;
		background: cl(main-color);
		padding: 40px 0 17px;
		@include mqPc {
			height: 60px;
			padding: 0; } }

	.l-lower {
		width: 100%;
		margin: 0 auto;
		color: cl(white);
		text-align: center;
		@include mqPc {
			max-width: 1200px;
			text-align: left;
			padding: 24px 20px 0 85px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row-reverse; }
		a {
			@include mqSp {
				margin-top: 15px;
				&:first-of-type {
					margin-top: 0; } } }

		// .l-lower__copyright
		&__copyright {
			@include OpenSans;
			@include fz(10,10);
			letter-spacing: ls(40);
			opacity: .5;
			&:last-child {
				padding-top: 38px; }
			@include mqPc {
				@include fz(13,13);
				&:last-child {
					padding-top: 0; } } }

		// .l-lower__link
		&__link {
			@include fz(12,12);
			letter-spacing: 0;
			a {
				color: cl(white);
				position: relative;
				display: block;
				@include mqPc {
					display: inline;
					padding-right: 30px;
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 2px;
						height: 12px;
						background-color: rgba(cl(white),.2);
						top: 4px;
						right: 13px; }
					&:last-child {
						padding-right: 0;
						&::after {
							display: none; } } } } }

		// .l-lower__blog
		&__blog {
			width: 100%;
			margin: 0 auto;
			padding: 40px 32px 0;
			display: block;
			@include mqPc {
				display: none; } } } }
