// TOP

.p-top {
	padding-top: 490px;
	z-index: 5;
	position: relative;
	@include mqPc {
		padding-bottom: 0;
		padding-top: 740px; }

	.p-special-bio {
		background: cl(key-color);
		position: relative;
		overflow: hidden;

		// .p-special-bio__inner
		&__inner {
			width: 100%;
			margin: 0 auto;
			padding: 45px 16px percentage(250 / 360);
			position: relative;
			@include mqPc {
				width: 100%;
				max-width: 1250px;
				padding: 88px percentage(80 / 1250) 102px percentage(140 / 1250); } }

		// .p-special-bio__body
		&__body {
			width: 100%;
			color: cl(white);
			@include mqPc {
				width: percentage(445 / 1000);
				min-width: 404px; }
			.ttl {
				width: 225px;
				@include mqPc {
					width: 370px; } }
			.read {
				@include fzm(14,28,0,37);
				font-weight: 600;
				@include mqPc {
					@include fzm(16,35,0,45); } } }

		// .p-special-bio__thumb
		&__thumb {
			position: absolute;
			width: percentage(362 / 360);
			top: 320px;
			right: -13px;
			z-index: 1;
			line-height: 1;
			border: 1px solid cl(white);
			padding: 11px;
			@include mqPc {
				width: percentage(624 / 1250);
				top: 148px;
				right: -23px;
				padding: 7px; }
			a {
				display: block; }
			&:hover {
				.p-special-bio__link .c-link-icon {
					letter-spacing: ls(200);
					color: cl(white);
					&::before {
						width: 112%;
						height: 40px;
						top: -4px;
						border-radius: 20px;
						background: cl(main-color);
						z-index: -1; }
					svg {
						&.c-link-icon__arr--main {
							fill: cl(white); } } } }

			// .p-special-bio__thumb--en
			&--en {
				top: 380px;
				@include mqPc {
					top: 148px; } } }

		// .p-special-bio__link
		&__link {
			position: absolute;
			top: 35px;
			// top: 407px
			right: 26px;
			z-index: 2;
			@include mqPc {
				top: 55px;
				right: 67px; } }

		// .p-special-bio__btn
		&__btn {
			width: 100%;
			max-width: 320px;
			margin: 30px 0 0 0;
			display: flex;
			justify-content: space-between;
			z-index: 2;
			@include mqPc {
				width: 370px;
				max-width: 370px;
				margin: 50px 0 0 0; }
			a {
				max-width: 153px;
				color: cl(key-color);
				&::after {
					background: url('../image/common/arr_sp_key.svg') no-repeat !important;
					background-size: 100% 100%; }
				@include mqPc {
					max-width: 173px;
					&::after {
						background: url('../image/common/arr_key.svg') no-repeat !important;
						background-size: 100% 100%; }
					&:hover {
						color: cl(white);
						&::after {
							background: url('../image/common/arr_wh.svg') no-repeat !important;
							background-size: 100% 100%; } } } } } }



	.p-advantage {
		width: 100%;
		background: cl(blue);
		color: cl(white);
		padding: percentage(110 / 360) 0 percentage(60 / 360);
		position: relative;
		@include mqPc {
			padding: 103px 0 95px; }

		// .p-advantage__inner
		&__inner {
			width: 100%;
			margin: 0 auto;
			padding: 0 16px;
			position: relative;
			@include mqPc {
				max-width: 1250px;
				padding: 0 percentage(80 / 1220) 0 percentage(140 / 1220); } }

		// .p-advantage__ttl
		&__ttl {
			@include mqPc {
				width: 100%;
				max-width: 1000px;
				position: absolute;
				top: -116px; }
			span {
				display: block; }
			.img {
				line-height: 1;
				width: 164px;
				@include mqPc {
					width: 190px; } }
			.ja {
				@include fz(12,12);
				padding-top: 15px;
				@include mqPc {
					@include fz(14,14);
					padding-top: 18px; } } }

		// .p-advantage__list
		&__list {
			margin: 25px 0 45px;
			@include mqPc {
				margin: 0; } }

		// .p-advantage__item
		&__item {
			background: cl(blue);
			position: relative;
			margin-bottom: percentage(22 / 328);
			@include mqPc {
				margin: 0 12px 25px; }
			&:last-child {
				margin-bottom: 0; }
			a {
				color: cl(white);
				@include mqPc {
					@include easing;
					&:hover {
						.txt {
							&::after {
								transform: translateY(-50%) translateX(5px); } } } } }
			.txt {
				letter-spacing: ls(0);
				padding: percentage(2 / 328) 30px 0 30px;
				position: relative;
				display: inline-block;
				font-size: 18 / 360 * 100 + vw;
				@include mqTab {
					@include fz(18,27); }
				@include mq1000 {
					@include fz(20,30);
					letter-spacing: ls(100);
					padding: 10px 30px 10px 40px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 5px;
					background: url('../image/common/arr_mini_wh.png') no-repeat;
					background-size: 100% 100%;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					@include mqPc {
						width: 19px;
						height: 7px;
						@include easing; } }
				@include En(); }

			.num {
				@include OpenSans;
				@include fz(16,16);
				position: absolute;
				top: calc(50% + 1px);
				left: 0;
				transform: translateY(-50%); }
			.bg {
				@include fz(36,36);
				opacity: .06;
				position: absolute;
				top: 50%;
				left: -12px;
				letter-spacing: ls(100);
				font-weight: 600;
				user-select: none;
				white-space: nowrap;
				transform: translateY(-50%);
				@include mqPc {
					@include fz(46,46);
					top: 50%;
					left: -15px;
					transform: translateY(-50%); } } }

		// .p-advantage__btn
		&__btn {
			width: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			z-index: 2;
			@include mqPc {
				width: 370px;
				max-width: 370px;
				margin: auto;
				position: absolute;
				bottom: 39px;
				right: 108px; }
			a {
				width: percentage(153 / 328);
				@include mqPc {
					max-width: 173px; } } } }



	.p-news {
		width: 100%;
		margin: 75px auto 60px;
		padding: 0 20px;
		position: relative;
		@include mqPc {
			max-width: 1250px;
			margin: 68px auto;
			padding: 0 percentage(100 / 1250) 0 percentage(150 / 1250); }
		&__inner {
			@include mqPc {
				width: 100%;
				max-width: 920px;
				display: flex;
				align-items: center; } }
		&__ttl {
			padding-bottom: 0;
			@include mqPc {
				margin-right: percentage(110 / 920); }
			.img {
				width: 87px;
				@include mqPc {
					width: 129px; } } }
		&__list {
			margin: 31px 0 40px;
			@include mqPc {
				width: percentage(580 / 920);
				margin: 0; } }
		&__link {
			display: flex;
			z-index: 2;
			@include mqPc {
				position: absolute;
				right: 45px; }
			a {
				margin: 0 auto; } } }


	.p-company-link {
		width: 100%;
		margin: 0 auto;
		@include bgCover('../image/corp/kv.jpg');
		a {
			display: block;
			position: relative;
			@include mqPc {
				@include easing;
				&::after {
					content: '';
					display: block;
					opacity: 0;
					width: 100%;
					height: 100%;
					background-color: cl(main-color);
					@include center;
					@include easing;
					z-index: 1; }
				&:hover {
					&::after {
						opacity: 1; }
					.p-company-link__arr {
						transform: translate(5px); } } } }
		&__inner {
			width: 100%;
			padding: 60px 20px;
			position: relative;
			margin: 0 auto;
			@include mqPc {
				max-width: 1250px;
				padding: percentage(110 / 1250) 0 percentage(80 / 1250) percentage(70 / 1250); }
			&--en {
				@include mqPc {
					padding: percentage(110 / 1250) 0 percentage(110 / 1250) percentage(70 / 1250); } } }
		&__ttl {
			position: relative;
			z-index: 2;
			span {
				display: block; }
			.en {
				display: block;
				width: 136px;
				margin: 0 auto;
				line-height: 1;
				@include mqPc {
					width: 213px; } }
			.ja {
				@include fz(12,12);
				color: cl(white);
				text-align: center;
				padding-top: 15px;
				@include mqPc {
					@include fz(16,16);
					padding-top: 26px; } } }
		&__link {
			display: none;
			@include mqPc {
				position: absolute;
				top: percentage(540 / 1190);
				right: 45px;
				z-index: 2;
				padding: 7px 0;
				display: block; } }
		&__arr {
			top: 6px !important; } } }

