// cookie

// .l-cookie
//--------------------------------------------------------------------------------
.l-cookie {
	width: 100%;
	position: fixed;
	bottom: 0;
	right: auto;
	left: auto;
	z-index: 10;
	background: rgba(cl(black), .8);
	color: cl(white);
	@include mqPc {
		width: 360px;
		bottom: 20px;
		right: 20px; }

	// .l-cookie__inner
	&__inner {
		padding: 20px 30px 30px; }

	// .l-cookie__read
	&__read {
		@include fz(14,28);
		margin-bottom: 20px;
		@include mqPc {
			@include fz(16,32); }
		.link {
			color: rgba(cl(white), .8);
			text-decoration: underline; } }

	// .l-cookie__btn
	&__btn {
		@include fz(14,28);
		padding: 5px 20px;
		background: cl(key-color);
		color: cl(white);
		display: block;
		text-align: center;
		@include mqPc {
			@include fz(16,32);
			&:hover {
				cursor: pointer; } } }

	&.close {
		display: none; } }
