// Header

// .l-index-header
//--------------------------------------------------------------------------------
.l-index-header {
	width: 100%;
	height: 68px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include easing;
	@include mqPc {
		height: 110px;
		// position: relative
		position: absolute;
		display: block;
		min-width: 1000px;
		box-shadow: inset -50px 50px 80px -30px cl(white); }
	.is-locked & {
		background: cl(white);
		@include mqPc {
			position: relative;
			width: 100%;
			height: 100vh;
			background: cl(main-color);
			transition: none; } } }



// .l-header
//--------------------------------------------------------------------------------
.l-header {
	@extend .l-index-header;
	background: cl(white);
	@include mqPc {
		position: relative !important; }


	// .l-header__bg
	&__bg {
		@include mqPc {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-image: url('../image/common/bg.png');
			background-size: 100px 173px;
			background-repeat: repeat;
			z-index: 1; } }


	// .l-header__img
	&__img {
		display: none;
		@include mqPc {
			width: percentage(360 / 1280);
			max-width: 360px;
			height: 100vh;
			@include bgCover('../image/common/menu_img.jpg');
			position: fixed;
			top: 0;
			right: 0;
			z-index: 1;
			opacity: 0;
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 333px;
				height: 422px;
				background: url('../image/common/menu_txt.png');
				background-size: 100% 100%;
				top: percentage(179 / 780);
				left: -20px; } } }


	// .l-header__menu-bg
	&__menu-bg {
		@include mqPc {
			width: 100%;
			height: 100%;
			@include center;
			transform: translateX(-101%);
			background: cl(main-color);
			z-index: -1;
			position: fixed; } } }
