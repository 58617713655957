// BLOG

.p-blog {
	.p-head {
		padding: 25px 15px 40px;
		background: cl(main-color);
		@include mqPc {
			padding: 46px 85px 46px 75px;
			display: flex;
			justify-content: space-between; }

		// .p-head__ttl
		&__ttl {
			span {
				display: block; }
			.ttl {
				@include fz(20,20);
				color: cl(white);
				letter-spacing: ls(120);
				@include mqPc {
					@include fz(26,26); } }
			.img {
				width: 151px;
				margin-top: 11px;
				font-size: 0;
				@include mqPc {
					width: 185px;
					margin-top: 18px; } } }

		// .p-head__logo
		&__logo {
			&--sp {
				display: block;
				width: 122px;
				height: 25px;
				margin-bottom: 14px;
				@include mqPc {
					display: none; } }
			&--pc {
				display: none;
				@include mqPc {
					display: block;
					width: 170px;
					height: 35px;
					margin-top: 11px; } } } }


	// .p-blog__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 38px 16px 80px;
		@include mqPc {
			max-width: 1120px;
			padding: 80px 20px 100px;
			display: flex;
			justify-content: space-between; } }


	.p-article {
		width: 100%;
		@include mqPc {
			width: 800px;
			margin-right: 40px; } }


	.p-section {
		border-bottom: 2px solid cl(black);
		padding-top: 40px;
		@include mqPc {
			padding-top: 80px; }
		&:first-child {
			padding-top: 0; }

		// .p-section__ttl
		&__ttl {
			font-weight: 500;
			span {
				display: block; }
			.date {
				@include fz(12,12);
				color: cl(text-gray);
				margin-bottom: 21px;
				@include mqPc {
					@include fz(16,16);
					margin-bottom: 21px; } }
			.ttl {
				@include fz(18,27);
				color: cl(blue);
				margin-bottom: 24px;
				@include mqPc {
					@include fz(24,36);
					margin-bottom: 40px; } } }

		// .p-section__read
		&__read {
			p {
				@include fzm(14,28,40,0);
				@include mqPc {
					@include fzm(16,32,46,0); } }
			// figure
			// 	width: 100%
			// 	margin-bottom: 34px
			// 	+mqPc
			// 		width: 380px
			// 		margin-bottom: 43px
			img {
				width: auto;
				height: auto; }
			a {
				color: cl(key-color);
				border-bottom: 1px solid cl(key-color);
				padding-bottom: 3px; }

			ul,ol {
				margin: 0 0 33px 20px;
				padding: 0;
				@include fz(13,24);
				@include mqPc {
					margin: 0 0 40px 24px;
					@include fz(16,28); } }
			ul {
				list-style-type: disc; }
			ol {
				list-style-type: decimal; }
			ul li {
				list-style-type: disc; }
			ol li {
				list-style-type: decimal; } }

		// .p-section__share
		&__share {
			display: flex;
			align-items: center;
			margin-top: 27px;
			margin-bottom: 40px;
			@include mqPc {
				margin-top: 48px;
				margin-bottom: 80px; }
			.img {
				width: 50px;
				margin-right: 26px;
				line-height: 0;
				@include mqPc {
					width: 49px;
					margin-right: 22px; } }
			a {
				display: block;
				width: 24px;
				height: 24px;
				margin-right: 22px;
				@include alpha;
				&:last-child {
					margin-right: 0; } } } }


	// 右側
	//--------------------------------------------------
	.p-side {
		width: 100%;
		@include mqPc {
			width: 200px; } }


	// .p-profile
	// 	margin-bottom: 45px
	// 	+mqPc
	// 		margin-bottom: 70px

	// 	// .p-profile__ttl
	// 	&__ttl
	// 		+fz(18,18)
	// 		letter-spacing: ls(200)
	// 		text-align: center
	// 		padding-bottom: 13px
	// 		border-bottom: 2px solid cl(black)
	// 		+mqPc
	// 			+fz(16,16)
	// 			padding-bottom: 17px

	// 	// .p-profile__thumb
	// 	&__thumb
	// 		margin: 24px 0 12px
	// 		+mqPc
	// 			margin: 24px 0 14px

	// 	// .p-profile__txt
	// 	&__txt
	// 		+fz(14,28)
	// 		width: 100%
	// 		margin: 0 auto
	// 		+mqPc
	// 			width: 188px


	.p-past {
		border-bottom: 1px solid cl(black);

		// .p-past__ttl
		&__ttl {
			@include fz(18,18);
			letter-spacing: ls(200);
			text-align: center;
			padding-bottom: 13px;
			border-bottom: 2px solid cl(black);
			@include mqPc {
				@include fz(16,16);
				padding-bottom: 17px; } }

		// .p-past__year
		&__year {
			border-top: 1px solid cl(black);
			position: relative;
			@include fz(14,14);
			color: cl(black);
			display: block;
			font-weight: bold;
			padding: 23px 15px;
			@include mqPc {
				@include fz(16,16);
				padding: 18px 8px;
				cursor: pointer; }
			&.first {
				border-top: none; }
			.link-icon {
				position: absolute;
				width: 22px;
				height: 22px;
				border-radius: 50%;
				border: 2px solid cl(key-color);
				top: 20px;
				right: 16px;
				z-index: -1;
				@include mqPc {
					top: 16px;
					right: 8px; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 10px;
					height: 2px;
					background: cl(key-color);
					top: 8px;
					left: 4px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 10px;
					height: 2px;
					background: cl(key-color);
					transform: rotate(90deg);
					top: 8px;
					left: 4px;
					@include easing; } }
			&.is-open {
				border-bottom: none;
				color: cl(key-color);
				padding: 23px 15px 14px;
				@include mqPc {
					padding: 18px 8px 13px; }
				.link-icon {
					background: cl(key-color);
					&::before {
						background: cl(white); }
					&::after {
						background: cl(white);
						transform: rotate(0); } } } }

		// .p-past__month
		&__month {
			display: none;
			padding-bottom: 20px;
			&.is-open {
				display: block; } }

		// .p-past__item
		&__item {
			margin-bottom: 9px;
			@include mqPc {
				margin-bottom: 4px; }
			&:last-child {
				margin-bottom: 0; }
			a {
				@include fz(14,14);
				color: cl(main);
				margin: 0 27px;
				@include mqPc {
					margin: 0 20px;
					@include easing;
					&:hover {
						color: cl(key-color); } } } } } }
