// MEMBER

html.no-scroll {
	height: 100%;
	overflow: hidden; }


.p-member {
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
	padding: 62px 16px 0;
	@include mqPc {
		padding: 114px 20px 80px 80px; }


	.p-conducts {
		margin-bottom: 50px;
		@include mqPc {
			margin-bottom: 100px; }
		&__inner {
			border: 2px solid cl(main-color);
			position: relative;
			padding: 77px 0 36px;
			@include mqPc {
				padding: 110px 0 48px; }
			&--eng {
				padding: 45px 0 36px;
				@include mqPc {
					padding: 70px 0 48px; } } }

		&__ttl {
			position: absolute;
			top: percentage(-10 / 360);
			left: 0;
			right: 0;
			width: 280px;
			margin: 0 auto;
			background: cl(white);
			padding: 0 5px;
			@include mqPc {
				width: 433px;
				top: percentage(-38 / 960);
				padding: 0 20px; }
			span {
				display: block; }
			.img {
				margin: 0 auto;
				line-height: 1; }
			.ja {
				@include fz(12,12);
				@include lsCenter;
				padding-top: 15px;
				@include mqPc {
					@include fz(16,16);
					padding-top: 20px; } } }
		&__copy {
			p {
				@include fzm(20,30,29,0);
				text-align: center;
				letter-spacing: ls(50);
				color: cl(key-color);
				@include mqPc {
					@include fzm(30,54,50,0);
					letter-spacing: ls(100); }
				@include En(); }
			&--eng {
				p {
					padding: 0 5px;
					@include mqPc {
						padding: 0; } } } }
		&__list {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			@include mqPc {
				width: 652px;
				justify-content: space-between; } }
		&__item {
			width: 110px;
			height: 110px;
			border-radius: 50%;
			background: cl(main-color);
			position: relative;
			margin: 0 10px;
			@include mqPc {
				width: 180px;
				height: 180px;
				margin: 0; }
			svg {
				fill: cl(white); }
			.num {
				width: 20px;
				height: 14px;
				position: absolute;
				top: 17px;
				left: 45px;
				@include mqPc {
					width: 28px;
					height: 20px;
					top: 50px;
					left: 76px; } }
			.txt {
				color: cl(white);
				text-align: center;
				@include fz(14,23);
				padding-top: 42px;
				@include mqPc {
					@include fz(18,27);
					padding-top: 90px; }
				&--line2 {
					@include mqPc {
						padding-top: 76px; } }
				@include En();
				&--eng {
					@include fz(14,20);
					letter-spacing: ls(0);
					@include mqPc {
						@include fz(18,27);
						letter-spacing: ls(100); } } } }

		&__desc {
			@include fz(14,21);
			margin-top: 20px;
			margin: 0 auto;
			padding: 0 19px;
			@include mqPc {
				@include fz(14,30);
				@include lsCenter;
				margin-top: 42px; }
			@include En();
			span {
				color: cl(white);
				background: cl(key-color);
				padding: 2px 0;
				border-radius: 15px;
				display: block;
				width: 137px;
				margin: 0 auto 12px;
				text-align: center;
				@include mqPc {
					display: inline;
					margin-right: 15px;
					padding: 5px 10px; }
				&.eng {
					width: 167px; } } } }


	// .p-member__dsc
	&__dsc {
		margin-bottom: 32px;
		@include mqPc {
			margin-bottom: 55px; } }

	.p-dsc {
		&__hdl {
			@include fz(18,18);
			color: cl(main-color);
			font-weight: 600;
			margin-bottom: 20px;
			@include mqPc {
				@include fz(24,24);
				margin-bottom: 25px; }
			@include En(); }
		p {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,32); }
			@include En(); } }


	.p-ceo {
		@include mqPc {
			display: flex;
			justify-content: space-between; }
		&__thumb {
			width: 100%;
			line-height: 1;
			@include mqPc {
				width: percentage(408 / 1020); } }
		&__body {
			width: 100%;
			padding-top: 27px;
			position: relative;
			@include mqPc {
				width: percentage(563 / 1020);
				padding-top: 10px; }
			@include En();
			.title {
				@include fz(12,12);
				@include mqPc {
					@include fz(14,14); } }
			.name-ja {
				@include fz(20,20);
				padding-top: 13px;
				@include mqPc {
					@include fz(28,28);
					padding-top: 15px; } }
			.name-en {
				@include fz(11,11);
				@include OpenSans;
				padding-left: 32px;
				color: cl(black);
				position: absolute;
				top: 56px;
				right: 0;
				@include mqPc {
					@include fz(12,12);
					top: 45px;
					right: 0;
					padding-left: 42px;
					padding-top: 0; }
				&::before {
					content: '';
					position: absolute;
					width: 24px;
					height: 2px;
					background: cl(black);
					top: 5px;
					left: 0;
					@include mqPc {
						width: 32px;
						top: 5px; } } }
			.txt {
				@include fzm(14,28,40,30);
				letter-spacing: ls(116);
				@include mqPc {
					@include fzm(14,28,48,26); }
				@include En(); }
			.link {
				max-width: 100%;
				@include mqPc {
					max-width: 204px; }
				&::after {
					right: 24px; }
				span {
					padding-right: 12px; } } } }


	// .p-member__wrap
	&__wrap {
		margin-top: 48px;
		@include mqPc {
			margin-top: 86px; } }


	// .p-member__hdl
	&__hdl {
		@include fz(18,24);
		color: cl(main-color);
		font-weight: 600;
		padding-top: 48px;
		margin-bottom: 15px;
		@include mqPc {
			@include fz(24,24);
			padding-top: 80px;
			margin-bottom: 0;
			padding-bottom: 60px; }
		@include En(); }


	// .p-member__sec
	&__sec {
		margin: 0;
		&--line {
			@extend .p-member__sec;
			border-top: 2px solid cl(main-color);
			margin: 50px 0;
			@include mqPc {
				margin: 80px 0; } } }
	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 8px;
		@include mqPc {
			justify-content: flex-start;
			margin-top: 0; } }
	&__item {
		width: percentage(156 / 328);
		margin-top: 38px;
		@include mqPc {
			width: percentage(308 / 1020);
			margin-top: 80px;
			&:not(:nth-child(3n)) {
				margin-right: percentage(48 / 1020); } }
		&--mt {
			margin-top: 0 !important;
			@include mqPc {
				margin-top: 0 !important; } }
		&--mtsp {
			margin-top: 38px;
			@include mqPc {
				margin-top: 0 !important; } }
		a {
			display: block;
			cursor: pointer;
			@include easing;
			&:hover {
				opacity: .8;
				.p-member__body {
					.icon {
						border: 2px solid cl(key-color);
						.line {
							background: cl(key-color); } } } } } }
	&__tag {
		@include fz(12,16);
		margin-bottom: 10px;
		padding-left: 15px;
		position: relative;
		@include mqPc {
			@include fz(14,19);
			margin-bottom: 14px;
			padding-left: 20px;
			&::before {
				top: 3px;
				width: 12px;
				height: 12px; } }
		@include En();
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 3px;
			left: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%; }
		&--en {
			&::before {
				top: 3px;
				@include mqPc {
					top: 6px; } } }
		&--main {
			@extend .p-member__tag;
			color: cl(main-color);
			&::before {
				background-color: cl(main-color); } }
		&--key {
			@extend .p-member__tag;
			color: cl(key-color);
			&::before {
				background-color: cl(key-color); } }
		&--orange {
			@extend .p-member__tag;
			color: #fc8626;
			letter-spacing: ls(0);
			&::before {
				background-color: #fc8626; } } }
	&__thumb {
		line-height: 1; }
	&__body {
		position: relative;
		margin-top: 16px;
		@include mqPc {
			margin-top: 24px; }
		.title {
			@include fz(12,16);
			@include mqPc {
				@include fz(14,21); } }
		.name-ja {
			@include fzm(16,24,8,12);
			word-wrap: break-word;
			width: percentage(128 / 156);
			@include mqPc {
				@include fzm(20,30,8,8);
				width: auto; }
			@include En(); }
		.name-en {
			@include fz(11,14);
			@include OpenSans;
			color: cl(text-gray);
			width: percentage(130 / 156);
			@include mqPc {
				@include fz(12,12);
				width: auto; } }
		.icon {
			position: absolute;
			width: 24px;
			height: 24px;
			border: 2px solid cl(black);
			border-radius: 50%;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include mqPc {
				width: 32px;
				height: 32px;
				border: 2px solid cl(black);
				@include easing; }
			.line {
				position: absolute;
				content: '';
				display: block;
				width: 12px;
				height: 2px;
				top: 9px;
				left: 4px;
				background: cl(black);
				&:last-child {
					transform: rotate(90deg); }
				@include mqPc {
					width: 16px;
					height: 2px;
					top: 13px;
					left: 6px;
					@include easing; } } } }


	// .p-member__other
	// .p-other
	.p-other {
		margin-top: 40px;
		@include mqPc {
			margin-top: 64px; }
		&__body {
			margin-top: 10px;
			@include mqPc {
				margin-top: 21px; }
			.name-ja {
				width: 100%; }
			.txt {
				@include fz(12,18);
				margin-top: 5px;
				@include mqPc {
					@include fz(16,24);
					margin-top: 12px; }
				@include En(); } } }






	// モーダル
	//--------------------------------------------------
	#overlay {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(cl(black), .8);
		display: none;
		z-index: 100;
		overflow-x: scroll;
		.p-modal {
			width: 100%;
			margin: 0 auto;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			@include mqPc {
				width: percentage(1120 / 1280);
				max-width: 1120px;
				min-width: 1000px; }

			&__wrap {
				position: relative; }

			&__close-btn {
				position: absolute;
				width: 40px;
				height: 40px;
				border: 2px solid cl(black);
				border-radius: 50%;
				top: 16px;
				right: 16px;
				cursor: pointer;
				z-index: 2;
				@include mqPc {
					width: 32px;
					height: 32px;
					top: 30px;
					right: 30px;
					@include easing;
					&:hover {
						border: 2px solid cl(key-color);
						.line {
							background: cl(key-color); } } }

				.line {
					position: absolute;
					content: '';
					display: block;
					width: 20px;
					height: 2px;
					background: cl(black);
					top: 17px;
					left: 8px;
					&:first-child {
						transform: rotate(45deg); }
					&:last-child {
						transform: rotate(-45deg); }
					@include mqPc {
						width: 16px;
						height: 2px;
						top: 13px;
						left: 6px;
						@include easing; } } }
			&__list {
				width: 100%;
				margin: 0 auto;
				background: cl(white);
				height: 100vh;
				line-height: 1;
				@include mqPc {
					padding: 80px 60px;
					height: auto; } }
			&__item {
				display: none;
				&.is-active {
					display: block;
					position: relative;
					height: calc(100vh - 50px);
					overflow-y: scroll;
					@include mqPc {
						height: auto;
						display: flex;
						justify-content: space-between;
						overflow-y: visible; } } }
			&__thumb {
				width: percentage(328 / 360);
				margin: 76px auto 0;
				line-height: 1;
				@include mqPc {
					width: percentage(408 / 1000);
					margin: 0; } }
			&__body {
				width: percentage(328 / 360);
				margin: 0 auto;
				padding-top: 28px;
				position: relative;
				@include mqPc {
					width: percentage(542 / 1000);
					padding-top: 14px;
					margin: 0; }
				@include En();
				.title {
					// +fz(12,15)
					// +mqPc
					// 	+fz(14,18)
					@include fz(12,16);
					@include mqPc {
						@include fz(14,21); } }
				.name-ja {
					@include fz(20,30);
					padding-top: 10px;
					@include mqPc {
						@include fz(28,28);
						padding-top: 15px; } }
				.name-en {
					@include fz(11,11);
					@include OpenSans;
					padding-left: 32px;
					color: cl(black);
					position: absolute;
					top: 59px;
					right: 5px;
					@include mqPc {
						@include fz(12,12);
						@include OpenSans;
						position: absolute;
						top: 50px;
						right: 0;
						padding-top: 0;
						padding-left: 42px; }
					&::before {
						content: '';
						position: absolute;
						width: 24px;
						height: 2px;
						background: cl(black);
						top: 5px;
						left: 0;
						@include mqPc {
							width: 32px;
							top: 5px; } } }
				.txt {
					@include fzm(12,24,48,26);
					letter-spacing: ls(116);
					@include mqPc {
						@include fzm(14,28,48,26); }
					@include En(); } } } } }
