// Hover

.u-hvr-thumb {
	a {
		@include mqPc {
			&:hover {
				img {
					transform: scale(1.05); } } } }
	//.u-hvr-thumb__img
	&__img {
		overflow: hidden;
		img {
			@include easing; } } }
