// 404

.p-error {
	width: 100%;
	margin: 0 auto;
	padding: 50px 16px 0;
	@include mqPc {
		max-width: 920px;
		padding: 90px 20px 120px 80px; }

	&__kv {
		.en {
			@include fz(14,28);
			color: cl(white);
			letter-spacing: ls(200);
			text-align: center;
			font-weight: 500;
			@include mqPc {
				@include fz(16,16); } } }

	&__txt {
		@include fz(14,28);
		padding-bottom: 7px;
		text-align: center;
		@include mqPc {
			@include fz(16,32);
			padding-bottom: 17px; } }

	.btn-back {
		width: 250px;
		margin: 30px auto 0;
		@include fz(14,14);
		font-weight: 600;
		text-align: right;
		padding: 11px 40px !important;
		@include mqPc {
			width: 300px;
			@include fz(16,16);
			margin: 40px auto 0;
			padding: 17px 45px !important;
			&:hover {
				color: cl(dark-blue);
				&::before {
					border: 2px solid cl(dark-blue);
					height: 32px; }
				&::after {
					left: 13px;
					transform: scale(-1,1);
					background: url('../image/common/arr_dabl.svg') no-repeat; } } }
		&::before {
			border: 2px solid cl(dark-blue);
			background: cl(dark-blue);
			height: 40px;
			border-radius: 20px;
			right: auto;
			left: 0;
			@include mqPc {
				height: 48px;
				border-radius: 24px; } }
		&::after {
			right: auto;
			left: 25px;
			transform: scale(-1,1);
			top: calc(50% - 4px);
			@include mqPc {
				left: 35px; } } } }
