//KEYFRAMES
@keyframes menuOpen {
	0% {
		transform: translateY(100px); }
	100% {
		transform: translateY(0); } }

// @keyframes load
// 	0%
// 		-webkit-transform: rotate(0deg)
// 		transform: rotate(0deg)
// 	100%
// 		-webkit-transform: rotate(360deg)
// 		transform: rotate(360deg)


