// PIPELINE

.p-pipeline {
	padding: 50px 16px 75px;
	@include mqPc {
		padding: 90px 20px 188px 80px; }


	// .p-pipeline__inner
	&__inner {
		width: 100%;
		max-width: 1020px;
		margin: 0 auto;
		position: relative; }


	// .p-pipeline__table
	&__table {
		overflow-x: scroll;
		margin-top: 40px;
		padding-top: 40px;
		@include mqPc {
			overflow: hidden;
			margin-top: 8px;
			padding-top: 70px; }

		&+& {
			@include mqPc {
				margin-top: 35px;
				padding-top: 140px; } } }


	// .p-pipeline__title
	&__title {
		color: #002e5b;
		text-align: center;
		@include mqPc {
			@include fz(28,40); } }

	// .p-pipeline__title-wrap
	&__title-wrap {
		color: #002e5b;
		display: flex;
		flex-direction: column;
		text-align: center;
		.en {
			@include mqPc {
				@include fz(28,40); } }
		.ja {
			margin-top: 5px;
			@include fz(12,17);
			@include mqPc {
				margin-top: 7px; } } }

	// .p-pipeline__read
	&__read {
		margin-top: 32px;
		background: cl(light-gray);
		@include En();
		p {
			@include fz(12,24);
			padding: 15px;
			@include mqPc {
				@include fz(14,21);
				padding: 20px; } } }

	// .p-pipeline__contact
	&__contact {
		margin: 0 auto;
		@include fz(14,28);
		@include En();
		@include JPfont;
		@include mqPc {
			margin: 0 auto;
			width: 100%;
			@include fz(16,32); }
		// p
		// 	padding-top: 0
		// 	+mqPc
		// 		padding-top: 0
		p + p {
			margin-top: 15px;
			@include mqPc {
				margin-top: 20px; } }
		a {
			color: cl(key-color);
			border-bottom: 1px solid cl(key-color);
			&:hover {
				border-bottom: 1px solid transparent; } } }

	// .p-pipeline__desc
	&__desc {
		position: relative;
		@include mqPc;
		@include En();
		p {
			@include fz(14,28);
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0; }
			@include mqPc {
				@include fz(16,32);
				text-align: center;
				margin-bottom: 16px; } } }

	&__list {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		@include mqPc {
			flex-wrap: nowrap;
			// justify-content: space-between
			justify-content: center;
			padding: 4px; } }
	&__item {
		width: 222px;
		position: relative;
		margin: 13px 10px;
		@include mqPc {
			margin: 0;
			width: 238px;
			margin: 0 20px;
			&:first-child {
				margin-left: 0; }
			&:last-child {
				margin-right: 0; } }
		&.ja {
			width: 282px;
			@include mqPc {
				margin: 0 10px;
				width: 259px; }
			.link {
				@include fz(14,18);
				border-radius: 13px;
				@include mqPc {
					@include fz(14,20);
					padding-left: 22px;
					border-radius: 13px; }
				&::before {
					border-radius: 16px;
					@include mqPc {
						border-radius: 16px; } }
				&::after {
					top: 34px;
					@include mqPc {
						top: 31px; } }
				@include mqPc {
					&:hover {
						&::before {
							border-radius: 16px; } } }
				.small {
					@include fz(12,16);
					@include mqPc {
						@include fz(10,14); } } } }

		&--eng {
			width: auto !important;
			.link {
				padding: 19.5px 28px 19.5px 15px !important;
				@include mqPc {
					padding: 13px 55px 13px 25px !important; } } }
		.link {
			display: block;
			@include fz(13,13);
			color: cl(white);
			padding: 19.5px 0;
			padding-left: 15px;
			position: relative;
			background: cl(blue);
			border-radius: 26px;
			@include mqPc {
				@include fz(14,14);
				padding: 13px 0;
				padding-left: 25px; }
			@include En(.05em,1);
			&::before {
				content: '';
				display: block;
				position: absolute;
				width: calc(100% + 8px);
				height: calc(100% + 8px);
				border-radius: 30px;
				border: 2px solid cl(blue);
				top: -4px;
				left: -4px;
				@include mqPc {
					border-radius: 24px;
					top: -4px;
					left: -4px;
					@include easing; } }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 6px;
				background: url('../image/common/arr_mini_wh.svg') no-repeat;
				background-size: 100% 100%;
				transform: rotate(90deg);
				top: 23px;
				right: 12px;
				@include mqPc {
					width: 18px;
					height: 7px;
					top: 17px;
					right: 14px; } }
			@include mqPc {
				@include easing;
				&:hover {
					background: cl(key-color);
					&::before {
						width: calc(100% + 10px);
						height: calc(100% + 10px);
						border-radius: 25px;
						top: -5px;
						left: -5px;
						border: 2px solid rgba(cl(key-color), .5); } } }
			.small {
				@include fz(10,18);
				@include mqPc {
					@include fz(10,14); } } } }

	&__bottom {
		position: relative;
		margin-top: 80px;
		@include mqPc {
			margin-top: 120px; }

		&::before {
			content: '';
			position: absolute;
			z-index: -2;
			height: calc(100% + 230px);
			width: calc(100% + 100px);
			background-color: rgba(244, 244, 244, 1);
			left: -80px;
			// margin-top: 80px
			// margin-bottom: 80px
			@include mqPc {
				width: calc(100% + 100px);
				left: -80px;
				height: calc(100% + 190px); }
			@include mq1120 {
				left: calc(-80px + (((100 * var(--vw)) - 1120px) / -2));
				width: calc(100% + 100px + ((100 * var(--vw)) - 1120px)); } } }

	// .p-pipeline__partition
	// &__partition
	// 	position: relative
	// 	background-color: rgba(244, 244, 244, 1)
	// 	width: calc(100% + 100px)
	// 	height: 80px
	// 	left: -80px
	// 	margin-top: 80px
	// 	margin-bottom: 80px
	// 	+mqPc
	// 		width: calc(100% + 100px)
	// 		height: 124px
	// 		left: -80px
	// 		margin-top: 125px
	// 		margin-bottom: 125px
	// 	+mq1120
	// 		left: calc(-80px + (((100 * var(--vw)) - 1120px) / -2))
	// 		width: calc(100% + 100px + ((100 * var(--vw)) - 1120px))

	// .p-pipeline__annotation
	&__annotation {
		ul {
			li , p {
				width: fit-content;
				display: block;
				text-align: left;
				margin-left: auto;
				@include fz(12,19);
				margin-top: 5px;
				@include mqPc {
						@include fz(13,20);
						margin-top: 6px; }
				@include En(.05em,1.3);

				a {
					border-bottom: 1px solid cl(key-color);
					color: cl(key-color); } } } } }

.p-pl-cat {
	position: relative;
	margin: 5px 0 25px;
	@include mqPc {
		position: absolute;
		top: -36px;
		right: 0;
		margin: 0; }

	// .p-pl-cat__list
	&__list {
		display: block;
		@include mqPc {
			display: flex; } }

	// .p-pl-cat__item
	&__item {
		@include fz(12,12);
		position: relative;
		padding-left: 27px;
		margin-bottom: 10px;
		@include mqPc {
			padding-left: 27px;
			margin-right: 24px; }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 20px;
			height: 12px;
			background: url('../image/pipeline/pl_arrow_or_mini.png') no-repeat;
			background-size: 100% 100%;
			top: 0px;
			left: 0; }
		&:nth-child(2) {
			margin-bottom: 0;
			@include mqPc {
				margin-right: 0; }
			&::before {
				background: url('../image/pipeline/pl_arrow_bl_mini.png') no-repeat;
				background-size: 100% 100%; } } } }



.p-pl-tbl {
	width: 100%;
	height: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-left: solid 1px cl(border-gray);
	border-right: solid 1px cl(border-gray);
	border-bottom: solid 1px cl(border-gray);
	margin-top: 24px;
	overflow: hidden;
	@include mqPc {
		margin-top: 40px; }

	thead {
		@include mqPc {
			background: cl(main-color); }
		th,td {
			padding: 0;
			a {
				display: block;
				@include fz(12,16);
				text-align: center;
				background: cl(main-color);
				color: cl(white);
				padding-top: 7px;
				padding-bottom: 7px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				// height: 45px
				@include mqPc {
					@include f(13);
					padding-top: 7px;
					padding-bottom: 7px;
 }					// height: 58px
				@include En(); }
			a.en {
				@include f(12); }
			// &.p-pl-tbl__col--col3
			// 	a
			// 		padding-top: 6px
			&.p-pl-tbl__col--col5,
			&.p-pl-tbl__col--col6,
			&.p-pl-tbl__col--col7,
			&.p-pl-tbl__col--col8, {
				a {
					padding-top: 3px;
					// height: 22px
					@include mqPc {
 } } }						// height: 28px
			&.p-pl-tbl__col--col8 {
				border-left: 1px solid cl(white);
				border-bottom: 1px solid cl(white); }
			a[href] {
				@include easing;
				&:hover {
					background: cl(key-color); } }
			span {
				display: inline-block; } } }


	th, td {
		height: 100%;
		div {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center; } }

	tr {
		position: relative; }

	tbody {
		.row-0.odd {
			.p-pl-tbl__col--col1 {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					width: 944px;
					height: 100%;
					background-color: #f4f4f4;
					@include mqPc {
						width: 1019px; } } } }
		.row-0.even {
			.p-pl-tbl__col--col1 {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					width: 944px;
					height: 100%;
					background-color: #fff;
					@include mqPc {
						width: 1019px; } } } }

		.step-1.row-0 {
			> .p-pl-tbl__col--col1 ~ * {
				padding: 24px 0; } }

		.step-2.row-0 {
			> .p-pl-tbl__col--col9 ~ * {
				padding: 24px 0 6px; } }
		.step-2.row-1 {
			> * {
				padding: 6px 0 24px; } }

		.step-3.row-0 {
			> .p-pl-tbl__col--col9 ~ * {
				padding: 24px 0 6px; } }
		.step-3.row-1 {
			> * {
				padding: 12px 0 12px; } }
		.step-3.row-2 {
			> * {
				padding: 6px 0 24px; } } }






	// .p-pl-tbl__row
	&__row {
		.p-pl-tbl__col {}

		&--first {
			.p-pl-tbl__col {
				padding-top: 30px;
				@include mqPc {
					padding-top: 40px; } } }
		&--last {
			.p-pl-tbl__col {} }

		&--break {
			.p-pl-tbl__col {
				padding-bottom: 40px;
				@include mqPc {
					padding-bottom: 42px; } } }
		&--pjct {
			&.p-pl-tbl__row--first {
				.p-pl-tbl__col {
					padding-top: 15px;
					@include mqPc {
						padding-top: 35px; } } }
			&.p-pl-tbl__row--last {
				.p-pl-tbl__col {
					@include mqPc {
						padding-bottom: 73px; } } }
			.p-pl-tbl__col {
				padding-top: 4px;
				padding-bottom: 4px; }
			.p-pl-tbl__col--col1 {
				padding-top: 30px; }
			.p-pl-tbl__col--pa {
				padding-bottom: 48px !important;
				@include mqPc {
					padding-bottom: 68px !important; } } } }

	// .p-pl-tbl__col
	&__col {
		padding: 15px 0;
		@include mqPc {
			padding: 6px 0; }
		&--pa {
			padding: 27px 0 12px 0;
			@include mqPc {
				padding: 12px 0 4px 0; } }
		// プロジェクト
		&--col1 {
			width: 134px;
			padding-left: 6px;
			padding-right: 6px;
			@include mqPc {
				width: 153px;
				padding-left: 6px;
				padding-right: 6px; } }

		// MOA
		&--col9 {
			width: 125px;
			border-left: solid 1px cl(border-gray);
			text-align: left;
			@include mqPc {
				width: 126px; } }
		// 適応症
		&--col2 {
			width: 140px;
			border-left: solid 1px cl(border-gray);
			text-align: left;
			@include mqPc {
				width: 126px; } }
		// 探索研究
		&--col3 {
			overflow: hidden;
			&--en a {
				padding-top: 4px !important;
				@include mqPc {
					padding-top: 7px !important; } } }
		// 導入先
		&--col10 {
			width: 127px;
			width: 170px;
			border-left: solid 1px cl(border-gray);
			text-align: left;
			@include mqPc {
				width: 160px; } }
		&--col3,
		&--col4,
		&--col5,
		&--col6,
		&--col7 {
			width: 75px;
			border-left: solid 1px cl(border-gray);
			@include mqPc {
				// width: 133px
				// width: 94px
				width: 67px;
 }				// width: 60px
			> div {
				display: block;
				width: 100%;
				height: 18px;
				position: relative;
				@include mqPc {
					height: 24px; }
				img {
					// width: 109px
					// max-width: 109px
					width: 86px;
					max-width: 86px;
					margin-left: -11px;
					&.half {
						// width: 64px
						// max-width: 64px
						// width: 60px
						// max-width: 60px
						width: 48px;
						max-width: 48px;
						margin-left: -49px; }
					@include mqPc {
						// width: 105px
						// max-width: 105px
						margin-left: -12px;
						width: 86px;
						max-width: 86px;
						&.half {
							margin-left: -49px;
							// max-width: 56px
							// width: 56px
							width: 48px;
							max-width: 48px; } } } } }
		&--col4,
		&--col5,
		&--col6,
		&--col7 {
			overflow: visible; }
		&--col8 {
			width: 225px;
			// width: 150px
			@include mqPc {
				// width: 400px
				// width: 284px
				width: 203px;
 } } }				// width: 120px

	// .p-pl-tbl__btn
	&__btn {
		margin: 0 auto;
		a {
			display: flex;
			align-items: center;
			@include f(13);
			width: 100%;
			height: 100%;
			min-height: 86px;
			padding: 6px;
			color: cl(white);
			background: cl(key-color);
			border-radius: 6px;
			position: relative;
			@include mqPc {
				@include f(13);
				@include easing; } }

		a[href] {
			@include mqPc {
				pointer-events: all; }
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 6px;
				background: url('../image/common/arr_mini_wh.png') no-repeat;
				background-size: 100% 100%;
				top: 0;
				bottom: 0;
				right: 12px;
				margin: auto;
				@include mqPc {
					width: 19px;
					height: 7px;
					@include easing; } }
			@include mqPc {
				&:hover {
					background: cl(dark-blue);
					&::after {
						transform: translateX(5px); } } } }
		span {
			text-align: start;
			@include mqPc {
				margin-left: 16px; } } }

	// .p-pl-tbl__moa
	&__moa {
		@include fz(12,17);
		padding: 0 8px;
		@include mqPc {
			@include fz(12,18); }
		@include En(.05em,1.3); }

	// .p-pl-tbl__logo
	&__logo {
		height: 64px!important;
		width: 64px;
		margin: 10px auto 0;
		img {
			object-fit: contain; } }

	// .p-pl-tbl__lst
	&__lst {
		margin-left: -4px;
		@include mqPc {
			margin-left: -5px; }
		li {
			@include fz(12,17);
			// white-space: nowrap
			padding: 0 8px 0 14px;
			position: relative;
			@include mqPc {
				@include fz(13,18);
				padding: 0 8px 0 16px; }
			@include En(.05em,1.3);
			&.en {
				@include fz(12,17);
				@include mqPc {
					@include fz(12,18); } }
			&:before {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				background-color: cl(key-color);
				border-radius: 50%;
				position: absolute;
				top: 8px;
				left: 0;
				transform: translateY(-50%);
				@include mqPc {
					top: 10px;
					width: 9px;
					height: 9px; } }
			+ li {
				margin: 5px 0;
				@include mqPc {
					margin: 6px 0; } } } }

	// .p-pl-tbl__project
	&__project {
		@include f(12);
		color: cl(main-color);
		padding: 14px;
		border: solid 2px cl(key-color);
		border-radius: 26px;
		@include mqPc {
			@include f(16);
			padding: 17px;
			border-radius: 31px; } } }




.p-pl-flow {
	width: 100%;
	margin: 0 auto;
	@include mqPc {
		max-width: 994px;
		padding-top: 40px; } }



.p-pl-flow1 {
	position: relative;
	display: flex;
	@include En();
	&__ttl {
		width: 46px;
		background: cl(main-color);
		color: cl(white);
		@include fz(18,42);
		writing-mode: vertical-rl;
		text-align: center;
		border: 2px solid cl(black);
		position: relative;
		@include mqPc {
			width: 54px;
			@include fz(16,50); }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 28px;
			height: 42px;
			background: url('../image/pipeline/top_sym02_sp.png') no-repeat;
			background-size: 100% 100%;
			bottom: -42px;
			left: 7px;
			z-index: -1;
			@include mqPc {
				width: 24px;
				height: 112px;
				background: url('../image/pipeline/top_sym02.png') no-repeat;
				background-size: 100% 100%;
				left: 14px;
				bottom: -52px; } } }
	&__table {
		width: percentage(940 / 994); }
	&__item {
		position: relative;
		border-top: 2px solid cl(black);
		border-right: 2px solid cl(black);
		border-bottom: 2px solid cl(black);
		margin-bottom: 40px;
		@include mqPc {
			display: flex;
			margin-bottom: 26px; }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 32px;
			height: 16px;
			background: url('../image/pipeline/top_sym01.png') no-repeat;
			background-size: 100% 100%;
			bottom: -31px;
			left: calc(50% - 16px);
			@include mqPc {
				width: 24px;
				height: 12px;
				bottom: -22px;
				left: percentage(111 / 940); } }
		&:last-child {
			margin-bottom: 0;
			&::after {
				display: none; } } }
	&__left {
		padding: 19px 0;
		width: 100%;
		background: cl(key-color);
		color: cl(white);
		@include lsCenter;
		position: relative;
		@include mqPc {
			padding: 19px 0;
			width: percentage(245 / 940);
			display: flex;
			align-items: center;
			justify-content: center; }
		@include En();
		.ttl {
			@include fz(16,24);
			@include mqPc {
				text-orientation: upright;
				writing-mode: horizontal-tb; } } }
	&__right {
		padding: 12px 16px;
		width: 100%;
		background: cl(white);
		position: relative;
		@include mqPc {
			padding: 20px 26px;
			width: percentage(695 / 940);
			display: flex;
			align-items: center; }
		.read {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,24); } } } }


.p-pl-flow2 {
	position: relative;
	display: flex;
	margin-top: 50px;
	border: 2px solid cl(black);
	@include mqPc {
		margin-top: 64px; }
	@include En();
	&__ttl {
		width: 45px;
		background: cl(main-color);
		color: cl(white);
		@include fz(18,45);
		writing-mode: vertical-rl;
		text-align: center;
		position: relative;
		@include mqPc {
			width: 54px;
			@include fz(16,54); }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 87px;
			height: 67px;
			background: url('../image/pipeline/top_sym04_sp.png') no-repeat;
			background-size: 100% 100%;
			bottom: -61px;
			left: 8px;
			z-index: -1;
			@include mqPc {
				width: 141px;
				height: 76px;
				background: url('../image/pipeline/top_sym04.png') no-repeat;
				background-size: 100% 100%;
				left: 15px;
				bottom: -66px; } } }

	&__table {
		width: percentage(940 / 994);
		position: relative; }
	&__item {
		position: relative;
		@include mqPc {
			display: flex; } }
	&__left {
		padding: 16px 0;
		width: 100%;
		background: cl(key-color);
		color: cl(white);
		@include lsCenter;
		position: relative;
		@include mqPc {
			padding: 13px 0;
			width: percentage(245 / 940);
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px dashed cl(black); }
		@include En();
		&.border {
			border-bottom: none; }
		.ttl {
			@include fz(16,24);
			@include mqPc {
				text-orientation: upright;
				writing-mode: horizontal-tb; } } }
	&__right {
		padding: 16px 16px;
		width: 100%;
		background: cl(white);
		position: relative;
		border-bottom: 1px dashed cl(black);
		@include mqPc {
			padding: 13px 26px;
			width: percentage(695 / 940);
			display: flex;
			align-items: center; }
		.read {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,24); } }
		&.border {
			border-bottom: none; } }
	&__txt {
		@include fz(14,40);
		text-align: center;
		color: cl(white);
		background: cl(dark-blue);
		width: percentage(200 / 328);
		height: 40px;
		border-radius: 20px;
		position: absolute;
		bottom: -75px;
		left: 110px;
		@include mqPc {
			@include fz(16,48);
			width: 320px;
			height: 48px;
			border-radius: 24px;
			bottom: -90px;
			left: 165px; }
		&--en {
			letter-spacing: .05em;
			line-height: 1.6;
			height: 56px;
			border-radius: 28px;
			padding-top: 5px;
			@include mqPc {
				width: 420px;
				height: 48px;
				line-height: 3;
				border-radius: 24px;
				padding-top: 0; } } } }



.p-pl-flow3 {
	position: relative;
	display: flex;
	margin-top: 105px;
	@include mqPc {
		margin-top: 130px; }
	@include En();
	&__ttl {
		width: 45px;
		background: cl(main-color);
		color: cl(white);
		@include fz(18,45);
		writing-mode: vertical-rl;
		text-align: center;
		position: relative;
		@include mqPc {
			width: 54px;
			@include fz(16,54); }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 87px;
			height: 37px;
			background: url('../image/pipeline/top_sym05_sp.png') no-repeat;
			background-size: 100% 100%;
			top: -43px;
			left: 9px;
			z-index: -1;
			@include mqPc {
				width: 141px;
				height: 44px;
				background: url('../image/pipeline/top_sym05.png') no-repeat;
				background-size: 100% 100%;
				top: -60px;
				left: 15px; } }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 87px;
			height: 67px;
			background: url('../image/pipeline/top_sym04_sp.png') no-repeat;
			background-size: 100% 100%;
			bottom: -67px;
			left: 9px;
			z-index: -1;
			@include mqPc {
				width: 141px;
				height: 76px;
				background: url('../image/pipeline/top_sym04.png') no-repeat;
				background-size: 100% 100%;
				bottom: -76px;
				left: 16px; } } }
	&__table {
		width: percentage(940 / 994);
		position: relative; }
	&__item {
		position: relative;
		border-top: 2px solid cl(black);
		border-right: 2px solid cl(black);
		border-bottom: 2px solid cl(black);
		margin-bottom: 40px;
		@include mqPc {
			display: flex;
			margin-bottom: 26px; }
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 32px;
			height: 16px;
			background: url('../image/pipeline/top_sym01.png') no-repeat;
			background-size: 100% 100%;
			bottom: -31px;
			left: calc(50% - 16px);
			@include mqPc {
				width: 24px;
				height: 12px;
				bottom: -22px;
				left: percentage(111 / 940); } }
		&:last-child {
			margin-bottom: 0;
			&::after {
				display: none; } } }
	&__left {
		padding: 19px 0;
		width: 100%;
		background: cl(key-color);
		color: cl(white);
		@include lsCenter;
		position: relative;
		@include mqPc {
			padding: 19px 0;
			width: percentage(245 / 940);
			display: flex;
			align-items: center;
			justify-content: center; }
		@include En();
		.ttl {
			@include fz(16,24);
			@include mqPc {
				text-orientation: upright;
				writing-mode: horizontal-tb; }
			span {
				@include fz(12,12); } } }
	&__right {
		padding: 12px 16px;
		width: 100%;
		background: cl(white);
		position: relative;
		@include mqPc {
			padding: 20px 26px;
			width: percentage(695 / 940); }
		.read {
			@include fz(14,28);
			@include mqPc {
				@include fz(16,24); }
			span {
				@include fz(12,12);
				@include mqPc {
					@include fz(14,24); } } } }
	&__txt {
		@include fz(14,40);
		text-align: center;
		color: cl(white);
		background: cl(dark-blue);
		width: percentage(200 / 328);
		height: 40px;
		border-radius: 20px;
		position: absolute;
		bottom: -75px;
		left: percentage(111 / 328);
		@include mqPc {
			@include fz(16,48);
			width: 320px;
			height: 48px;
			border-radius: 24px;
			bottom: -90px;
			left: 165px; }
		@include En(.05em,3); } }

