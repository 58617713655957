// SCO-792

.p-sco-792 {
	&__inner {
		position: relative; }

	.p-sco-cat {
		@include mqPc {
			margin-top: 30px; } }

	.p-drawing {
		width: 328px;
		margin: 0 auto;
		@include mq360 {
			width: 100%;
			min-width: 288px; }
		@include mqPc {
			width: auto;
			transform: scale(0.82,0.82); }

		// .p-drawing__unit
		&__unit {
			width: 328px;
			border: 2px solid cl(light-gray);
			border-radius: 5px;
			padding-top: 26px;
			position: relative;
			@include mqPc {
				width: percentage(464 / 968);
				max-width: 464px;
				border: 3px solid cl(light-gray); }
			&--top {
				width: 328px;
				height: auto;
				margin: 0 auto;
				@include mq360 {
					width: 100%; }

				@include mqPc {
					width: 624px;
					max-width: 624px;
					margin: 0 auto 38px;
					height: 448px; }
				&::before {
					display: none;
					@include mqPc {
						content: '';
						display: block;
						position: absolute;
						width: 24px;
						height: 29px;
						background: url('../image/pipeline/arr_short.png') no-repeat;
						background-size: 100% 100%;
						bottom: -32px;
						left: 144px; } }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 18px;
					height: 608px;
					background: url('../image/pipeline/792/arr_sp.png') no-repeat;
					background-size: 100% 100%;
					bottom: -610px;
					right: percentage(15 / 328);
					@include mqPc {
						width: 24px;
						height: 29px;
						background: url('../image/pipeline/arr_short.png') no-repeat;
						background-size: 100% 100%;
						bottom: -32px;
						right: 144px; } }

				.txt-area,
				%txt-area {
					margin: 0 auto;
					background: cl(light-gray);
					border-radius: 5px;
					text-align: center;
					padding: 12px 0;
					position: relative;
					@include mqPc {
						padding-top: 20px;
						border-radius: 10px; } }
				.txt-area--small {
					@extend %txt-area;
					padding-top: 6px;
					@include mqPc {
						padding-top: 10px; }
					p {
						letter-spacing: ls(50);
						@include fz(12,18);
						margin: 5px 5px 0;
						@include mqPc {
							@include fz(14,21);
							margin: 10px 10px 0; } } }
				.txt-area--last {
					padding-top: 6px;
					@include mqPc {
						padding-top: 12px;
						margin-bottom: 20px; }
					p {
						margin: 5px 5px 0;
						@include mqPc {
							margin: 10px 10px 0; } } }

				.txt,
				%txt {
					@include fz(12,18);
					padding-right: 22px;
					position: relative;
					letter-spacing: ls(50);
					display: inline-block;
					margin: 5px 10px 0;
					@include mqPc {
						@include fz(18,21);
						padding-right: 28px;
						margin: 10px 10px 0; }
					&:first-child {
						margin-top: 0; }
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 15px;
						height: 15px;
						top: calc(50% - 7.5px);
						right: 0;
						@include mqPc {
							width: 20px;
							height: 20px;
							top: calc(50% - 10px); } }
					&--up {
						@extend %txt;
						&::after {
							background: url('../image/pipeline/ico_arr_up.png') no-repeat;
							background-size: 100% 100%; } }

					&--down {
						@extend %txt;
						&::after {
							background: url('../image/pipeline/ico_arr_down.png') no-repeat;
							background-size: 100% 100%; } } } } }


		// .p-drawing__ttl
		&__ttl {
			@include fz(16,16);
			letter-spacing: ls(50);
			text-align: center;
			@include mqPc {
				@include fz(18,18); } }


		// .p-drawing__flex
		&__flex {
			width: 310px;
			margin: 0 auto 28px;
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			@include mq360 {
				transform: scale(0.9,0.9); }
			@include mqPc {
				width: 474px;
				margin: 20px 0 40px 90px;
				justify-content: space-between; } }


		// .p-drawing__left
		&__left {
			position: relative;
			margin-top: 23px;
			@include mqPc {
				margin-top: 32px; }
			.main {
				width: 130px;
				height: 24px;
				border-radius: 12px;
				@include fz(12,24);
				letter-spacing: ls(0);
				color: cl(white);
				background: cl(main-color);
				text-align: center;
				margin: 0 auto;
				position: relative;
				@include mqPc {
					width: 140px;
					height: 36px;
					border-radius: 18px;
					@include fz(12,36); }
				&:nth-child(1) {
					margin-bottom: 35px;
					@include mqPc {
						margin-bottom: 40px; }
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 15px;
						height: 18px;
						background: url('../image/pipeline/792/sym05.png') no-repeat;
						background-size: 100% 100%;
						bottom: -27px;
						left: 57px;
						@include mqPc {
							width: 25px;
							height: 28px;
							bottom: -34px;
							left: 57px; } } }
				&:nth-child(2) {
					margin-bottom: 35px;
					@include mqPc {
						margin-bottom: 37px; }
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 16px;
						height: 24px;
						background: url('../image/pipeline/792/sym07.png') no-repeat;
						background-size: 100% 100%;
						bottom: -24px;
						left: 57px;
						@include mqPc {
							width: 24px;
							height: 29px;
							bottom: -29px;
							left: 58px;
							background: url('../image/pipeline/arr_short_eff.png') no-repeat;
							background-size: 100% 100%; } } } }
			.txt-area {
				width: 140px;
				height: auto;
				@include mqPc {
					width: 195px;
					height: 98px; }
				&--en {
					padding: 7px 0 !important;
					@include mqPc {
						padding-top: 11px !important; }
					.txt--up,
					.txt--down {
						@include fz(10,10);
						@include mqPc {
							@include fz(14,21); } } } }

			.txt-area--small {
				height: 35px !important;
				margin-bottom: 37px;
				@include mqPc {
					height: 48px !important; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 16px;
					height: 24px;
					background: url('../image/pipeline/792/sym07.png') no-repeat;
					background-size: 100% 100%;
					bottom: -24px;
					left: 62px;
					@include mqPc {
						width: 24px;
						height: 29px;
						bottom: -29px;
						left: 85px;
						background: url('../image/pipeline/arr_short_eff.png') no-repeat;
						background-size: 100% 100%; } } }

			.txt-area--last {
				height: 35px;
				@include mqPc {
					height: 48px; } }

			.entp {
				width: 80px;
				height: 24px;
				border-radius: 12px;
				@include fz(12,24);
				padding-left: 5px;
				color: cl(white);
				background: cl(blue);
				text-align: center;
				position: absolute;
				top: 29px;
				right: -75px;
				@include mqPc {
					width: 80px;
					height: 40px;
					border-radius: 20px;
					@include fz(18,40);
					top: 35px;
					right: -85px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 39px;
					height: 14px;
					background: url('../image/pipeline/792/sym06.png') no-repeat;
					background-size: 100% 100%;
					transform: scale(-1,1);
					bottom: 5px;
					left: -16px;
					z-index: -1;
					@include mqPc {
						width: 67px;
						height: 24px;
						bottom: 8px;
						left: -50px; } } } }

		// .p-drawing__right
		&__right {
			width: percentage(130 / 310);
			max-width: 200px;
			margin-top: auto;
			margin-bottom: 12px;
			margin-right: 4px;
			@include mq320 {
				margin-right: 20px; }
			@include mqPc {
				width: 239px;
				max-width: 239px;
				margin: 0; } }



		// 2段目
		//--------------------------------------------------
		// .p-drawing__wrap
		&__wrap {
			display: block;
			@include mqPc {
				display: flex;
				justify-content: space-between; } }


		.p-drawing__unit--left,
		.p-drawing__unit--right {
			margin-top: 32px;
			padding-bottom: 26px;
			@include mq360 {
				width: 248px; }
			@include mqPc {
				margin-top: 0; }
			.p-drawing__txt-area {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 256px;
				margin: 10px auto 0;
				@include mq360 {
					transform: scale(0.8,0.8); }
				@include mqPc {
					justify-content: space-between;
					width: 418px;
					max-width: none;
					position: absolute;
					bottom: 25px;
					left: percentage(23 / 464); } }
			.txt-area,
			%txt-area {
				background: cl(light-gray);
				border-radius: 5px;
				text-align: center;
				padding: 12px 0;
				position: relative;
				@include mqPc {
					padding-top: 20px;
					border-radius: 10px; }

				&--en {
					padding: 7px 0 !important;
					@include mqPc {
						padding-top: 11px !important; }
					.txt--up,
					.txt--down {
						@include fz(10,10);
						@include mqPc {
							@include fz(14,21); } } } }

			.txt-area--small {
				@extend %txt-area;
				padding-top: 6px;
				@include mqPc {
					padding-top: 10px; }
				p {
					letter-spacing: ls(50);
					@include fz(12,18);
					margin: 5px 5px 0;
					@include mqPc {
						@include fz(14,21);
						margin: 10px 10px 0; } } }

			.txt-area--last {
				padding-top: 6px;
				@include mqPc {
					padding-top: 12px; } }


			.txt,
			%txt {
				@include fz(12,18);
				padding-right: 22px;
				position: relative;
				letter-spacing: ls(50);
				display: inline-block;
				margin: 5px 10px 0;
				@include mqPc {
					@include fz(18,21);
					padding-right: 28px;
					margin: 10px 10px 0; }
				&:first-child {
					margin-top: 0; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 0;
					@include mqPc {
						width: 20px;
						height: 20px;
						top: calc(50% - 10px); } }

				&--up {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_up.png') no-repeat;
						background-size: 100% 100%; } }

				&--down {
					@extend %txt;
					&::after {
						background: url('../image/pipeline/ico_arr_down.png') no-repeat;
						background-size: 100% 100%; } } } }


		// 左図
		//--------------------------------------------------
		.p-drawing__unit--left {
			position: relative;
			width: 288px;
			@include mqPc {
				width: 100%;
				height: 456px; }

			.p-drawing__spflex {
				display: flex;
				justify-content: space-around;
				margin-top: 15px;
				margin-bottom: 16px;
				@include mqPc {
					display: block;
					margin-top: 0;
					margin-bottom: 0; } }
			.group {
				position: relative;
				width: 130px;
				margin-top: 10px;
				margin-left: 10px;
				@include mqPc {
					width: 170px;
					margin-top: 38px;
					margin-left: 60px; } }
			.key {
				width: 130px;
				height: 36px;
				border-radius: 18px;
				@include fz(14,36);
				letter-spacing: ls(100);
				color: cl(white);
				background: cl(key-color);
				text-align: center;
				margin: 0 auto;
				position: relative;
				@include mqPc {
					width: 170px;
					height: 40px;
					border-radius: 20px;
					@include fz(18,40); }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 17px;
					height: 36px;
					background: url('../image/pipeline/792/sym01.png') no-repeat;
					background-size: 100% 100%;
					bottom: -30px;
					left: 57px;
					z-index: -1;
					@include mqPc {
						width: 28px;
						height: 59px;
						bottom: -30px;
						left: 71px; } } }
			.entp {
				width: 80px;
				height: 24px;
				margin: 40px auto 0;
				border-radius: 12px;
				@include fz(12,24);
				padding-left: 5px;
				color: cl(white);
				background: cl(blue);
				text-align: center;
				@include mqPc {
					height: 40px;
					border-radius: 20px;
					@include fz(18,40); } }
			.img {
				width: 130px;
				margin-right: 15px;
				@include mqPc {
					width: 199px;
					max-width: none;
					position: absolute;
					top: 74px;
					right: percentage(60 / 466); } }

			.txt-area {
				width: 102px;
				height: 74px;
				@include mqPc {
					width: 185px;
					max-width: none;
					height: 98px;
					margin-top: 0; } }
			.txt-area--small {
				width: 127px;
				height: 35px;
				@include mqPc {
					width: 185px;
					max-width: none;
					height: 48px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 17px;
					background: url('../image/pipeline/arr_short_eff.png') no-repeat;
					background-size: 100% 100%;
					transform: rotate(-90deg);
					top: 9px;
					right: -21px;
					@include mqPc {
						width: 24px;
						height: 29px;
						top: 10px;
						right: -38px; } } }
			.txt-area--last {
				height: 35px;
				@include mqPc {
					height: 48px; } } }


		// 右図
		//--------------------------------------------------
		.p-drawing__unit--right {
			position: relative;
			width: 288px;
			@include mqPc {
				width: 100%;
				height: 456px; }
			.word {
				@include fz(12,12);
				letter-spacing: ls(50);
				position: absolute;
				top: 5px;
				left: -8px;
				@include mqPc {
					@include fz(14,14);
					top: 15px;
					left: -20px; }
				&--en {
					top: 0;
					left: -40px;
					@include mqPc {
						top: 5px;
						left: -45px; } } }

			.img {
				width: 130px;
				margin: 25px 0 0 105px;
				position: relative;
				@include mqPc {
					width: 197px;
					max-width: none;
					position: absolute;
					top: 78px;
					left: percentage(180 / 466);
					margin: 0; } }
			.entp {
				width: 80px;
				height: 24px;
				border-radius: 12px;
				@include fz(12,24);
				padding-left: 5px;
				color: cl(white);
				background: cl(blue);
				text-align: center;
				position: absolute;
				top: 109px;
				left: -76px;
				@include mqPc {
					height: 40px;
					border-radius: 20px;
					@include fz(18,40);
					top: 162px;
					left: -74px; } }
			.txt-area {
				width: 102px;
				height: 74px;
				@include mqPc {
					width: 185px;
					max-width: none;
					height: 98px;
					margin-top: 0; } }
			.txt-area--small {
				width: 127px;
				height: 35px;
				@include mqPc {
					width: 185px;
					max-width: none;
					height: 48px; }
				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 14px;
					height: 17px;
					background: url('../image/pipeline/arr_short_eff.png') no-repeat;
					background-size: 100% 100%;
					transform: rotate(-90deg);
					top: 9px;
					right: -21px;
					@include mqPc {
						width: 24px;
						height: 29px;
						top: 10px;
						right: -38px; } } }
			.txt-area--last {
				height: 35px;
				@include mqPc {
					height: 48px; } } } } }
