// Content

.l-content {
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url('../image/common/bg.png');
		background-size: 100px 173px;
		background-repeat: repeat;
		top: 0;
		left: 0;
		z-index: -10; }


	//.l-content__pagetop
	&__pagetop {
		position: relative;
		width: 136px;
		margin: 60px auto 45px;
		z-index: 2;
		padding: 18px 10px 17px;
		display: block;
		@include mqPc {
			position: absolute;
			width: auto;
			margin: auto;
			bottom: -976px;
			right: 60px;
			padding: 25px 16px;
			@media screen and (min-width: 1159px) {
				bottom: -611px; }
			&:hover {
				.arr {
					bottom: 18px; } } }
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 32px;
			height: 32px;
			top: 0;
			right: 10px;
			border: 2px solid cl(main-color);
			border-radius: 16px;
			@include mqPc {
				@include easing;
				top: 0;
				right: 0; } }
		.arr {
			width: 34px;
			height: 7px;
			position: absolute;
			right: 10px;
			bottom: 13px;
			transform: rotate(-90deg);
			@include mqPc {
				@include easing;
				right: 0;
				bottom: 13px; } }
		svg {
			fill: cl(main-color); }
		p {
			@include OpenSans;
			@include fz(14,14);
			color: cl(main-color);
			letter-spacing: ls(100);
			@include mqPc {
				display: none; } }

		&--top {
			@include mqPc {
				bottom: -919px;
				@media screen and (min-width: 1159px) {
					bottom: -547px; } } } } }
