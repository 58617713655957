// NEWS

// .c-news
//---------------------------------------------------
.c-news {
	&__item {
		margin-bottom: 30px;
		@include mqPc {
			margin: 12px 0; }
		&:last-child {
			margin-bottom: 0; }
		a {
			display: inline-block;
			position: relative;
			color: cl(black);
			@include mqPc {
				margin: 20px 20px 20px 0;
				overflow: hidden;
				@include easing;
				&:last-child {
					margin-top: 0; } }
			&:hover {
				.c-news__ttl {
					text-decoration: underline; } }
			&[href$=".pdf"]:hover {
				&::after {
					text-decoration: none; } } }



		a[href$=".pdf"] {
			position: relative;
			&::after {
				content: 'PDF';
				@include OpenSans;
				@include fz(12,12);
				color: cl(white);
				background: cl(main-color);
				padding-left: 12px;
				padding-right: 9px;
				width: 50px;
				height: 18px;
				margin-left: 6px;
				vertical-align: 2px; } } }

	&__cap {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		z-index: 1;
		@include mqPc {
			margin-bottom: 18px; }
		.tag {
			@include fz(12,12);
			padding: 5px 17px;
			border-radius: 13px;
			margin-right: 22px;
			color: cl(main-color);
			border: 2px solid cl(main-color);
			@include mqPc {
				@include fz(15,15);
				padding: 4.5px 18px;
				border-radius: 14px;
				margin-right: 20px; }
			@include En(.05em,1);
			&--paper,
			&--business {
				color: cl(main-color);
				border: 2px solid cl(main-color); }
			&--info {
				color: cl(light-blue);
				border: 2px solid cl(light-blue); }
			&--ir {
				color: cl(blue);
				border: 2px solid cl(blue); } }
		.date {
			@include fz(14,14);
			@include OpenSans;
			color: cl(text-gray);
			letter-spacing: ls(100);
			line-height: 26px;
			@include mqPc {
				@include fz(16,16);
				line-height: 28px; } } }
	&__ttl {
		color: cl(black);
		@include fz(14,21);
		position: relative;
		display: inline;
		z-index: 1;
		@include mqPc {
			@include fz(16,24); }
		@include En(); } }
