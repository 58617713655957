// SCO-006

.p-sco-006 {
	&__inner {
		position: relative; }

	.p-sco-cat {
		@include mqPc {
			margin-top: 20px; } }

	.p-drawing {
		position: relative;
		width: 328px;
		margin: 0 auto 50px;
		@include mqPc {
			// transform: scale(0.82,0.82)
			width: 82%;
			margin: 0 auto;
 }			// width: 100%
		@include mq360 {
			transform: scale(0.9,0.9); }

		// .p-drawing__sst
		// &__sst
		// 	width: 145px
		// 	height: 30px
		// 	border-radius: 15px
		// 	+fz(12,30)
		// 	color: cl(white)
		// 	background: cl(blue)
		// 	text-align: center
		// 	margin: 0 0 27px auto
		// 	position: relative
		// 	+mqPc
		// 		width: 260px
		// 		height: 50px
		// 		border-radius: 25px
		// 		+fz(18,50)
		// 		margin: 0 auto 40px
		// 	&::after
		// 		content: ''
		// 		display: block
		// 		position: absolute
		// 		width: 14px
		// 		height: 25px
		// 		background: url('../image/pipeline/267/arr_act_sp.png') no-repeat
		// 		background-size: 100% 100%
		// 		bottom: -20px
		// 		left: calc(50% - 7px)
		// 		z-index: -1
		// 		+mqPc
		// 			width: 24px
		// 			height: 29px
		// 			background: url('../image/pipeline/arr_short_act.png') no-repeat
		// 			background-size: 100% 100%
		// 			bottom: -29px
		// 			left: calc(50% - 12px)


		// .p-drawing__key
		// &__key
		// 	width: 155px
		// 	height: 36px
		// 	border-radius: 18px
		// 	+fz(14,36)
		// 	color: cl(white)
		// 	background: cl(key-color)
		// 	text-align: center
		// 	position: absolute
		// 	left: 0
		// 	top: 54px
		// 	+mqPc
		// 		width: 290px
		// 		height: 60px
		// 		border-radius: 30px
		// 		+fz(20,60)
		// 		top: 85px
		// 	&::after
		// 		content: ''
		// 		display: block
		// 		position: absolute
		// 		width: 46px
		// 		height: 16px
		// 		background: url('../image/pipeline/006/inhibition.png') no-repeat
		// 		background-size: 100% 100%
		// 		top: 50%
		// 		right: -20px
		// 		transform: translateY(-50%)
		// 		z-index: -1
		// 		+mqPc
		// 			width: 57px
		// 			height: 20px
		// 			background: url('../image/pipeline/006/inhibition.png') no-repeat
		// 			background-size: 100% 100%
		// 			top: 50%
		// 			right: -54px
		// 			transform: translateY(-50%)


		// // .p-drawing__sstr5
		// &__sstr5
		// 	width: 145px
		// 	height: 30px
		// 	border-radius: 15px
		// 	+fz(12,30)
		// 	color: cl(white)
		// 	background: cl(blue)
		// 	text-align: center
		// 	margin: 0 0 0 auto
		// 	position: relative
		// 	+mqPc
		// 		width: 260px
		// 		height: 50px
		// 		border-radius: 25px
		// 		+fz(18,50)
		// 		margin: 0 auto 82px
		// 	&::after
		// 		content: ''
		// 		display: block
		// 		position: absolute
		// 		width: 75px
		// 		height: 541px
		// 		background: url('../image/pipeline/006/arr_eff01_sp.png') no-repeat
		// 		background-size: 100% 100%
		// 		top: 30px
		// 		left: 70px
		// 		+mqPc
		// 			width: 774px
		// 			height: 69px
		// 			background: url('../image/pipeline/006/arr_eff01.png') no-repeat
		// 			background-size: 100% 100%
		// 			top: 50px
		// 			left: calc(50% - 387px)


		// // .p-drawing__list
		// &__list
		// 	width: 100%
		// 	position: relative
		// 	margin: 35px auto 0
		// 	+mqPc
		// 		max-width: 968px
		// 		display: flex
		// 		justify-content: space-between
		// 		position: relative
		// 		margin: 0 auto 80px


		// // .p-drawing__item
		// &__item
		// 	width: 288px
		// 	height: 129px
		// 	border: 2px solid cl(light-gray)
		// 	border-radius: 5px
		// 	margin: 20px auto 0
		// 	position: relative
		// 	display: flex
		// 	align-items: center
		// 	justify-content: center
		// 	+mqPc
		// 		width: 218px
		// 		height: 300px
		// 		border: 3px solid cl(light-gray)
		// 		border-radius: 10px
		// 		padding: 30px 16px 20px
		// 		margin: 0
		// 		display: block

		// 	&:not(:last-child)
		// 		+mqPc
		// 			margin: 0 percentage(32 / 968) 0 0

		// 	&:nth-child(1)
		// 		.wrap
		// 			margin-top: 15px
		// 			margin-right: 23px
		// 			+mqPc
		// 				margin-top: 0
		// 				margin-right: 0
		// 		.img
		// 			width: 143px
		// 			margin-top: 3px
		// 			+mqPc
		// 				width: 170px
		// 				margin-top: 0
		// 		.txt-area
		// 			div p
		// 				+mqPc
		// 					margin-left: 2px

		// 	&:nth-child(2)
		// 		.wrap
		// 			margin-top: 17px
		// 			margin-right: 22px
		// 			margin-left: 15px
		// 			+mqPc
		// 				margin-top: 0
		// 				margin-right: 0
		// 				margin-left: 0
		// 		.txt-area
		// 			div p
		// 				+mqPc
		// 					margin-left: 4px

		// 	&:nth-child(3)
		// 		.img
		// 			margin: 7px auto 0
		// 			+mqPc
		// 				margin: 10px auto 15px
		// 		.txt-area
		// 			.arr-eff02 p
		// 				+mqPc
		// 					margin-left: 5px
		// 			.arr-eff03 p
		// 				+mqPc
		// 					margin-left: 3px

		// 	&:nth-child(4)
		// 		.img
		// 			margin: 5px auto 0
		// 			+mqPc
		// 				margin: 10px auto 15px
		// 		.txt-area
		// 			div p
		// 				+mqPc
		// 					margin-left: 4px

		// 	// .p-drawing__item--pancreas
		// 	&--pancreas
		// 		&:before
		// 			content: ''
		// 			display: block
		// 			position: absolute
		// 			width: 20px
		// 			height: 577px
		// 			background: url('../image/pipeline/006/arr_eff04_sp.png') no-repeat
		// 			background-size: 100% 100%
		// 			top: -90px
		// 			left: -22px
		// 			+mqPc
		// 				width: 508px
		// 				height: 71px
		// 				background: url('../image/pipeline/006/arr_eff04.png') no-repeat
		// 				background-size: 100% 100%
		// 				top: auto
		// 				bottom: -74px
		// 				left: auto
		// 				right: calc(50% - 254px)

		// 	// .p-drawing__item--gallbladder
		// 	&--gallbladder
		// 		&:before
		// 			content: ''
		// 			display: block
		// 			position: absolute
		// 			width: 20px
		// 			height: 204px
		// 			background: url('../image/pipeline/006/arr_eff05_sp.png') no-repeat
		// 			background-size: 100% 100%
		// 			top: 80px
		// 			right: -22px
		// 			+mqPc
		// 				width: 24px
		// 				height: 71px
		// 				background: url('../image/pipeline/006/arr_eff05.png') no-repeat
		// 				background-size: 100% 100%
		// 				top: auto
		// 				bottom: -74px
		// 				right: calc(50% - 12px)

		// 	.wrap
		// 		margin-right: 15px
		// 		+mqPc
		// 			margin-right: 0

		// 	.name
		// 		+fz(12,12)
		// 		letter-spacing: ls(50)
		// 		text-align: center
		// 		+mqPc
		// 			+fz(16,16)
		// 		&--en
		// 			+fz(10,10)
		// 			+mqPc
		// 				+fz(14,18)

		// 	.img
		// 		width: 70px
		// 		margin: 0 auto
		// 		+mqPc
		// 			width: 100px
		// 			margin: 10px auto 15px

		// 	.txt-area
		// 		display: flex
		// 		+mqPc
		// 			width: 100%
		// 			display: block
		// 			margin: 0 auto

		// 		.en &
		// 			.txt--up,
		// 			.txt--down
		// 				+fz(10,13)
		// 				+mqPc
		// 					+fz(14,18)

		// 		div
		// 			width: 81px
		// 			height: 70px
		// 			text-align: center
		// 			background: cl(light-gray)
		// 			border-radius: 5px
		// 			padding: 7px 8px 8px
		// 			position: relative
		// 			display: flex
		// 			justify-content: center
		// 			align-items: center
		// 			+mqPc
		// 				width: 100%
		// 				height: auto
		// 				border-radius: 10px
		// 				padding: 10px 5px 14px
		// 				display: block
		// 			&:not(:last-child)
		// 				margin-right: 7px
		// 				+mqPc
		// 					margin-right: 0
		// 					margin-bottom: 12px

		// 			&.pd
		// 				padding: 7px 8px 8px
		// 				+mqPc
		// 					padding: 5px 5px 4px

		// 		.arr-eff02
		// 			width: 81px
		// 			+mqPc
		// 				width: 100%
		// 			&:before
		// 				content: ''
		// 				display: block
		// 				position: absolute
		// 				width: 14px
		// 				height: 79px
		// 				background: url('../image/pipeline/006/arr_eff02_sp.png') no-repeat
		// 				background-size: 100% 100%
		// 				bottom: -79px
		// 				right: calc(50% - 7px)
		// 				z-index: 1
		// 				+mqPc
		// 					width: 70px
		// 					height: 24px
		// 					background: url('../image/pipeline/006/arr_eff02.png') no-repeat
		// 					background-size: 100% 100%
		// 					top: 50%
		// 					bottom: auto
		// 					right: -70px
		// 					transform: translateY(-50%)
		// 		.arr-eff03
		// 			width: 81px
		// 			+mqPc
		// 				width: 100%
		// 			&:before
		// 				content: ''
		// 				display: block
		// 				position: absolute
		// 				width: 43px
		// 				height: 80px
		// 				background: url('../image/pipeline/006/arr_eff03_sp.png') no-repeat
		// 				background-size: 100% 100%
		// 				top: -80px
		// 				left: 0px
		// 				z-index: 1
		// 				+mqPc
		// 					width: 70px
		// 					height: 76px
		// 					background: url('../image/pipeline/006/arr_eff03.png') no-repeat
		// 					background-size: 100% 100%
		// 					top: auto
		// 					bottom: 20px
		// 					left: -70px

		// 	.txt,
		// 	%txt
		// 		+fz(12,18)
		// 		position: relative
		// 		letter-spacing: ls(50)
		// 		padding: 0
		// 		display: block
		// 		+mqPc
		// 			+fz(14,14)
		// 			padding: 3px 28px 3px 0
		// 			display: inline-block
		// 		&::after
		// 			content: ''
		// 			display: block
		// 			width: 15px
		// 			height: 15px
		// 			position: relative
		// 			margin: 4px calc(50% - 7.5px) 0
		// 			+mqPc
		// 				width: 20px
		// 				height: 20px
		// 				position: absolute
		// 				top: calc(50% - 10px)
		// 				left: auto
		// 				right: 0
		// 				margin: 0
		// 			.en &
		// 				margin: 6px calc(50% - 7.5px) 0
		// 				+mqPc
		// 					margin: 0

		// 		&--up
		// 			@extend %txt
		// 			&::after
		// 				background: url('../image/pipeline/ico_arr_up.png') no-repeat
		// 				background-size: 100% 100%

		// 		&--down
		// 			@extend %txt
		// 			&::after
		// 				background: url('../image/pipeline/ico_arr_down.png') no-repeat
		// 				background-size: 100% 100%


		// // .p-drawing__effect
		// &__effect
		// 	display: block
		// 	+mqPc
		// 		display: flex
		// 		justify-content: flex-end


		// // .p-drawing__effect-item
		// &__effect-item
		// 	width: 288px
		// 	margin: 20px auto 0
		// 	border: 2px solid cl(light-gray)
		// 	border-radius: 5px
		// 	padding: 26px 0
		// 	align-items: center
		// 	position: relative
		// 	+mqPc
		// 		width: 358px
		// 		height: 138px
		// 		margin: 0
		// 		border: 3px solid cl(light-gray)
		// 		border-radius: 10px
		// 		padding: 55px 0
		// 		align-items: center

		// 	&:not(:last-child)
		// 		margin: 20px auto 0
		// 		+mqPc
		// 			margin: 0 74px 0 0

		// 	&:nth-child(1)
		// 		.txt-area
		// 			margin-left: 3px
		// 			+mqPc
		// 				margin-left: 6px

		// 	&:nth-child(2)
		// 		.txt-area
		// 			margin-left: 3px
		// 			+mqPc
		// 				margin-left: 2px

		// 	.txt-area
		// 		display: flex
		// 		margin: 0 auto
		// 		justify-content: center

		// 		.en &
		// 			.txt--up,
		// 			.txt--down
		// 				+fz(12,16)
		// 				+mqPc
		// 					+fz(16,18)

		// 	.txt,
		// 	%txt
		// 		+fz(14,12)
		// 		padding-right: 22px
		// 		margin-right: 20px
		// 		position: relative
		// 		letter-spacing: ls(50)
		// 		padding: 2px 0
		// 		+mqPc
		// 			+fz(18,22)
		// 			padding-right: 28px
		// 			margin-right: 28px
		// 		&:last-child
		// 			margin-right: 0
		// 		&::after
		// 			content: ''
		// 			display: block
		// 			position: absolute
		// 			width: 15px
		// 			height: 15px
		// 			top: calc(50% - 7.5px)
		// 			right: 0
		// 			+mqPc
		// 				width: 20px
		// 				height: 20px
		// 				top: calc(50% - 10px)
		// 		&--up
		// 			@extend %txt
		// 			&::after
		// 				background: url('../image/pipeline/ico_arr_up.png') no-repeat
		// 				background-size: 100% 100%

		// 		&--down
		// 			@extend %txt
		// 			&::after
		// 				background: url('../image/pipeline/ico_arr_down.png') no-repeat
 } }		// 				background-size: 100% 100%
